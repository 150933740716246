import { vec3, mat4 } from 'gl-matrix';

const findIframeChild = (component) => {
    if (!component) {
        return null;
    }
    if (component && component.nodeName == 'IFRAME') {
        return component;
    }
    for (let i=0;i<component.children.length;i++) {
        let child = component.children[i];
        // console.log("child nodeName " + child.nodeName);
        let result = this.findIframeChild(child);
        if (result != null) {
            return result;
        }
    }
    return null;
}

const handleFocus = (animationData) => {
    // set the focus?
    if (animationData.focus !== undefined && animationData.focus) {

        const focusParent = document.getElementById(
            'prenzit_' + animationData.id);

        let hasSetFocus = false;
        let iFrameChild = findIframeChild(focusParent);
        if (iFrameChild) {
            iFrameChild.focus();
            hasSetFocus = true;
        }

        if (!hasSetFocus) {
            const iframes = document.getElementsByTagName("IFRAME");
            if (iframes && iframes.length > 0) {

                const iframe = iframes[iframes.length-1];

/*                console.log("iframe found, length " + iframes.length);
                const parent = iframe.parentElement;
                if (parent) {
                    console.log("Parent, nodeName " + parent.nodeName + " children count: " + ( parent.children ? parent.children.length : "-"));
                    if (parent.children.length > 0) {
                        console.log("parent child tagname " + parent.children[0].tagName);
                    }
                    // does the parent have children?
                } */
                if (!iframe.onload) {
                    //console.log("setting focus to first iframe ... using onload " + iframes.length);
                    iframe.onload = () => {
                        //console.log("setting focus to iframe");
                        iframe.contentWindow.focus();
                    }
                } else {
                    //console.log("setting focus outside von onload")
                    iframe.contentWindow.focus();
                }
            }
        }
    } else {
      window.focus();
    }
}

const getMatCenterForPresentationMode = (width, height, scrollCorrectionLeft, scrollCorrectionTop, 
    state, scale) => {
    let center = vec3.create();
    vec3.transformMat4(center,
        vec3.fromValues(width / 2, height / 2, 0),
        state.currentIMat);

    let mat = mat4.create();
    mat4.fromTranslation(mat, vec3.fromValues(-scrollCorrectionLeft, -scrollCorrectionTop, 0));
    mat4.scale(mat, mat, vec3.fromValues(scale, scale, 1));

    if (state.vmat !== undefined && state.vmat != null) {
        mat4.multiply(mat,mat,state.vmat);
    }

    mat4.multiply(mat, mat, state.currentIMat);
    return { mat, center };
}

export { handleFocus, getMatCenterForPresentationMode };
