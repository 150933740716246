
import { EditableSlide, StaticSlide } from '../components/slide';
import { EditableBulletpoints, StaticBulletpoints } from '../components/bulletpoints';
import { EditablePresentationElement, StaticPresentationElement } from '../components/presentationelement';
// we need to import "h" because of test ...
import { React, h } from 'preact';
import { connectStoreon} from 'storeon/preact';
import { glMatrix, mat4, vec3 } from 'gl-matrix';
import { EditableImageElement, StaticImageElement } from '../components/image';
import { calculateTransformMatrixes } from './matrixCalculations';

glMatrix.setMatrixArrayType(Array);

const extractCss = function (elementData) {
    let css = elementData.css || '';
    if (css.length > 0) {
        css = css.trim();
    }
    if (css.charAt(css.length - 1) != ';') {
        css += ';';
    }
    
    if (elementData.width !== undefined) {
        css += `width: ${elementData.width}px;`;
    }
    if (elementData.height !== undefined) {
        css += `height: ${elementData.height}px;`;
    }

    return css;
}

const generateSlideElementMap = function (element, parentId, slideElementMap, parentIds, index, oldSlideElementMap) {

    let newParentId;
    let newParentIds;
    let content;
    if (element.id === undefined) {
      // then this is the root (we are in slideData)
      newParentId = null;
      newParentIds = [];
      content = element;
    } else {
      newParentId = element.id;
      newParentIds = [...parentIds, index];
      // copy mats from oldSlideElementMap if present
      const mats = ((oldSlideElementMap !== undefined &&
        oldSlideElementMap[element.id] !== undefined &&
        oldSlideElementMap[element.id].mats !== undefined) ?
            oldSlideElementMap[element.id].mats : undefined);

      slideElementMap[element.id] = {
        element, parentId,
        parentIds: [...parentIds, index],
        mats
      };
      content = element.content;
    }
  
    if (content == null || !Array.isArray(content) || content.length == 0) {
      return;
    }
    content.forEach((el, index_v2) => {
      generateSlideElementMap(el, newParentId, slideElementMap, newParentIds, index_v2, oldSlideElementMap);
    });
  }

const createNumMatrixes = (element) => {
    let num = 1;
    if (element.otherPositions && element.otherPositions.length) {
        num += element.otherPositions.length;
    }
    const result = [];
    for (let i=0;i<num;i++) {
        result.push(mat4.create());
    }
    return result;
}


const calcTransformAndCreateSlidesAndViews = function (element, slideElementMap, children,
    isPreview, fixedVisibilityData, viewComponentChildren, honeyPath) {

    if (honeyPath && honeyPath.length > 0 && !honeyPath.includes(element.id)) {
        children.push('');
        return;
    }  


    const isFirstAndOnlyElement = honeyPath && honeyPath.length > 0 && honeyPath[0] == element.id;
    // const isFirstAndOnlyElement = false;
    const mats = isFirstAndOnlyElement ?  createNumMatrixes(element) : calculateTransformMatrixes(element);
    slideElementMap[element.id] = { ...slideElementMap[element.id], mats };

    children.push( createSingleElement(element, mats, slideElementMap, isPreview, fixedVisibilityData, 
        viewComponentChildren, honeyPath) );
    
}

const createChildrenAndText = function (elementData, slideElementMap, isPreview, fixedVisibilityData,
        viewComponentChildren, honeyPath) {

    
    // find out, if the animationStepId-Element is a view, in this case we show all sibblings ....
    const showSibblings = !honeyPath || (honeyPath.length == 1 && slideElementMap[elementData.id].element.type === 'View'); 
    if (showSibblings) { honeyPath = []; }

    const elementChildren = [];
    if (Array.isArray(elementData.content) && elementData.content.length > 0) {
        elementData.content.forEach((el) => {
            calcTransformAndCreateSlidesAndViews(el, slideElementMap,
                elementChildren, isPreview, fixedVisibilityData, viewComponentChildren, honeyPath);
        })
    }    
    return { elementChildren };

}

const createSingleElement = function (singleSlideData, mats, slideElementMap, isPreview, fixedVisibilityData,
    viewComponentChildren, honeyPath) {

    //if (honeyPath && honeyPath.length > 0 && !honeyPath.includes(singleSlideData.id)) {
    //    return '';
    //}

    const elementScale = singleSlideData.scale ?? 1;

    if (singleSlideData.type == 'Text' || singleSlideData.type == 'Title') {
        const css = extractCss(singleSlideData);

        if (isPreview) {
            return <StaticPresentationElement elementId={singleSlideData.id}
                parentId={slideElementMap[singleSlideData.id].parentId} type={singleSlideData.type}
                animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} 
                 mats={mats} css={css} text={singleSlideData.text}
                scale={elementScale} isPreview={isPreview} visibilityData={fixedVisibilityData}
            />
        }

        const SpecificEditablePresentationElement = connectStoreon('visibilityData_' + singleSlideData.id, EditablePresentationElement );
        return (            
            <SpecificEditablePresentationElement elementId={singleSlideData.id}
                parentId={slideElementMap[singleSlideData.id].parentId} type={singleSlideData.type}
                animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} 
                mats={mats} css={css} text={singleSlideData.text}
                innerHTML={singleSlideData.innerHTML}
                scale={elementScale} isPreview={isPreview} fixedVisibilityData={fixedVisibilityData}
                />
        );

    }

    if (singleSlideData.type == 'Slide') {
        const css = extractCss(singleSlideData);

        // we have to remove the honeypath to this element ....
        let newHoneyPath = (!honeyPath ? [] : honeyPath.slice(1));
        // check, if we have to remove the honeyPath because the last element is a view 
        if (newHoneyPath.length > 0) {
            const lookup = slideElementMap[newHoneyPath[newHoneyPath.length-1]];
            if (lookup.element.type == 'View') {
                newHoneyPath = newHoneyPath.slice(0,-1); // remove the last element ...
            }
        }

        const { elementChildren } = createChildrenAndText(singleSlideData, slideElementMap, isPreview, fixedVisibilityData, viewComponentChildren, newHoneyPath);

        viewComponentChildren[singleSlideData.id] = elementChildren;
        
        if (isPreview) {
            return (
                <StaticSlide elementId={singleSlideData.id} element={singleSlideData}
                    parentId={slideElementMap[singleSlideData.id].parentId}
                    animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} 
                    mats={mats} css={css}
                    scale={elementScale} isPreview={isPreview} visibilityData={fixedVisibilityData} />
            );
        }
        const SpecificEditableSlide = connectStoreon('visibilityData_' + singleSlideData.id, EditableSlide );
        return (
            <SpecificEditableSlide elementId={singleSlideData.id} element={singleSlideData}
                parentId={slideElementMap[singleSlideData.id].parentId}
                animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} 
                mats={mats} css={css}
                scale={elementScale} isPreview={isPreview} fixedVisibilityData={fixedVisibilityData} />
        );
    }


    if (singleSlideData.type == 'Bulletpoints') {
        const css = extractCss(singleSlideData);

        if (isPreview) {
            return <StaticBulletpoints elementId={singleSlideData.id}
            parentId={slideElementMap[singleSlideData.id].parentId} mats={mats}
            content={singleSlideData.content} css={css}
            scale={elementScale}
            animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} 
            isPreview={isPreview}
            visibilityData={fixedVisibilityData} />;

        }
        const SpecificEditableBulletpointsElement = connectStoreon('visibilityData_' + singleSlideData.id, EditableBulletpoints );
        return <SpecificEditableBulletpointsElement elementId={singleSlideData.id}
            parentId={slideElementMap[singleSlideData.id].parentId} mats={mats}
            content={singleSlideData.content} css={css}
            scale={elementScale}
            animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} isPreview={isPreview}
            fixedVisibilityData={fixedVisibilityData} />;
    }

    if (singleSlideData.type == 'Image') {
        const css = extractCss(singleSlideData);

        if (isPreview) {
            return <StaticImageElement elementId={singleSlideData.id} element={singleSlideData}
            parentId={slideElementMap[singleSlideData.id].parentId}
            animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} 
            mats={mats} css={css}
            scale={elementScale} isPreview={isPreview} visibilityData={fixedVisibilityData}/>;
        }

        const SpecificEditableImageElement = connectStoreon('visibilityData_' + singleSlideData.id, EditableImageElement );
        return <SpecificEditableImageElement elementId={singleSlideData.id} element={singleSlideData}
        parentId={slideElementMap[singleSlideData.id].parentId}
        animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} mats={mats} css={css}
        scale={elementScale} isPreview={isPreview} fixedVisibilityData={fixedVisibilityData}/>;

    }

    // we create the correct transforms ...
    if (singleSlideData.type == 'View') {

        createChildrenAndText(singleSlideData, slideElementMap, isPreview, fixedVisibilityData, viewComponentChildren);
        return '';
    }
    // does not happen
    return '';
};

const updateSingleElementNoChildren = function (singleSlideData, parentId) {

    const elementScale = singleSlideData.scale ?? 1;
    const mats = calculateTransformMatrixes(singleSlideData);

    if (singleSlideData.type == 'Text' || singleSlideData.type == 'Title') {
        const css = extractCss(singleSlideData);

        const SpecificEditablePresentationElement = connectStoreon('visibilityData_' + singleSlideData.id, EditablePresentationElement );
        return (            
            <SpecificEditablePresentationElement elementId={singleSlideData.id}
                parentId={parentId} type={singleSlideData.type}
                animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} 
                mats={mats} css={css} text={singleSlideData.text}
                innerHTML={singleSlideData.innerHTML}
                scale={elementScale} isPreview={false} fixedVisibilityData={undefined}
                />
        );
    }

    if (singleSlideData.type == 'Slide') {
        const css = extractCss(singleSlideData);

        const SpecificEditableSlide = connectStoreon('visibilityData_' + singleSlideData.id, EditableSlide );

        return (
            <SpecificEditableSlide elementId={singleSlideData.id} element={singleSlideData}
                parentId={parentId}
                animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} 
                mats={mats} css={css}
                scale={elementScale} isPreview={false} fixedVisibilityData={undefined} />
        );
    }


    if (singleSlideData.type == 'Bulletpoints') {
        const css = extractCss(singleSlideData);

        const SpecificEditableBulletpointsElement = connectStoreon('visibilityData_' + singleSlideData.id, EditableBulletpoints );

        return <SpecificEditableBulletpointsElement elementId={singleSlideData.id}
            parentId={parentId} mats={mats}
            content={singleSlideData.content} css={css}
            scale={elementScale}
            animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} 
            isPreview={false}
            fixedVisibilityData={undefined} />;
    }

    if (singleSlideData.type == 'Image') {
        const css = extractCss(singleSlideData);

        const SpecificEditableImageElement = connectStoreon('visibilityData_' + singleSlideData.id, EditableImageElement );

        return <SpecificEditableImageElement elementId={singleSlideData.id} element={singleSlideData}
        parentId={parentId}
        animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} 
        mats={mats} css={css}
        scale={elementScale} isPreview={false} fixedVisibilityData={undefined}/>;

    }

    return mats;

};

const updateSingleElementNoChildrenForPreview = function (singleSlideData, parentId, fixedVisibilityData ) {

    const elementScale = singleSlideData.scale ?? 1;

    // TODO: handle this correctly! -> need to take care of honeyPath!
    const mats = calculateTransformMatrixes(singleSlideData);

    if (singleSlideData.type == 'Text' || singleSlideData.type == 'Title') {
        const css = extractCss(singleSlideData);

        return <StaticPresentationElement elementId={singleSlideData.id}
            parentId={parentId} type={singleSlideData.type}
            animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} mats={mats} css={css} text={singleSlideData.text}
            scale={elementScale} isPreview={true} visibilityData={fixedVisibilityData}
        />
    }

    if (singleSlideData.type == 'Slide') {
        const css = extractCss(singleSlideData);
        return (
            <StaticSlide elementId={singleSlideData.id} element={singleSlideData}
                parentId={parentId}
                animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} mats={mats} css={css}
                scale={elementScale} isPreview={true} visibilityData={fixedVisibilityData} />
        );
    }


    if (singleSlideData.type == 'Bulletpoints') {
        const css = extractCss(singleSlideData);

            return <StaticBulletpoints elementId={singleSlideData.id}
            parentId={parentId} mats={mats}
            content={singleSlideData.content} css={css}
            scale={elementScale}
            animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} isPreview={true}
            visibilityData={fixedVisibilityData} />;
    }

    if (singleSlideData.type == 'Image') {
        const css = extractCss(singleSlideData);

        return <StaticImageElement elementId={singleSlideData.id} element={singleSlideData}
        parentId={parentId}
        animation={singleSlideData.animation === undefined ? false : singleSlideData.animation} mats={mats} css={css}
        scale={elementScale} isPreview={true} visibilityData={fixedVisibilityData}/>;

    }
    return null;

};

const createSlides = function (slideData, slideElementMap, fixedVisibilityData, viewComponentChildren ) {

    const input = !Array.isArray(slideData) ? [slideData] : slideData;

    const children = [];
    input.forEach(element => {
        // here we transform the data and the content ....
        //if (element.type == 'View' ||
        //    (element.content !== undefined && Array.isArray(element.content))) {
            const isPreview = fixedVisibilityData !== undefined;
            calcTransformAndCreateSlidesAndViews(element, slideElementMap, children,
                isPreview, fixedVisibilityData , viewComponentChildren);
        //}

    });
    // these are the direct "frame"-children
    return children;
};


const findElementSlideDataRoot = (elementId,slideElementMap) => {
    const lookupResult = slideElementMap[elementId];
    if (!lookupResult) { return elementId; }
    if (!lookupResult.parentId) {
        return elementId;
    }
    return findElementSlideDataRoot(lookupResult.parentId,slideElementMap);
}

const generateHoneyPath = (elementId, slideElementMap) => {
    
    const lookupResult = slideElementMap[elementId];
    if (!lookupResult) { return []; }

    const parentResult = generateHoneyPath( lookupResult.parentId, slideElementMap );
    parentResult.push(elementId);

    return parentResult;
}


/**
 * We are creating "SlimSlides"; idea is to just create those slides that are "important" for the current animationStep ...
 * 
 * @param {*} slideData 
 * @param {*} slideElementMap 
 * @param {*} fixedVisibilityData 
 * @param {*} viewComponentChildren 
 * @returns 
 */
const createSlimSlides = function (slideData, slideElementMap, fixedVisibilityData, viewComponentChildren , animationStepId) {


    // we neeed a working slideElementMap in order to find the correct parents ...
    // next step is to find the "silver thread" from the animationStepId to the root
    // and only generate those elements ...
    generateSlideElementMap(slideData, null, slideElementMap, [], undefined, undefined);

//    const elementRoot = findElementSlideDataRoot(animationStepId,slideElementMap);

    let honeyPath = generateHoneyPath(animationStepId,slideElementMap);
    const children = [];

    // find out, if the animationStepId-Element is a view, in this case we show all sibblings ....
    const showSibblings = honeyPath.length == 1 && slideElementMap[animationStepId].element.type === 'View'; 
    if (showSibblings) { honeyPath = []; }


    const input = !Array.isArray(slideData) ? [slideData] : slideData;
    input.forEach(element => {

        // this is called for previews only ...

        // first step: we create the path only, if the animationStepId is "contained"
        if (showSibblings || honeyPath.includes(element.id)) {
            const isPreview = fixedVisibilityData !== undefined;
            calcTransformAndCreateSlidesAndViews(element, slideElementMap, children,
                isPreview, fixedVisibilityData , viewComponentChildren, honeyPath);
        } else {
            children.push([]);
        }
    });
    // these are the direct "frame"-children
    return children;
};



// parameter: imat: mat4 is the current inverse translation
// matrix. This matrix transports the currently
// selected slide / or partial view so that the
// left most corner is at 0,0,0

const iMatSpaceCache = new Map();

const calculateViewSpace = function (imat, viewWidth, viewHeight, perspective) {

    const key = JSON.stringify({ imat, viewWidth, viewHeight, perspective });
    if (iMatSpaceCache.has(key)) {
        return iMatSpaceCache.get(key);
    }

    // we handle the perspective ...
    const center = vec3.create();
    vec3.transformMat4(center,
        vec3.fromValues(viewWidth / 2, viewHeight / 2, 0),
        imat);

    const corners = [vec3.fromValues(0, 0, 0),
    vec3.fromValues(viewWidth, 0, 0),
    vec3.fromValues(viewWidth, viewHeight, 0),
    vec3.fromValues(0, viewHeight, 0)];

    let minx = 0;
    let maxx = 0;
    let miny = 0;
    let maxy = 0;

    corners.forEach((corner) => {
        const v = vec3.create();
        vec3.transformMat4(v, corner, imat);

        // we check the values with regard to the perspective .
        if (v[2] !== 0) {
            // there is a z component .
            const vpart = vec3.create();
            vec3.subtract(vpart, v, center);
            const factor = perspective / (perspective - (v[2]));
            vec3.scale(vpart, vpart, factor);
            vec3.add(v, vpart, center);
        }
        if (v[0] < minx) {
            minx = v[0];
        }
        if (v[0] >= maxx) {
            maxx = v[0];
        }

        if (v[1] < miny) {
            miny = v[1];
        }
        if (v[1] >= maxy) {
            maxy = v[1];
        }
    });

    const calculatedWidth = (maxx - minx);
    const calculatedHeight = (maxy - miny);

    const retval = [calculatedWidth, calculatedHeight, minx, maxx, miny, maxy, center[0], center[1]];
    iMatSpaceCache.set(key, retval);

    return retval;

};

const findElement = function(content,id) {

    if (content == null || !Array.isArray(content) || content.length == 0) {
      return null;
    }

    let result = null;
    content.forEach( el => {
        if (el.id !== undefined && el.id == id) {
          result = el;
          return;
        }        
    });
    if (result != null) {
      return result;
    }
    content.forEach( el => {
      const recursiveResult = findElement(el.content,id);
      if (recursiveResult != null) {
        result = recursiveResult;
        return;
      }
    });
    return result;
}

	/* This function takes a Matrix and applies all the matrix transformations
	   of all parents
	   It takes into account the current positions of all slides (including parents)
	   It is important to get a "current" mat so that an inverse matrix can be calculated
	   for each element (View/Slide). This inverse matrix must take into account the current
	   visible position.
	   We need the inverse matrix to show the Slide/View as a fullscreen-Slide.
	   What we do: the Slide/View has been put whereever using matrix-transformations. Using the
	   inverse matrix on the view, we put this slide at the very center of the view.
	   we then translate the view in the canvas, so the we can still scroll to other parts
	   and adjust the scrollLeft / scrollTop part. (In Presentation Mode we do not have the scroll part)
	    */
	const enrichMat = function(mat,slideElementMap, parentId, positions) {
		if (parentId == null) {
			return mat;
		}

		const fullMat = mat4.create();

		const parent = slideElementMap[parentId];

		const position = positions[parentId] ?? 0;

		const currentParentMat = parent.mats[position];

		const nextParentId = slideElementMap[parentId].parentId;
		if (nextParentId == null) {
			mat4.multiply(fullMat,currentParentMat,mat);
			return fullMat;
		}

		const nextMat = enrichMat(currentParentMat,slideElementMap, nextParentId, positions);
		mat4.multiply(fullMat,nextMat,mat);
		return fullMat;
	}

export { createSlides, createSlimSlides, calculateViewSpace,  
    findElement, enrichMat, 
    updateSingleElementNoChildren, updateSingleElementNoChildrenForPreview, 
    generateSlideElementMap,
    generateHoneyPath };