// a Generic Slide-Component

import { Component } from 'preact';

import { calculateTransformMatrixes } from '../../utils/matrixCalculations';

class SlideElement extends Component {

    constructor() {
        super();
    }

    /**
     * Returns true, if the component should continue with processing
     * the changed properties
     * This should happen, if the edit ends, or if the component
     * is a preview component.
     * 
     * @param {*} nextProps 
     * @param {*} state 
     */
    static checkIfPropertyChangeNeedsHandling(nextProps,state) {
        const elementVisibilityData  = nextProps['visibilityData_' + nextProps.elementId];

        // we must coninue with the processing of this property
        // change. Either there has been no previous VisibilityData
        // being set (initial rendering) or we are in preview-mode
        // Preview mode gets changes only, if there has been a request
        // to update the component. So content will have changed definitely.
        if (state.prevVisibilityData === null || nextProps.isPreview) {
                return { doContinue : true, elementVisibilityData }; 

        }
 
        if (elementVisibilityData != state.prevVisibilityData ) {
                // Something changed with the current animation of the element
                // (maybe the bullet-points are activated differently ... )
                return { doContinue : true, elementVisibilityData }; 
        }


        if (nextProps.editData !== undefined && 
                nextProps.editData.editedSlide !== undefined
                ) {
                // then we are truly in edit mode ....
                if (nextProps.animation !== undefined &&
                        nextProps.animation &&
                        // elementVisibilityData === undefined && 
                        !state.data.visible) {
                        return { doContinue : true, elementVisibilityData }; 
                }
        }

        if (state.data.isHiddenDueToEdit &&
                (nextProps.editData === undefined || 
                nextProps.editData.editedSlide === undefined)) {
                // this is important so that previously hidden elements will be "unhidden" if edit stops ...
                return { doContinue : true, elementVisibilityData }; 
        }


        if ((nextProps.editData === undefined || 
                (nextProps.editData !== undefined && nextProps.editData.editedSlide === undefined) ) &&
                state.data.isParentSlideEdited) {
                return { doContinue : true, elementVisibilityData }; 
        }
        if (nextProps.editData !== undefined && 
                nextProps.editData.editedSlide !== undefined &&
                nextProps.editData.editedSlide === nextProps.parentId) {
                return { doContinue : true, elementVisibilityData }; 
        }

        // then we do not need to continue processing this change.
        // it is just irrelevant for now - this happens, if an edit happens on another slide and
        // therefore the content and appearance of this element will not be altered ...
        return { doContinue : false, elementVisibilityData };

    }
    static handleVisibility(draft,nextProps) {
        /**
         * If we are in "preview"-mode, the activation, position and invisible settings are
         * transfered with nextProps.visibilityData.
         * If we are not in "preview"-mode, the activation, position and invisible settings
         * are transfered with an individual property, which is made up from
         * visibilityData{_elementId}
         */

        const elementVisibilityData = nextProps['visibilityData_' + nextProps.elementId];

        let visible = true;
        if (nextProps.animation !== undefined && 
            nextProps.animation) {
                // check, if the element has been activated by the current animation 
                if (nextProps.isPreview) {
                        visible = nextProps.elementId in nextProps.visibilityData.activated;
                } else {
                        visible = elementVisibilityData !== undefined && elementVisibilityData.activated !== undefined;
                }
        }

        if (visible) {
                if (nextProps.isPreview) {
                        if (nextProps.visibilityData.invisible.includes(nextProps.elementId)) {
                                visible = false;
                        }
                } else {
                        if (elementVisibilityData !== undefined && elementVisibilityData.invisible == true) {
                                visible = false;
                        }
                }
        }
        draft.visible = visible;
    }

    static handlePositionData(draft,nextProps) {
        const elementVisibilityData = nextProps['visibilityData_' + nextProps.elementId];


        let position;
        if (nextProps.isPreview) {
                if (nextProps.elementId in nextProps.visibilityData.positions) {
                        position = nextProps.visibilityData.positions[nextProps.elementId];
                }
        } else {
                if (elementVisibilityData !== undefined &&  elementVisibilityData.position !== undefined) {
                        position = elementVisibilityData.position;
                }
        }
        if (position !== undefined) {
                draft.position = position;
        } else {
                draft.position = 0;
        }
    }

    static handleEditData(draft, nextProps) {

        if (!nextProps.isPreview) {

            if (nextProps.editData !== undefined && nextProps.editData.editedSlide !== undefined) {
                // if there is any edit going on and the element is not visible, than
                // we actually do hide it completely
                draft.isHiddenDueToEdit = !draft.visible;
            } else {
                draft.isHiddenDueToEdit = false;
            }

            // comparison with !== is important for
            // editedSlide -> undefined means no editing,
            // null means: the "main"-View, where parentId === null
            draft.isParentSlideEdited = 
                nextProps.editData !== undefined && 
                nextProps.editData.editedSlide !== undefined && 
                nextProps.editData.editedSlide === nextProps.parentId;
        
            if (nextProps.editData.changedElementId == nextProps.elementId) {

                let changedElementData = nextProps['visibilityData_' + nextProps.elementId].changedElementData;

                if (changedElementData) {
                    draft.editMats = calculateTransformMatrixes(changedElementData);

                    if (changedElementData.width !== undefined) {
                        draft.editCss += `width: ${changedElementData.width}px;`;
                    }
                    if (changedElementData.height !== undefined) {
                        draft.editCss += `height: ${changedElementData.height}px;`;
                    }
                }
            }
            draft.isSelected = nextProps.editData.selectedElementId == nextProps.elementId;
            // draft.isSelected = nextProps.elementId in nextProps.editData.selectedElementsMap;
        } else {
            draft.isParentSlideEdited = false;
            draft.isSelected = false;
            draft.isHiddenDueToEdit = false;
        }
    }

    static basicRendering(props,state) {

        let mat = props.mats[state.data.position];
        if (state.data.editMats != null) {
                mat = state.data.editMats[state.data.position];
        }

        let styleString = `transform: matrix3d(${mat});${props.css};` + state.data.editCss;

        styleString += 'opacity: ' + (state.data.visible ? '1;' : '0;');

        return styleString ;
    }

}

export default SlideElement;