// immutable data structures ...
import produce from 'immer';

import sha1 from 'js-sha1';

import { createSlides, updateSingleElementNoChildren, generateSlideElementMap }
  from '../utils/slideDataToSlideComponent.js';

const LINE_EXPRESSION = /\r\n|\n\r|\n|\r/g; // expression symbols order is very important

/* alternatitve implementation:  
  const navigateToElement = function( mutableSlideData, parentIds ) {
  const content = navigateToParentContent(mutableSlideData,parentIds);
  return content[parentIds[parentIds.length-1]];
} */

const navigateToElement = function (mutableSlideData, parentIds) {
  let element = mutableSlideData[parentIds[0]];
  for (let i = 1; i < parentIds.length; i++) {
    // we get a new "element" and descend into the parentIds-Tree ...
    element = element.content[parentIds[i]];
  }
  return element;
}
const navigateToParentContent = function (mutableSlideData, parentIds) {
  if (parentIds.length == 1) {
    return mutableSlideData;
  }
  let element = mutableSlideData[parentIds[0]];
  for (let i = 1; i < parentIds.length - 1; i++) {
    element = element.content[parentIds[i]];
  }
  return element.content;
}



const getNewId = function (data, slideElementMap, initialValue) {
  let newId;
  let i = (initialValue === undefined ? 0 : initialValue);
  for (; i < 10000; i++) {
    newId = data + "_" + i;
    if (slideElementMap[newId] === undefined) {
      break;
    }
  }
  return { newId, i };
}

const addComponentToState = function (parentId, immutableState, newSlideData, newComponent, newElement) {

  let parentIds;

  const result = {};

  if (parentId == null) {
    // we add the component to the frameChildren 
    // we are at the slideData level; no parent ...

    const newFrameChildren = [...immutableState.frameChildren];
    newFrameChildren.push(newComponent);
    result.frameChildren = newFrameChildren;

    parentIds = [newFrameChildren.length - 1];
  } else {

    const newViewComponentChildren = { ...immutableState.viewComponentChildren };
    const newChildren = (parentId in newViewComponentChildren) ? [...newViewComponentChildren[parentId]] : [];
    newChildren.push(newComponent);
    newViewComponentChildren[parentId] = newChildren;
    result.viewComponentChildren = newViewComponentChildren;

    // the component is added at the end of the parent-component
    parentIds = [...immutableState.slideElementMap[parentId].parentIds, newChildren.length - 1];
  }

  let newSlideElementMap = { ...immutableState.slideElementMap };
  newSlideElementMap[newElement.id] = { element: newElement, parentId, parentIds, mats: newComponent.props.mats };
  // we only need this for the current element-tree (and not everything else )
  if (parentId != null) {
    const parentIdsCopy = [parentIds[0]];
    const parentElement = navigateToElement(newSlideData, parentIdsCopy);
    const index = parentIds[0];
    generateSlideElementMap(parentElement, null, newSlideElementMap,
      [], index, newSlideElementMap);
  }

  result.slideElementMap = newSlideElementMap;

  result.recalculatePreviewElements = true;

  return result;

}

// calculate the animation step number
const calculateAnimationSteps = (animationData, slideElementMap) => {

  if (animationData === undefined ||
    animationData == null ||
    !Array.isArray(animationData)) {
    return undefined;
  }

  const elementAnimatedInStepMap = {}

  const animationStepCounter = {};
  const animationToCounterArray = [];

  animationData.forEach((animationStep, index) => {

    let counterForAnimationStepId = -1;
    if (animationStepCounter[animationStep.id] === undefined) {
      animationStepCounter[animationStep.id] = {}
    } else {
      counterForAnimationStepId = animationStepCounter[animationStep.id].counter;
    }
    animationStepCounter[animationStep.id] = {
      counter: counterForAnimationStepId + 1, lastIndex: index
    };

    animationToCounterArray.push(counterForAnimationStepId + 1);

    if (animationStep.animation !== undefined) {
      Object.keys(animationStep.animation).forEach((key) => {
        const oldvalue = elementAnimatedInStepMap[key] !== undefined ? elementAnimatedInStepMap[key] : [];
        elementAnimatedInStepMap[key] = [...oldvalue, index];
      })
    }
  })

  //  console.log("elementAnimationInStepMap is " + JSON.stringify(elementAnimatedInStepMap));
  //console.log("animationStepCounter  is " + JSON.stringify(animationStepCounter));
  //console.log("animationToCounterArray is " + JSON.stringify(animationToCounterArray));

  const animationStepMap = {};

  //console.log("inside caluclateAnimationSteps ...");
  Object.keys(slideElementMap).forEach((key) => {
    animationStepMap[key] = { animationStepNum: '?' };
    const lookupResult = slideElementMap[key];
    if (lookupResult.element === undefined) return;

    animationStepMap[key] = { ...animationStepMap[key], parentId: lookupResult.parentId };

    if (lookupResult.element.animation) {
      //console.log("Animated item " + key);
      const animatedWhere = elementAnimatedInStepMap[key];
      if (animatedWhere !== undefined) {
        if (animatedWhere.length > 1) {
          //console.log("More than one animation location for " + key + " " + JSON.stringify(animatedWhere));
        } else {
          // exactly one location ....
          const animationStep = animationData[animatedWhere[0]];
          if (animationStep.id == lookupResult.parentId || lookupResult.parentId == null) {
            // it is an animation on the mainview 
            const animationStepIndex = animatedWhere[0];
            animationStepMap[key] = {
              ...animationStepMap[key],
              animationStepNum: animationToCounterArray[animationStepIndex],
              slideNum: animationStepIndex
            };
          }
        }
      }
    } else {
      // setting the animation sign to "-" to signal, that element is not animated
      animationStepMap[key] = { ...animationStepMap[key], animationStepNum: '-' };
    }
  })
  return { animationStepMap, animationStepCounter };

}

const moveElement = (draft, lookupResult, data, changedElement) => {
  // lookup the element to change in the mutable copy of the original slideData 
  const element = navigateToElement(draft, lookupResult.parentIds);

  if (element === undefined) {
    console.log("moveElement: We have no element for data.id " + data.id + " and lookupResult.parentIds " + JSON.stringify(lookupResult.parentIds));
  } else {
    // we need to take the Position into account ....
    if (data.position != 0) {
      element.otherPositions[data.position - 1].positionX = changedElement.otherPositions[data.position - 1].positionX;
      element.otherPositions[data.position - 1].positionY = changedElement.otherPositions[data.position - 1].positionY;
    } else {
      element.positionX = changedElement.positionX;
      element.positionY = changedElement.positionY;
    }
  }

  return element;
}

const handleSingleUpdate = function (newSlideData, lookupResult, immutableState) {

  const element = navigateToElement(newSlideData, lookupResult.parentIds);
  if (element == null || element == undefined || !element.id) {
    console.log("WARNING: no element in handleSingleUpdate for lookupResult.parentIds " + lookupResult.parentIds);
    return;
  }
  const positionInParent = lookupResult.parentIds[lookupResult.parentIds.length - 1];

  const newReactElement = updateSingleElementNoChildren(element, lookupResult.parentId);

  const result = { slideData: newSlideData };

  if (lookupResult.parentId == null) {
    // we are in the "global" frame
    const newFrameChildren = [...immutableState.frameChildren];
    newFrameChildren[positionInParent] = newReactElement;
    result.frameChildren = newFrameChildren;
  } else {
    // we make a shallow-copy, which is completly sufficient.
    // we just change one item ... the rest will stay the same
    const newViewComponentChildren = { ...immutableState.viewComponentChildren };

    // obacht! Hier muss man mitdenken!
    // another "shallow" copy for the child-components of just this single entity
    newViewComponentChildren[lookupResult.parentId] = [...newViewComponentChildren[lookupResult.parentId]];
    newViewComponentChildren[lookupResult.parentId][positionInParent] = newReactElement;
    result.viewComponentChildren = newViewComponentChildren;
  }

  // and the slideElementMap ... for further changes to start ...

  const newSlideElementMap = { ...immutableState.slideElementMap };
  newSlideElementMap[element.id] = {
    element, parentId: lookupResult.parentId,
    parentIds: lookupResult.parentIds, mats: newReactElement.props.mats
  };
  result.slideElementMap = newSlideElementMap;

  // add data to result to do the preview-Update aswell
  result.updatePreviewElement = { element, parentId: lookupResult.parentId, locationInParent: positionInParent };

  return result;
}

const getParentContextForAdd = function (draft, state, data, givenNewId) {
  let content;
  let parentId;
  if (data == null) {
    content = draft;
    data = "mainview"; // we put in an id for the "Main"-View
    parentId = null;
  } else {
    // data is the id of the currently edited slide ...
    // We do not need the content of the slideElementMap ...
    content = navigateToElement(draft, state.slideElementMap[data].parentIds).content;
    parentId = data;
  }

  const newId = givenNewId || getNewId(data, state.slideElementMap).newId;

  return { content, newId, parentId };
}

const calculateAnimationStepIndex = (state, usedParentId, animationStepNum) => {
  let tmpCounter = -1;
  for (let i = 0; i < state.animationData.length; i++) {
    const tmpAnimationStep = state.animationData[i];
    if (tmpAnimationStep.id == usedParentId) {
      tmpCounter++;
      if (tmpCounter == animationStepNum) {
        return i;
      }
    }
  }

}

const undoStack = [];

const pushStateOnUndoStack = (state) => {
  undoStack.push(state);
  if (undoStack.length > 10) {
    // we have the last 10 steps ...
    undoStack.splice(0, 1);
  }
}

const slideData = (store) => {

  store.on('setAllData', (state, data) => {

    pushStateOnUndoStack(state);

    if ('updatePreviewElement' in data) {

      // handle the previewUpdate 
      store.dispatch('updatePreviewElement', data);
      delete data.updatePreviewElement;
    }
    if ('removeElementInPreviews' in data) {
      // we want to remove the element from the previews
      store.dispatch('removeElementInPreviews', data);
      delete data.removeElementInPreviews;
    }
    if ('recalculatePreviewElements' in data) {
      store.dispatch('createPreviewComponentsAndData', { ...state, ...data });
      delete data.recalculatePreviewElements;
    }

    const currentSlideData = (data.slideData !== undefined ? data.slideData : store.get().slideData);
    const currentAnimationData = (data.animationData !== undefined ? data.animationData : store.get().animationData);
    const currentSlideElementMap = (data.slideElementMap !== undefined ? data.slideElementMap : store.get().slideElementMap);

    // now we calculate the current hash-value of the data ....
    data.hash = sha1(JSON.stringify({
      data:
        { slideData: currentSlideData, animationData: currentAnimationData }
    })).toString();

    if (data.needNewAnimationSteps) {
      data.animationSteps = calculateAnimationSteps(currentAnimationData, currentSlideElementMap);
    }
    delete data.needNewAnimationSteps;

    return data;
  });

  store.on('setPreviewHash', (state, data) => {
    // we always set the hash, regardless of the previous value
    // this is a reminder to update the images for the previews ...
    return { previewHash: data };
  })

  store.on('undo', () => {

    // this is operation mutates the state?
    if (undoStack.length > 0) {
      return undoStack.pop();
    }

  });

  store.on('endChange', (state, data) => {

    // console.log("Calling endChange with " + JSON.stringify(data));
    const lookupResult = state.slideElementMap[data.id];
    if (lookupResult === undefined) {
      console.log("no state for " + data.id + "? End change doing nothing?");
      return;
    }
    const changedElement = state['visibilityData_' + data.id].changedElementData;

    if (changedElement == null) {
      console.log("endChange called with no current changedElement ... doing nothing");
      return;
    }

    let newSlideData = state.slideData;

    if (data.action == 'moveElement') {

      newSlideData = produce(state.slideData, draft => {
        moveElement(draft, lookupResult, data, changedElement);
      })

    } else if (data.action == 'scaleElement') {

      newSlideData = produce(state.slideData, draft => {
        // lookup the element to change in the mutable copy of the original slideData 
        let element = navigateToElement(draft, lookupResult.parentIds);

        if (element === undefined) {
          console.log("scaleElement: We have no element for data.id " + data.id + " and lookupResult.parentIds " + JSON.stringify(lookupResult.parentIds));
        } else {
          if (data.position != 0) {
            element.otherPositions[data.position - 1].scale = changedElement.otherPositions[data.position - 1].scale;
          } else {
            element.scale = changedElement.scale;
          }
        }
      });

    } else if (data.action == 'width' || data.action == 'height') {

      newSlideData = produce(state.slideData, draft => {
        let element = moveElement(draft, lookupResult, data, changedElement);
        if (element !== undefined) {
          element["width"] = changedElement.width;
          element["height"] = changedElement.height;
        }
      });
    }

    store.dispatch('endEdit');

    const result = handleSingleUpdate(newSlideData, lookupResult, state);
    if (result) {
      result.needNewAnimationSteps = false;
      store.dispatch('setAllData', result);
    }

  });

  store.on('changeText', (state, data) => {
    const lookupResult = state.slideElementMap[data.id]; // findElement(state.slideData, data.id);

    if (lookupResult != null) {

      if (lookupResult.element.type === 'Bulletpoints') {
        // we have to "decipher" the text with new lines and create the items

        const lines = data.text.split(LINE_EXPRESSION).filter(el => el.length > 0);

        // this does produce a newSlideData from the immutable state.slideData
        const newSlideData = produce(state.slideData, draft => {
          let modifiedElement = navigateToElement(draft, lookupResult.parentIds);
          modifiedElement.content = lines;
        });

        const result = handleSingleUpdate(newSlideData, lookupResult, state);
        if (result) {
          result.needNewAnimationSteps = false;
          store.dispatch('setAllData', result);
        }
        // important, so that the animation is handled for bulletpoints
        store.dispatch('current');
        return;

      } else {

        const newSlideData = produce(state.slideData, draft => {
          let modifiedElement = navigateToElement(draft, lookupResult.parentIds);
          modifiedElement.text = data.text;
        });

        const result = handleSingleUpdate(newSlideData, lookupResult, state);
        if (result) {
          result.needNewAnimationSteps = false;
          store.dispatch('setAllData', result);
        }
        return;
      }
    }

  })

  store.on('removeElement', (immutableState, data) => {

    // allow only elements to be removed, that have no part in animation ...

    // console.log("calling removeElement for " + data);
    let isInAnimation = false;
    immutableState.animationData.forEach((animation) => {
      if (animation.id == data) {
        isInAnimation = true;
      }
    })

    if (isInAnimation) {
      // check, if it only is an ID without anything else ...
      console.log("Can't delete something in animation! " + data);
      return;
    }

    const lookupResult = immutableState.slideElementMap[data];

    if (!lookupResult) {
      return;
    }

    const positionInParent = lookupResult.parentIds[lookupResult.parentIds.length - 1];
    // console.log("Position in Parent for " + data + " is " + positionInParent);

    // we have to remove the element from the parent ...
    const newSlideData = produce(immutableState.slideData, draft => {
      const content = navigateToParentContent(draft, lookupResult.parentIds);
      if (content != null) {
        content.splice(positionInParent, 1);
      } else {
        console.log("Element " + data + " has no parent-content in removeElement!");
      }
    });

    const result = { slideData: newSlideData };

    if (lookupResult.parentId == null) {
      // in main view 
      const newFrameChildren = [...immutableState.frameChildren];
      newFrameChildren.splice(positionInParent, 1);
      result.frameChildren = newFrameChildren;

    } else {
      const newViewComponentChildren = { ...immutableState.viewComponentChildren };
      const newChildren = [...newViewComponentChildren[lookupResult.parentId]];

      newChildren.splice(positionInParent, 1);
      newViewComponentChildren[lookupResult.parentId] = newChildren;
      result.viewComponentChildren = newViewComponentChildren;
    }

    const newAnimationData = produce(immutableState.animationData, draft => {
      // now we remove all appearances in animationData
      draft.forEach(animationStep => {
        // we need to remove "data" (=id) in all deactivateAnimation and animation ...
        if (animationStep.deactivateAnimation !== undefined) {
          let found = -1;
          animationStep.deactivateAnimation.forEach((el, index) => {
            if (el == data) {
              found = index;
            }
          })
          if (found >= 0) {
            animationStep.deactivateAnimation.splice(found, 1);
          }
        }

        if (animationStep.animation !== undefined) {
          delete animationStep.animation[data];
        }
      });

    });

    if (immutableState.animationData !== newAnimationData) {
      result.animationData = newAnimationData;
    }


    // this will repair the index and the location in the parent ...
    // (we copy the old "mats" entries from the existing slideElementMap)
    const newSlideElementMap = {};
    generateSlideElementMap(result.slideData, null, newSlideElementMap, [], undefined, immutableState.slideElementMap);

    result.slideElementMap = newSlideElementMap;

    // handle the removal in the preview-Part
    result.removeElementInPreviews = { id: data, lookupResult };

    result.needNewAnimationSteps = true;
    store.dispatch('setAllData', result);
  
  });


  store.on('moveAnimation', (state, data) => {

    // move the view up or down ...
    const newAnimationData = produce(state.animationData, draft => {
      const movedElements = draft.splice(data.startIndex, data.length);
      draft.splice(data.newPosition, 0, ...movedElements);
    });

    store.dispatch('setAnimationData', newAnimationData);

  });

  store.on('addAnimation', (state, data) => {

    const newAnimationData = produce(state.animationData, draft => {

      // what do we expect of data ...
      // data.parentId -> the "view" for the animation (=parentId)
      // data.animationId -> the id of the elemented beeing animated ...

      let animationStep;
      // we go to the first appearance of data.parentId in the animationSteps ...
      for (let i = 0; i < draft.length; i++) {
        animationStep = draft[i];
        if (animationStep.id == data.parentId) {
          break;
        }
      }
      if (animationStep === undefined) {
        // we have no element where we can add the animation to ....
        console.log("AddAnimation called without any matching animationStep for " + data.parentId);
        return;
      }

      if (animationStep.animation === undefined) {
        animationStep.animation = {};
      }
      animationStep.animation[data.animationId] = {};
    });
    if (newAnimationData === state.animationData) {
      console.log("Warning: No change of animationData in addAnimation!");
      return;
    }

    const lookupResult = state.slideElementMap[data.animationId];

    const newSlideData = produce(state.slideData, draft => {
      const modifiedElement = navigateToElement(draft, lookupResult.parentIds);
      modifiedElement.animation = true;
    });

    store.dispatch('setSlideAndAnimationData', { slideData: newSlideData, animationData: newAnimationData });
    store.dispatch('current');


  });

  store.on('increaseAnimationStep', (state, data) => {

    // what do we expect:
    // animationData is present (state.animationData)
    // animationSteps are present ... (state.animationSteps)
    // slideElementMap is present (state.slideElementMap)

    // data just contains the element.id
    // we should be able to do a lookup and find the parentId


    const lookupResult = state.slideElementMap[data];

    const usedParentId = lookupResult.parentId ? lookupResult.parentId : "mainview_0";


    const animationStepCounter = state.animationSteps.animationStepCounter[usedParentId];
    const animationStepForElement = state.animationSteps.animationStepMap[data];

    if (animationStepCounter && animationStepForElement) {
      // we change the animationData 

      const oldAnimationStepIndex = calculateAnimationStepIndex(
        state, usedParentId, animationStepForElement.animationStepNum);

      if (oldAnimationStepIndex === undefined) {
        console.log("ERROR: no matching StepIndex found!");
        return;
      }

      let addNewAnimationStep = true;
      let newAnimationStepIndex = oldAnimationStepIndex + 1;
      // is there an existing next animation step for the usedParentId?
      if (animationStepCounter.lastIndex > oldAnimationStepIndex) {
        // then we have a next location somewhere ...
        for (let i = oldAnimationStepIndex + 1; i < state.animationData.length; i++) {
          const animationStep = state.animationData[i];
          if (animationStep.id == usedParentId) {
            newAnimationStepIndex = i;
            addNewAnimationStep = false;
            break;
          }
        }
      }

      const newAnimationData = produce(state.animationData, draft => {

        // check, if there is already a new step .... (todo)
        let newAnimationDataStep;

        if (addNewAnimationStep) {
          newAnimationDataStep = { id: usedParentId, animation: {} };
        } else {
          newAnimationDataStep = draft[newAnimationStepIndex];
          if (newAnimationDataStep.animation === undefined) {
            newAnimationDataStep.animation = {};
          }
        }

        const oldAnimationStep = draft[oldAnimationStepIndex];

        newAnimationDataStep.animation[data] = { ...oldAnimationStep.animation[data] };

        // remove the old animation
        delete oldAnimationStep.animation[data];

        draft.splice(newAnimationStepIndex, (addNewAnimationStep ? 0 : 1), newAnimationDataStep);

      });

      store.dispatch('setAnimationData', newAnimationData);
      store.dispatch('gotoSlide', { slideNum: newAnimationStepIndex });
    }

  });

  store.on('decreaseAnimationStep', (state, data) => {

    // what do we expect:
    // animationData is present (state.animationData)
    // animationSteps are present ... (state.animationSteps)
    // slideElementMap is present (state.slideElementMap)

    // data just contains the element.id
    // we should be able to do a lookup and find the parentId

    const lookupResult = state.slideElementMap[data];

    const usedParentId = lookupResult.parentId ? lookupResult.parentId : "mainview_0";


    const animationStepCounter = state.animationSteps.animationStepCounter[usedParentId];
    const animationStepForElement = state.animationSteps.animationStepMap[data];

    if (animationStepCounter && animationStepForElement) {

      const oldAnimationStepIndex = calculateAnimationStepIndex(
        state, usedParentId, animationStepForElement.animationStepNum);

      const removeAnimationStep = animationStepForElement.animationStepNum == 0;

      let gotoSlideNum = oldAnimationStepIndex;

      const newAnimationData = produce(state.animationData, draft => {

        const oldAnimationStep = draft[oldAnimationStepIndex];
        const oldAnimation = oldAnimationStep.animation[data];
        delete oldAnimationStep.animation[data];

        if (!removeAnimationStep) {
          // then we have to attach the animation to a previous step ...
          for (let i = oldAnimationStepIndex - 1; i >= 0; i--) {
            const prevAnimationStep = draft[i];
            if (prevAnimationStep.id == usedParentId) {
              if (prevAnimationStep.animation === undefined) {
                prevAnimationStep.animation = {};
              }
              prevAnimationStep.animation[data] = oldAnimation;
              break;
            }
          }
        }

        if (animationStepCounter.counter > 0 && Object.keys(oldAnimationStep.animation).length == 0) {
          // remove the step completely, if there is a previous one, and if there are no other
          // animations active at this step.
          draft.splice(oldAnimationStepIndex, 1);
          gotoSlideNum--;
        }

      });

      if (removeAnimationStep) {
        const newSlideData = produce(state.slideData, draft => {
          const element = navigateToElement(draft, lookupResult.parentIds);
          delete element.animation;
        });
        store.dispatch('setSlideAndAnimationData', {
          slideData: newSlideData,
          animationData: newAnimationData
        });
      } else {
        store.dispatch('setAnimationData', newAnimationData);
      }
      store.dispatch('gotoSlide', { slideNum: gotoSlideNum });
    }
  })

  store.on('setAnimationData', (state, data) => {

    if (data != state.animationData) {

      pushStateOnUndoStack(state);

      // if animationData has changed, we need to recreate the previews
      // new position, maybe a change in animations/visibility, etc.
      store.dispatch('createPreviewComponentsAndData', {
        ...state, animationData: data
      });

      // check, if the currentSlideNum is still valid ...
      // goes to a previous animation step if necessary ...
      store.dispatch('adjustCurrentSlideNum', data.length);

      const hash = sha1(JSON.stringify({ data: { slideData: state.slideData, animationData: data } })).toString();

      // precalculate the animation-numbers ...
      const animationSteps = calculateAnimationSteps(data, state.slideElementMap);

      return { animationData: data, hash, animationSteps };
    }
  });

  store.on('addText', (state, data) => {

    // data is the id of the edited slide.
    // (or null, if we are in global mode)

    let newElement;
    let parentId;

    let newSlideData = produce(state.slideData, draft => {

      const parentContext = getParentContextForAdd(draft, state, data.parentId, data.newId);
      parentId = parentContext.parentId;
      const newId = parentContext.newId;
      newElement = data.content || {
        id: newId, type: "Text", positionX: 300, positionY: 300, width: 300,
        height: 200, text: "Some new text"
      };

      // Todo -> update the slideElementMap ...
      parentContext.content.push(newElement);

    });

    // now we create the actual component (Preact)
    const newTextComponent = updateSingleElementNoChildren(newElement, parentId);

    const result = addComponentToState(parentId, state, newSlideData, newTextComponent, newElement);
    result.slideData = newSlideData;

    // We could optimize the update in the previews to just add the text-element
    // but we just do a "recalculation" of the previews
    result.needNewAnimationSteps = true;
    store.dispatch('setAllData', result);


  });

  store.on('addBulletpoints', (state, data) => {

    let newElement;
    let parentId;
    let newId;

    let animation = true;

    const newSlideData = produce(state.slideData, draft => {

      if (data == null) {
        // data is null, if we are adding bulletpoints to the mainview
        // (not within slides)
        animation = false;
      }

      const parentContext = getParentContextForAdd(draft, state, data);
      parentId = parentContext.parentId;
      newId = parentContext.newId;

      newElement = {
        id: newId, animation, type: "Bulletpoints", positionX: 300, positionY: 300, width: 300,
        height: 200, content: ["One", "..."]
      };
      parentContext.content.push(newElement);
    });


    const newBulletpointsComponent = updateSingleElementNoChildren(newElement, parentId);

    const result = addComponentToState(parentId, state, newSlideData, newBulletpointsComponent, newElement);
    result.slideData = newSlideData;

    let addedNewAnimationStep = false;
    if (animation) {

      const newAnimationData = produce(state.animationData, draft => {

        let animationStep = draft[state.navigationState.currentSlideNum];
        if (animationStep.animation === undefined) {
          animationStep.animation = {};
        }
        if (Object.keys(animationStep.animation).length > 0) {
          // there is already an animation going on at this step ...
          // we have to put in a new animationstep ...
          let newStep = { id: data, animation: {} };
          newStep.animation[newId] = {};
          draft.splice(state.navigationState.currentSlideNum, 0, newStep);
          addedNewAnimationStep = true;
        } else {
          animationStep.animation[newId] = {};
        }
      });
      result.animationData = newAnimationData;
    }

    result.needNewAnimationSteps = true;
    store.dispatch('setAllData', result);
    if (addedNewAnimationStep) {
      store.dispatch('gotoSlide', { slideNum: state.navigationState.currentSlideNum + 1 });
    } else {
      store.dispatch('current');
    }
    return;

  });

  store.on('addImage', (state, data) => {

    let newElement;
    let parentId;

    const newSlideData = produce(state.slideData, draft => {

      const parentContext = getParentContextForAdd(draft, state, data.parentId);
      parentId = parentContext.parentId;
      const newId = parentContext.newId;

      newElement = {
        id: newId, type: "Image", positionX: 100, positionY: 100, width: data.width, height: data.height,
        dataURL: data.dataURL
      };
      parentContext.content.push(newElement);
    });

    const newImageComponent = updateSingleElementNoChildren(newElement, parentId);

    const result = addComponentToState(parentId, state, newSlideData, newImageComponent, newElement);
    result.slideData = newSlideData;

    result.needNewAnimationSteps = true;
    store.dispatch('setAllData', result);

  });

  /**
   * data must be { parentId: ?, currentSlideNum : number }
   */
  store.on('addSlide', (state, data) => {
    // we add a slide ...
    // where does it appear in the preview?

    let parentId;
    let newElement;
    let newId;

    const newSlideData = produce(state.slideData, draft => {

      const parentContext = getParentContextForAdd(draft, state, data.parentId);
      parentId = parentContext.parentId;
      newId = parentContext.newId;
      
      newElement = {
        id: newId, type: "Slide", positionX: 300, positionY: 300, scale: 0.3, content: []
      };

      parentContext.content.push(newElement);
    });


    const newSlideComponent = updateSingleElementNoChildren(newElement, parentId);

    const result = addComponentToState(parentId, state, newSlideData, newSlideComponent, newElement);
    result.slideData = newSlideData;

    // make sure, that the new slide appears in animation ...
    const newLocation = data.currentSlideNum + 1;
    const newAnimationData = produce(state.animationData, draft => {
      draft.splice(newLocation, 0, { id: newId });
    });
    result.animationData = newAnimationData;
    result.needNewAnimationSteps = true;
    store.dispatch('setAllData', result);

    // add some title and text to the new slide 

    const newIdResult = getNewId(newId, store.get().slideElementMap, 0);
    const newIdTitle = newIdResult.newId;
    const newIdText = getNewId(newId, store.get().slideElementMap, newIdResult.i + 1).newId;

    store.dispatch('addText', {
      parentId: newId,
      newId: newIdTitle, content:
        { id: newIdTitle, type: "Title", positionX: 170, positionY: 70, width: 300, height: 100, text: "Title" }
    });

    store.dispatch('addText', {
      parentId: newId,
      newId: newIdText, content: { id: newIdText, type: "Text", positionX: 230, positionY: 300, width: 400, height: 300, text: "Some text... " }
    });

  })

  store.on('rotateElement', (state, data) => {

    const newSlideData = produce(state.slideData, draft => {

      const elementId = data.elementId;
      const position = data.position;
      const rotation = data.rotation;

      const element = navigateToElement(draft, state.slideElementMap[elementId].parentIds);

      let posData = element;
      if (position != 0) {
        posData = element.otherPositions[position - 1];
      }
      if (posData.rotation === undefined) {
        posData.rotation = [{ z: rotation }];
      } else {
        // find the rotation ...
        let rotIndex = -1;
        if (Array.isArray(posData.rotation)) {
          posData.rotation.forEach((el, index) => {
            if (el.z !== undefined) {
              rotIndex = index;
            }
          })
        }
        if (rotIndex != -1) {
          posData.rotation[rotIndex].z = posData.rotation[rotIndex].z + rotation;
        } else {
          posData.rotation.push({ z: rotation });
        }
      }
    });

    const lookupResult = state.slideElementMap[data.elementId];
    const result = handleSingleUpdate(newSlideData, lookupResult, state);
    if (result) {
      result.needNewAnimationSteps = false;
      store.dispatch('setAllData', result);
    }
  });


  // I get some edited slide-data (potentially dangerous)
  store.on('changeSlideData', (state, data) => {

    // data contains elementId
    // and data contains data 

    if (!data.elementId) {
      // we have no elementId, this means, we have the whole data ...
      store.dispatch('setSlideAndAnimationData', { slideData: data.data });
      return;
    }

    const lookupResult = state.slideElementMap[data.elementId];
    if (!lookupResult) {
      return;
    }

    const newSlideData = produce(state.slideData, draft => {

      const arrCopy = [...lookupResult.parentIds];
      const locationInParent = arrCopy.pop();

      let content;
      if (arrCopy.length == 0) {
        // then the element is part of the main_view ...
        content = draft;
      } else {
        content = navigateToElement(draft, arrCopy).content;
      }

      content[locationInParent] = data.data;

    });

    const result = handleSingleUpdate(newSlideData, lookupResult, state);
    if (result) {
      result.needNewAnimationSteps = true;
      store.dispatch('setAllData', result);
      store.dispatch('current');
    }



  });

  // can only be set together.
  store.on('setSlideAndAnimationData', (state, data) => {

    const slideDataSame = data.slideData == state.slideData;
    const animationDataSame =
      data.animationData === undefined ||
      data.animationData == state.animationData;


    const result = {};
    if (!slideDataSame) {
      // we generate the element map.
      const slideElementMap = {};
      generateSlideElementMap(data.slideData, null, slideElementMap, [], undefined, state.slideElementMap);

      const frameChildren = [];
      const viewComponentChildren = {};
      // we have to create the children individually ....
      data.slideData.forEach(slideDataElement => frameChildren.push(createSlides(slideDataElement, slideElementMap, undefined, viewComponentChildren) ));
      

      result.slideData = data.slideData;
      result.slideElementMap = slideElementMap;
      result.frameChildren = frameChildren;
      result.viewComponentChildren = viewComponentChildren;

    }
    if (!animationDataSame) {
      result.animationData = data.animationData;
    }

    if (Object.keys(result).length > 0) {

      pushStateOnUndoStack(state);
      result.hash = sha1(JSON.stringify({ data: { slideData: data.slideData, animationData: data.animationData } })).toString();


      result.animationSteps = calculateAnimationSteps(result.animationData === undefined ? state.animationData : result.animationData,
        (result.slideElementMap === undefined ? state.slideElementMap : result.slideElementMap));

      store.dispatch('createPreviewComponentsAndData', { ...state, ...result });

      return result;
    }


  });


  /**
   * we want to add a view 
   */
  store.on('addCustomView', (state, data) => {

    // What about adding a view from "globalView"???

    // data : vmat -> the additional view-matrix 
    // data : id -> base of the current view (what happens if no view (Global Mode))
    // data : width -> the width of the current view
    // data : height -> the height of the current view

    const id = state.animationData[state.navigationState.currentSlideNum].id;
    const lookupResult = state.slideElementMap[id];


    const newId = getNewId(id + "_v", state.slideElementMap).newId;
    const newView = {
      id: newId, type: "View", vmat: data.vmat,
      positionX: data.width / 2, positionY: data.height / 2,
      width: data.width, height: data.height
    };

    const newAnimation = { id: newId, scale: data.scale };
    if (state.animationData[state.navigationState.currentSlideNum].positions !== undefined) {
      newAnimation.positions = { ...state.animationData[state.navigationState.currentSlideNum].positions };
    }
    if (state.animationData[state.navigationState.currentSlideNum].invisible !== undefined) {
      newAnimation.invisible = [...state.animationData[state.navigationState.currentSlideNum].invisible];
    }

    let positionInParent;
    const newSlideData = produce(state.slideData, draft => {

      // lookup the element to change in the mutable copy of the original slideData 
      const element = navigateToElement(draft, lookupResult.parentIds);
      if (element.content === undefined) {
        element.content = [];
      }
      element.content.push(newView);
      positionInParent = element.content.length - 1;
    });


    const newAnimationData = produce(state.animationData, draft => {
      draft.splice(state.navigationState.currentSlideNum + 1, 0, newAnimation);
    });

    const mats = updateSingleElementNoChildren(newView, id);

    const newSlideElementMap = { ...state.slideElementMap };
    newSlideElementMap[newId] = {
      element: newView, parentId: id,
      parentIds: [...lookupResult.parentIds, positionInParent], mats
    };
    // We have to update the element of the parent in the slideElementMap as well!!!!

    const result = {
      slideData: newSlideData, animationData: newAnimationData,
      slideElementMap: newSlideElementMap,
      recalculatePreviewElements: true
    };

    result.needNewAnimationSteps = true;
    store.dispatch('setAllData', result);

  });

  // Documentation of animationData 

  // id : string (mast be part of slideData)
  // transitionDuration : N
  // transition : "fade-out-in"
  // deactivateAnimation: [] 
  // animation
  //     { "id" : { } , .... }
  //                active : [], position: N, reset: false
  store.on('@init', () => {

    return {
      slideElementMap: {},
      frameChildren: [],
      viewComponentChildren: {},
      slideData: [],
      animationData: [],
      animationSteps: {}
    }
  });
}

export { slideData, navigateToElement, navigateToParentContent, generateSlideElementMap };


