import { calculateAnimationsForSlideNum, dispatchActivations } from '../utils/animationLogic';

import produce from 'immer';

/**
* Contains information about activated objects. For each object, the following attributes are allowd
*
 * @typedef Activated 

 * @type {object}
 * 
 * @property {object} active - used to define, which bullet-point elements are currently active
 * @property {array} position - used to define, which bullet-point elements are currently active
 * 
 *  */

/**
 * 
 * @typedef VisibilityData
 * @type {object}
 * @property {Activated} activated
 * @property {array} invisible
 * @property {object} position
 * 
 * 
 */


/** so here we check, if the individual animation has really changed ... */
function handleResult(state,result,data) {
  Object.keys(data).forEach( key => {
    const oldvalue = state['visibilityData_' + key];
    if (oldvalue === undefined) {
      result['visibilityData_' + key] = { activated : data[key] };
    } else { 
      // check, if the value has changed!
      // here we allow for a JSON-Stringify ... 
      if ( JSON.stringify(oldvalue.activated) != JSON.stringify(data[key])) {
        result['visibilityData_' + key] = { ...oldvalue, activated : data[key] };
      }
    }
  })

  return result;    

 }

/**
 * @var visibilityData
 * 
 * A store used by elements to access if and how they are currently displayed
 * 
 * 
 * @param {*} store 
 */
const visiblityData = store => {
  store.on('@init', () => (
    {
      visibilityData: { invisible: [], positions: {}, activated: {} },
      currentAnimation: {}
    }
  ));

  store.on('setInvisible', (state, data) => {
    const result = { visibilityData : { 
      ...state.visibilityData, invisible : data }};

      // we have to remove the "old" individual invisible
      // states ....
      
      Object.keys(state).forEach(key => {
        if (key.indexOf('visibilityData_') == 0 &&
        !data.includes(key.substr('visibilityData_'.length))) {
          if (state[key].invisible !== undefined) { 
            result[key] = { ...state[key], invisible : undefined };
          }
        }
      })

      data.forEach( key => {
        const oldvalue = state['visibilityData_' + key];
        if (oldvalue === undefined) {
          result['visibilityData_' + key] = { invisible : true };
        } else {
          // check, if the value has really changed ..
          if (oldvalue.invisible != true) {
            result['visibilityData_' + key] = { ...oldvalue, invisible : true };
          }
        }
      })
      return result;

  });

  store.on('setPositions', (state, data) => {
    
    const result = { visibilityData : {
      ...state.visibilityData, positions : data }
    };
    // we have to remove the "old" individual positions
    // states ...

    const data_keys = Object.keys(data);

    Object.keys(state).forEach(key => {
      if (key.indexOf('visibilityData_') == 0 &&
      !data_keys.includes(key.substr('visibilityData_'.length))) {
        // deleting position for elements, that are not part of the
        if (state[key].position !== undefined) { 
          result[key] = { ...state[key], position : undefined };
        }
      }
    })

    Object.keys(data).forEach( key => {
      const oldvalue = state['visibilityData_' + key];
      if (oldvalue === undefined) {
        result['visibilityData_' + key] = { position : data[key] };
      } else { 
        // check, if the new position value has actually changed.
        if (data[key] != oldvalue.position) {
          result['visibilityData_' + key] = { ...oldvalue, position : data[key] };
        }
      }
    })
    return result;    

  });
  store.on('setActivated', (state, data) => {
    const result = { visibilityData : 
      { ...state.visibilityData, activated: data }
    };

    const data_keys = Object.keys(data);

    // we have to remove the "old" individual activations
    // states ...
    Object.keys(state).forEach(key => {
      if (key.indexOf('visibilityData_') == 0 &&
      !data_keys.includes(key.substr('visibilityData_'.length))) {
        // check, if activation has changed:
        if (state[key].activated !== undefined) { 
          result[key] = { ...state[key], activated : undefined };
        }
      }
    });
    return handleResult(state,result,data);
  });
  store.on('addActivated', (state, data) => {
    const result = { visibilityData : { ...state.visibilityData,
      activated : { ...state.visibilityData.activated, ...data }}
    };
    return handleResult(state,result,data);
    
  });
  store.on('removeActivated', (state, data) => {
    const newVisibilityData = produce( state.visibilityData, draft => {
      delete draft.activated[data];
    });
    if (newVisibilityData !== state.visibilityData) {
      const result = { visibilityData : newVisibilityData};
      
      const oldvalue = state['visibilityData_' + data];
      if (oldvalue === undefined) {
        // should not happen! .. we are not removing an activity
        // that was not active previously ...
      } else {
        result['visibilityData_' + data] = {
          ...oldvalue, activated: undefined
        };
      }
      return result;
    }
  });
  store.on('resetActivated', (state) => {
    const newVisibilityData = produce( state.visibilityData, draft => {
      draft.activated = {};
    });
    if (newVisibilityData !== state.visibilityData) {

      const result = { visibilityData : newVisibilityData};

      Object.keys(state).forEach(key => {
        if (key.indexOf('visibilityData_') == 0) {

          let oldvalue = state[key];
          result[key] = {
              ...oldvalue, activated: undefined
            };
        }
      });
      return result;
    }
  });
  store.on('setCurrentAnimation', (state, data) => {
    if (data != state.currentAnimation) {
      return { currentAnimation: data };
    }
  });

  store.on('gotoSlide', (state, data) => {

    const slideNum = data.slideNum;

    
    store.dispatch('setCurrentAnimation', {});
    store.dispatch('setVisibleId', undefined);

    if (!state.animationData || !Array.isArray(state.animationData) || slideNum < 0 || slideNum >= state.animationData.length) {
      return;
    }

    // figure out the animations for the slide, that we want to go to
    // if we want to go to the end of the animation, we just add one to the slideNum ...

    store.dispatch('setActivated', calculateAnimationsForSlideNum(state.animationData, state.slideElementMap, 
      slideNum + (data.isEnd !== undefined ? 1 : 0 )));

    dispatchActivations(store, state.animationData[slideNum], (data.isEnd !== undefined ? 'prev' : undefined));

    // the previousSlideNum is only checked for fade-out-in effects
    // to make sure that going backward uses the same animation ...
    store.dispatch('setPreviousSlideNum', -1);
    store.dispatch('setCurrentSlideNum', slideNum);

    if (state.globalData.isPresentationMode) {
      store.dispatch('setMoveZoomMode', false);
    }

  });

  store.on('next', (state) => {

    store.dispatch('setVisibleId', undefined);

    if (Object.keys(state.currentAnimation).length > 0) {

      // increment counters (if present) 
      let isDone = true;

      const newCurrentAnimation = produce( state.currentAnimation, draft => {

        Object.keys(draft).forEach(id => {
          if ('counter' in draft[id]) {
            if (draft[id].counter + 1 <= draft[id].maxcounter) {
              draft[id] = {
                counter: draft[id].counter + 1,
                maxcounter: draft[id].maxcounter
              };
              isDone = false;
            }
          }
        });
      });

      if (!isDone) {

        store.dispatch('setCurrentAnimation', newCurrentAnimation);

        dispatchActivations(store, state.animationData[state.navigationState.currentSlideNum]);

        if (state.globalData.isPresentationMode) {
          store.dispatch('setIsMoveZoomMode', false);
        }

        return;
      }
      // no more currentAnimations!
      store.dispatch('setCurrentAnimation', {});

    }

    const previousSlideNum = state.navigationState.currentSlideNum;
    let currentSlideNum = previousSlideNum + 1;
    if (currentSlideNum >= state.animationData.length) {
      currentSlideNum = 0;
      // we clean all activations, because we start at null ....
      store.dispatch('resetActivated');
    }

    dispatchActivations(store, state.animationData[currentSlideNum]);

    store.dispatch('setPreviousSlideNum', previousSlideNum);
    store.dispatch('setCurrentSlideNum', currentSlideNum);

    if (state.globalData.isPresentationMode) {
      store.dispatch('setIsMoveZoomMode', false);
    }

  });

  store.on('prev', (state) => {

    // we rerturn to the currentSlideNum-1 (and no longer another clicked/visible slide)
    store.dispatch('setVisibleId',undefined);

    let isDone = true;

    if (Object.keys(state.currentAnimation).length > 0) {
      // there are animations going on right now ...

      // first we need to find out the maximum counter value ..
      // (we can only decrement counters, which are equal)
      const newCurrentAnimation = produce( state.currentAnimation, draft => {

        let currentMaxCounter = -1;

        Object.keys(draft).forEach(id => {
          if ('counter' in draft[id] &&
          draft[id].counter > currentMaxCounter) {
            currentMaxCounter = draft[id].counter;
          }
        });
        Object.keys(draft).forEach(id => {
          if ('counter' in draft[id] &&
              draft[id].counter - 1 >= 0) {
            if (draft[id].counter == currentMaxCounter) {
              draft[id] = {
                counter: draft[id].counter - 1,
                maxcounter: draft[id].maxcounter
              };
            }
            isDone = false;
          } else {
            if (currentMaxCounter <= 0) {
              // make sure that we remove a manual activation at the last
              // auto-animation-step ...
              store.dispatch('removeActivated', id);
              delete draft[id];

            }
          }
        });
      });
      if (!isDone) {
        store.dispatch('setCurrentAnimation', newCurrentAnimation);
        if (state.globalData.isPresentationMode) {
          store.dispatch('setIsMoveZoomMode', false);
        }
        dispatchActivations(store, 
          state.animationData[state.navigationState.currentSlideNum], 
          'prev');
        return;
      }
      store.dispatch('setCurrentAnimation', newCurrentAnimation);
    }

    const previousSlideNum = state.navigationState.currentSlideNum;
    let currentSlideNum = previousSlideNum - 1;
    if (currentSlideNum < 0) {
      currentSlideNum = state.animationData.length - 1;
    }

    store.dispatch('setActivated', calculateAnimationsForSlideNum(state.animationData, state.slideElementMap, currentSlideNum));

    dispatchActivations(store, state.animationData[currentSlideNum], 'prev');

    store.dispatch('setPreviousSlideNum', previousSlideNum);
    store.dispatch('setCurrentSlideNum', currentSlideNum);

    if (state.globalData.isPresentationMode) {
      store.dispatch('setIsMoveZoomMode', false);
    }

  });

  store.on('current', (state) => {
    store.dispatch('setVisibleId', undefined);
    if (state.globalData.isPresentationMode) {
      store.dispatch('setIsMoveZoomMode', false);
    } else {
      // we are not in Presentation mode ...
      // do a reset of zoom and scroll 
      store.dispatch('gotoSlide', { slideNum : state.navigationState.currentSlideNum });
    }
  });
};

export default visiblityData;


