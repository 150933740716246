// import style from './style.css';
import { React ,h} from 'preact';

import { connectStoreon } from 'storeon/preact';

import Editmarker from '../editmarker';

import produce from 'immer';

import { md } from '../../utils/markdown';
import SlideElement from '../slideelement';

class Bulletpoints extends SlideElement {

    constructor() {
        super();
        this.state = {
            data: {
                visible: true, bulletsInvisible: [], position: 0,
                isParentSlideEdited: false, editMats: null, editCss: '',
                isSelected: false, prevContent: [],
                renderedListItems: []
            },
            prevVisibilityData : null
        };
    }


    static checkArrayEqualityOneDimension(array1, array2) {
        if (array1.length != array2.length ) {
            return false;
        }
        for (let key in array1) {
            if (array1[key] != array2[key]) {
                return false;
            }
        }
        return true;
    }

    static getDerivedStateFromProps(nextProps, state) {

        const { elementVisibilityData, doContinue } = 
            Bulletpoints.checkIfPropertyChangeNeedsHandling(nextProps,state);

        if (!doContinue) {
            return null;
        }

        // let draft = { ...state.data };

        const newData = produce(state.data, draft => {

            draft.editMats = null;
            draft.editCss = '';

            if (nextProps.content === undefined || !Array.isArray(nextProps.content) ||
                nextProps.content.length < 1) {
                draft.visible = "false";
                return;
            }

            let bulletsInvisible = [];
            
            if (nextProps.elementId !== undefined) {
                
                /**
                 * If we are in "preview"-mode, the activation, position and invisible settings are
                 * transfered with nextProps.visibilityData.
                 * If we are not in "preview"-mode, the activation, position and invisible settings
                 * are transfered with an individual property, which is made up from
                 * visibilityData{_elementId}
                */

                let visible = true;
                if (nextProps.animation) {

                    // so the bulletPoint is managed ....

                    if (nextProps.isPreview) {
                        visible = nextProps.elementId in nextProps.visibilityData.activated;
                    } else {
                        visible = elementVisibilityData !== undefined && elementVisibilityData.activated !== undefined;
                    }

                    if (visible) {
                        const counter = nextProps.isPreview ? 
                            nextProps.visibilityData.activated[nextProps.elementId].counter :
                            elementVisibilityData.activated.counter;
                        
                        if (counter === undefined) {
                            const active = nextProps.isPreview ? 
                                nextProps.visibilityData.activated[nextProps.elementId].active :
                                elementVisibilityData.activated.active;
                            if (active === undefined) {
                                bulletsInvisible.push(...(Array.from({ length: nextProps.content.length }, (v, k) => k)));
                            } else {
                                for (let i = nextProps.content.length - 1; i >= 0; i--) {
                                    if (!active.includes(i)) {
                                        bulletsInvisible.push(i);
                                    }
                                } 
                            }    
                        } else {                            
                            for (let i = nextProps.content.length - 1; i > counter; i--) {
                                bulletsInvisible.push(i);
                            }
                        }
                    } else {
                        // not visible ....
                        bulletsInvisible.push(...(Array.from({ length: nextProps.content.length }, (v, k) => k)));
                    }

                } 

                if (visible) {
                    if (nextProps.isPreview) {
                            if (nextProps.visibilityData.invisible.includes(nextProps.elementId)) {
                                    visible = false;
                            }
                    } else {
                            if (elementVisibilityData !== undefined && elementVisibilityData.invisible == true) {
                                    visible = false;
                            }
                    }
                }
                if (!visible) {
                    bulletsInvisible.length = 0;
                    for (let i = 0; i < nextProps.content.length; i++) {
                        bulletsInvisible.push(i);
                    } 
                }

                draft.visible = visible;

                if (!Bulletpoints.checkArrayEqualityOneDimension(state.data.bulletsInvisible,bulletsInvisible)) {
                    draft.bulletsInvisible = bulletsInvisible;
                }

                let position;
                if (nextProps.isPreview) {
                        if (nextProps.elementId in nextProps.visibilityData.positions) {
                                position = nextProps.visibilityData.positions[nextProps.elementId];
                        }
                } else {
                        if (elementVisibilityData !== undefined &&  elementVisibilityData.position !== undefined) {
                                position = elementVisibilityData.position;
                        }
                }
                if (position !== undefined) {
                        draft.position = position;
                } else {
                        draft.position = 0;
                }
            }

            SlideElement.handleEditData(draft,nextProps);

            // check on state and not on draft, because draft is a PROXY element! 
            // the proxy will not be equal ... even though the content is the same.
            if (state.data.prevContent != nextProps.content) {
                draft.renderedListItems = [];
                nextProps.content.forEach(el => {
                    draft.renderedListItems.push(md.render(el));
                });
                draft.prevContent = nextProps.content;
            }

        });
        if (newData != state.data) {
            return { data: newData, prevVisibilityData : elementVisibilityData };
        }
        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (this.state.data != nextState.data) {
            return true;
        }

        //  these properties do change - especially in Preview-Mode!
        return this.props.mats != nextProps.mats ||
            this.props.css != nextProps.css ;
    }

//    componentDidUpdate() {
//        if (this.state.data.isParentSlideEdited && this.state.data.isSelected) {
//            // this.editor.focus();
//        }
//    }

    setEditorRef(el) {
        if (el != null) {
            this.editor = el;
        }
    }
    focus() {
        // replace the shown list with other text ....
        if (this.lastEditedText == null) {
            this.lastEditedText = '';
            this.props.content.forEach(li => {
                this.lastEditedText += li + "\n";
            });
        }   
        this.editor.innerText = this.lastEditedText;
    }

    onInput(event) {
        if (this.state.data.isSelected) {
            event.eventElementId = this.props.elementId;
            event.text = this.editor.innerText;
            this.lastEditedText = event.text;
        }
    }

    onKeyDown(event) {
        // TODO: do this, when we are in true Text-Edit-Mode ...
        if (this.state.data.isSelected) {
            if (event.keyCode == 27) { // Escape Key
                // then we finish this editing and return to the previous value
                event.eventElementId = this.props.elementId;
                event.action = 'Escape';
                this.lastEditedText = null;
                return;
            }

            // stopPropagation is called to prevent frame from handling keyboard shortcuts ...
            event.stopPropagation();
        }
    }

    render(props, state) {

        if (state.data.isHiddenDueToEdit) {
            return null;
        }
        if (props.isPreview && !state.data.visible) {
            return null;
        }

        let styleString = SlideElement.basicRendering(props,state);

        let editMarker = null;
        let contentEditable = false;

        if (state.data.isParentSlideEdited) {
            contentEditable = state.data.isSelected;
            editMarker = <Editmarker elementId={props.elementId} parentId={props.parentId}
                scale={props.scale}
                animation={props.animation}
                selected={state.data.isSelected} />;
            const border = 1 * props.scale;
            styleString += `margin: -${border}px;`;
        }

        const items = [];
        state.data.renderedListItems.forEach((li, index) => {
            const itemStyle = 'opacity: ' + (state.data.bulletsInvisible.includes((index)) ? '0;' : '1;')
            items.push(<li style={itemStyle} dangerouslySetInnerHTML={{ __html: li }} />)
        });
        const list = <ul>
            {items}
        </ul>;

        const editor = contentEditable ? 
            <div ref={el => this.setEditorRef(el)}
                style='user-select: all; width: 100%; height: 100%;'
                onInput={ev => this.onInput(ev)}
                onMouseDown={ev => ev.stopPropagation()}
                onMouseUp={ev => ev.stopPropagation()}
                contentEditable='true'
                spellCheck='true'
                onFocus={ev => this.focus(ev)}
                onKeyDown={ev => this.onKeyDown(ev)}>{list}</div> : 
            null;

        return (
            <div class={
                'bulletpoints presentationelement '
                + (state.data.isParentSlideEdited ? ' edit' : '')}
                style={styleString}
                contentEditable='false'
                spellCheck='false'
            > {state.data.isSelected ? editor : list}
                { editMarker}
            </div >
        );
    }
}

const EditableBulletpoints = connectStoreon('editData', Bulletpoints);
const StaticBulletpoints = connectStoreon(Bulletpoints);

export { EditableBulletpoints, StaticBulletpoints };

