import { React  } from 'preact';
import { useStoreon } from 'storeon/preact';


function getJsonString(selectedElementId, slideElementMap, slideData ) {
    if (selectedElementId) {
        if (slideElementMap != null && slideElementMap[selectedElementId] !== undefined) {
            return JSON.stringify(slideElementMap[selectedElementId].element, null, 4);
        }
    } 
    if (slideData) {
        return JSON.stringify(slideData,null,4);
    }
    return '';
}

function SlideDataEditor() {

        const { dispatch, slideElementMap, editData, slideData } = useStoreon('slideElementMap', 'editData', 'slideData');

        const jsonString = getJsonString(editData.selectedElementId,slideElementMap,slideData);

        const applyChanges = function() {
            let newSlideDataString = document.getElementById('sde_' + editData.selectedElementId).value.trim();
            try {
                let newSlideData = JSON.parse(newSlideDataString);
                let data = { elementId : editData.selectedElementId, data : newSlideData };

                dispatch('changeSlideData', data );
            } catch(e) {
                alert(e);
            }
        }

        const keyDown = function(ev) {
            ev.stopPropagation();
        }
    
        const controlElements = <div style='position: absolute; top: calc(100% - 30px);' >
            <button onClick={applyChanges}>Apply</button> 
            <button onClick={ () => {dispatch('setIsShowSlideDataEditor', false)}}>Hide</button>
        </div>
        


        return <div style='position: absolute; left: 10px; width: 600px; height: 500px; overflow: hidden; resize: both;'
                onKeyDown={keyDown}>
                  <textarea id={'sde_' + editData.selectedElementId} style='resize: none; overflow: scroll; height: calc( 100% - 40px ); width: 100%' value={jsonString} />
            {controlElements}
        </div>;
    
}

export default SlideDataEditor;