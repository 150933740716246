import { React, Component, h } from 'preact';
import { connectStoreon } from 'storeon/preact';

class Editmarker extends Component {
    constructor() {
        super();
    }

    onMouseDown(event,action, data) {
        if (event.eventElementId === undefined) {
            event.eventElementId = this.props.elementId;
            event.action = action;
            event.data = data;
        }
        // this event will "bubble up" to the Slide-Component ...
    }

    addAnimation(event) {

        event.stopPropagation();
        event.preventDefault();
        
        const animationStepData = this.props.elementId;
        const parentData = this.props.animationSteps.animationStepMap[animationStepData];

        // we use "mainview_0" as parentId for elements with parentId == null
        const usedParentId = (parentData.parentId ? parentData.parentId : "mainview_0");
        
        const step = this.props.animationSteps.animationStepCounter[usedParentId];

        if (!step) {
            console.log("No animationStep found for " + usedParentId);
            return;
        }

        // check, if we can add the animation ....

        if (parentData.animationStepNum == '-') {
            this.props.dispatch('addAnimation', { 
                parentId : usedParentId, animationId : this.props.elementId });
        } else if (Number.isInteger(parentData.animationStepNum)) {
            // we could remove the complete Step ... 
        } else {
            console.log("Cannot do any animation due to complex animation");
        }
    }

    increaseAnimationStep(event) {
        this.props.dispatch('increaseAnimationStep', this.props.elementId );
        event.stopPropagation();
        event.preventDefault();
    }
    decreaseAnimationStep(event) {
        this.props.dispatch('decreaseAnimationStep', this.props.elementId );
        event.stopPropagation();
        event.preventDefault();
    }

    render(props) {

        if (props.selected) {
            // no moving or scaling, while an element is selected ....
            return <div style='position: absolute; transform: translate3d(0px,0px,0.1px); width:100%;height:100%; top:0px; left:0px; border : solid blue 2px; pointer-events: none;' />
        }

            let edgesWidthHeight = 20 / props.scale;
            if (edgesWidthHeight < 20) {
                edgesWidthHeight = 20;
            }
            
            const scaleEdgeStyle = `position: absolute; left:0px;top:0px; width: ${edgesWidthHeight}px;height: ${edgesWidthHeight}px;border-left-style: solid ; border-top-style: solid; border-top-color: blue; border-left-color: blue;pointer-events: auto;`;

            const widthEdgeStyle = `position: absolute; left: calc(100% - ${edgesWidthHeight}px);top: calc(50% - ( ${edgesWidthHeight}px / 2 )); width: ${edgesWidthHeight}px;height:${edgesWidthHeight}px;border-right-style: solid; border-right-color: blue;pointer-events: auto;`;

            const heightEdgeStyle = `position: absolute; left: calc(50% - (${edgesWidthHeight}px / 2));top: calc(100% - ${edgesWidthHeight}px); width: ${edgesWidthHeight}px;height:${edgesWidthHeight}px;border-bottom-style: solid; border-bottom-color: blue;pointer-events: auto;`;

            const moveEdgeStyle = `position: absolute; left:calc(50% - ${edgesWidthHeight}px); top:calc(50% - ${edgesWidthHeight}px);
            width: ${edgesWidthHeight}px;height:${edgesWidthHeight}px;line-height: 0;cursor:move; transform: translate3d(0px,0px,0.002px);pointer-events: auto;`;

            const animationEdgeStyle = `position: absolute; left: calc(100% - 35px); top:0px;transform: translate3d(0px,0px,0.002px);pointer-events: auto; cursor: pointer; height:${1.5*edgesWidthHeight}px;line-height:${1.5*edgesWidthHeight}px;font-size: ${1.5*edgesWidthHeight}px; color: darkgrey; background: ${props.animation ? 'blue' : 'grey'};`;

            const animationButtonsStyle = 'position: absolute; left: calc(100% - 50px); top:0px;transform: translate3d(0px,0px,0.002px);pointer-events: auto; cursor: pointer; height:30px;';

            // i want the icon to fill the edgesWidthHeigt ...
            // the font is 24px ....
            const inverseScale = edgesWidthHeight / 24;
            // let inverseScale = 1/props.scale;
            const moveIconStyle = `transform-origin: 50% 50%; transform: scale(${inverseScale}) ;  color: blue;`;

            // Important Stuff: "pointer-events: none;" is set, when we have selected the element.
            // prevents mouse-clicks to be captured ...
            // const editDivStyle = `position: absolute; transform: translate3d(0px,0px,0.1px); width:100%;height:100%; top:0px; left:0px; ${props.selected ? 'border : solid blue 2px; pointer-events: none;' : ''}`;
            const editDivStyle = 'position: absolute; transform: translate3d(0px,0px,0.1px); width:100%;height:100%; top:0px; left:0px;';
            const moveDiv =  
                <div class='edge' style={moveEdgeStyle}
                        onMouseDown={ev => this.onMouseDown(ev,'move')}
                  ><i  class="material-icons" style={moveIconStyle}>pan_tool</i></div>;

            const idDiv = props.globalData.isShowIds ? 
                <div style='position: absolute; top: -20px; font-size: 14px;'>{props.elementId}</div> : null;

            let stepNum = 'M';
            const animationStepData = props.animationSteps.animationStepMap[props.elementId];

            if (animationStepData === undefined)  {
                console.log("In Edit Data: no animationStep for " + props.elementId);
            } else {
                stepNum = animationStepData.animationStepNum;

            }
            return  <div style={editDivStyle} onMouseDown={ev => this.onMouseDown(ev,'select')}>
                    {idDiv}
                    {props.animation ? <div style={animationButtonsStyle}>
                        <button style='position: absolute; width:15px; height:15px;'
                            onMouseDown={ev=>this.increaseAnimationStep(ev)}>+</button>
                        <button style='position: absolute; top: 15px; width:15px; height:15px;'
                        onMouseDown={ev=>this.decreaseAnimationStep(ev)}>-</button>
                    </div> : null }
                    <div style={animationEdgeStyle} 
                        onMouseDown={ev=>this.addAnimation(ev)}>{stepNum}A</div>
                    <div class='edge' 
                           style={scaleEdgeStyle} 
                            onMouseDown={ev => this.onMouseDown(ev,'scale')}/>
                    <div class='edge' 
                           style={widthEdgeStyle}
                            onMouseDown={ev => this.onMouseDown(ev,'width')}/>
                    <div class='edge' 
                           style={heightEdgeStyle}
                            onMouseDown={ev => this.onMouseDown(ev,'height')}/>
                {moveDiv}
            </div>;
    }
}

export default connectStoreon('globalData','animationSteps',Editmarker);
