/**
 * 
 * Dependencies of navigationState: we assume, that state.animationData is available 
 * 
 * @param {*} state 
 * @param {*} visibleElementId 
 */

const searchForVisibleElementLocationBackwards = (state, visibleElementId) => {
  let stepNumInAnmiation = null;
  for (let i = state.navigationState.currentSlideNum - 1; i >= 0; i--) {
    if (state.animationData[i].id == visibleElementId) {
      stepNumInAnmiation = i;
      break;
    }
  }
  return stepNumInAnmiation;
}

const searchForVisibleElementLocationForward = (state, visibleElementId) => {
  let stepNumInAnmiation = null;
  for (let i = state.navigationState.currentSlideNum + 1; i < state.animationData.length; i++) {
    if (state.animationData[i].id == visibleElementId) {
      stepNumInAnmiation = i;
      break;
    }
  }
  return stepNumInAnmiation;
}


const navigationData = (store) => {
  store.on('@init', () => (
    {
      navigationData: {
        isAnimating: true,
        isFullScreen: false
      },
      navigationState: {
        currentSlideNum: 0,
        visibleId: undefined, // if another slide is selected to be "visible" instead of the currentSlideNum
        visibleSlideNum: undefined, // the location of the "visible" slide in the animation tree (if present)
        previousSlideNum: -1, // to manage fade-out-in transitions
      }
    }
  ));

  store.on('setCurrentSlideNum', (state, currentSlideNum) => {
    if (currentSlideNum != state.navigationState.currentSlideNum) {
      return {
        navigationState: {
          ...state.navigationState, currentSlideNum
        }
      }
    }
  });

  /**
   * This is called, when the shown Slide is different then the currently "Presented" Slide in 
   * the presentation. 
   * Happens, when someone clicks on another Slide in Presentation-Mode
   * Tries to find the location of the clicked slide in the animation-Data 
   * (the selected slide is shown using a different color in the "Slide-Circles")
   */
  store.on('setVisibleId', (state, visibleElementId) => {
    if (visibleElementId != state.navigationState.visibleId) {

      if (visibleElementId !== undefined) {
        const currentStepElementId = state.animationData[state.navigationState.currentSlideNum].id;
        if (currentStepElementId == visibleElementId) {
          // the visibleId shall be set to the slide that is in the current animation step
          // so we just return to the standard presentation 
          visibleElementId = undefined;
        }
      }

      if (visibleElementId != state.navigationState.visibleId) {
        let visibleSlideNum = undefined;
        if (visibleElementId != undefined) {
          // we need to find the visibleSlideNum ...
          visibleSlideNum = searchForVisibleElementLocationBackwards(state, visibleElementId)
            ?? searchForVisibleElementLocationForward(state, visibleElementId);
        }

        return { navigationState: { ...state.navigationState, visibleId: visibleElementId, visibleSlideNum } };
      }
    }
  });

  store.on('setPreviousSlideNum', (state, previousSlideNum) => {
    if (previousSlideNum != state.navigationState.previousSlideNum) {
      return {
        navigationState: {
          ...state.navigationState,
          previousSlideNum
        }
      };
    }
  });

  store.on('adjustCurrentSlideNum', (state, animationDataLength) => {
    if (state.navigationState.currentSlideNum >= animationDataLength) {
      // Does a recalculation of the visible elements.
      store.dispatch('gotoSlide', { slideNum: animationDataLength - 1 });
    }
  });

  store.on('setIsAnimating', (state, isAnimating) => {
    if (isAnimating != state.navigationData.isAnimating) {
      return {
        navigationData: {
          ...state.navigationData, isAnimating
        }
      };
    }
  });
  store.on('setIsFullScreen', (state, isFullScreen) => {
    if (isFullScreen != state.navigationData.isFullScreen) {
      return {
        navigationData: {
          ...state.navigationData, isFullScreen
        }
      };
    }
  });
}

export default navigationData;