// a collection of useful functions to calculate the animation logic


/**
 * 
 * @param {*} animationDataItem : An animation Step with id and animation, position and invisible
 * 
 * @param {*} animationIsStartingForSlide : calculate animations either for beginning 
 * of the animation step or at the very end (different when dealing with BulletPoints)
 * @param {*} previousActivated: the current State of activated objects. 
 * For each animated id it has information about current position, if the object is activated, 
 * or what items are actually activated. Example: {"1":{"active":[],"position":1},"40":{"active":[]},"pp1_iframe":{"active":[],"position":1},"pp1_b":{"counter":2,"maxcounter":2},"pp1_b2":{"active":[0,1,2]},"2_text":{"active":[],"position":1},"2a":{"active":[1,2],"position":0}} 
 * @param {*} slideElementMap: Used for looking up element-Data to determine, if an element is animated 
 * and what type it is (eg. Bulletpoints)
 */
const calculateActivationForOneSlide = function (animationDataItem, animationIsStartingForSlide,
    previousActivated, slideElementMap) {

    const activated = {};
    
    if (animationDataItem.animation !== undefined) {

        Object.keys(animationDataItem.animation).forEach(animationId => {

            const animatedElement = slideElementMap[animationId];

            if (animatedElement?.element?.animation !== undefined) { 
            //    && animatedElement.element !== undefined 
            //    && animatedElement.element.animation !== undefined) {
                // check if the element itself "wants" to be animated ...

                let position = null;
                let activationArray = null;
                let counter;

                // Copy the data from a previous activation (if present)
                if (previousActivated !== undefined) {
                    // copy the current position information so that it will be saved, if present
                    if (animationId in previousActivated) {

                        if ('position' in previousActivated[animationId]) {
                            position = previousActivated[animationId].position;
                        }
                        if ('active' in previousActivated[animationId]) {
                            activationArray = previousActivated[animationId].active;
                        }
                        if ('counter' in previousActivated[animationId]) {
                            counter = previousActivated[animationId].counter;
                        }
                    }
                }

                let newActivationArray = null;
                if ('active' in animationDataItem.animation[animationId] &&
                    Array.isArray(animationDataItem.animation[animationId].active)) {
                    // there is an activation array passed from "outside" - we just save that.
                    newActivationArray = animationDataItem.animation[animationId].active;
                }


                if ('position' in animationDataItem.animation[animationId] &&
                    Number.isInteger(animationDataItem.animation[animationId].position)) {
                    position = animationDataItem.animation[animationId].position;
                }

                let activatedSet = false;
                if (animatedElement.element.type == 'Bulletpoints') {
                    // then we go to the maximum ...

                    if (newActivationArray == null) {
                        let maxcounter = animatedElement.element.content.length - 1;

                        if ('reset' in animationDataItem.animation[animationId] &&
                            animationDataItem.animation[animationId].reset == false) {

                            // reset is activly set to false ..
                            if (counter == undefined) {
                                // we use the old activation
                            } else {
                                // convert the current counter value to activated ...
                                activationArray = [];
                                for (let i = 0; i <= counter; i++) {
                                    activationArray.push(i);
                                }
                            }
                            activated[animationId] = {
                                active:
                                    activationArray
                            };
                        } else {
                            const startValue = !animationIsStartingForSlide ? maxcounter : 0;

                            activated[animationId] = {
                                counter: startValue,
                                maxcounter
                            };

                        }
                        activatedSet = true;
                    }

                }

                if (!activatedSet) {

                    if (newActivationArray != null) {
                        // we put in some activation data
                        activated[animationId] = { active: newActivationArray };
                    } else {
                        activated[animationId] = {};
                    }
                }

                if (position != null) {
                    activated[animationId].position = position;
                }

            }
        });
    }
    if (animationDataItem.deactivateAnimation !== undefined) {
        animationDataItem.deactivateAnimation.forEach(animationId => {
            const animatedElement = slideElementMap[animationId];
            if (animatedElement?.element?.animation !== undefined) {
                delete activated[animationId];
            }
        });
    }
    return activated;
}

	/**
	 * This sets the animations so that the previous anmiations are taken into account
	 * @param {} slidenum 
	 */
const calculateAnimationsForSlideNum = function(animationData,slideElementMap,slidenum) {

		let activated = {};
		animationData.forEach( (animation,index) => {
			if (index >= slidenum) {
				return;
			}
			const animationIsStartingForSlide = false;
			const activatedForSlideStep = calculateActivationForOneSlide(animation,animationIsStartingForSlide, 
					activated, slideElementMap);

			activated = { ...activated, ...activatedForSlideStep};

			if (animation.deactivateAnimation !== undefined) {
				animation.deactivateAnimation.forEach( animationId => {
					const animatedElement = slideElementMap[animationId];
					if (animatedElement.element.animation !== undefined) {
							delete activated[animationId];
					}
				});
			}
        });
		return activated;
	}

/* calculates the current positions for all objects
       looks into animation and also fixed position set in animationData */
const calculateCompletePositions = function (animationDataPositions, activated) {
    // because of "Auto", positions maybe set differently and not as a "position" element,
    // we ask the current values in the store, what is currently being animated/activated.
    const caclulatedPositions = {};
    Object.keys(activated).forEach(id => {
        let position = activated[id].position;
        if (position !== undefined) {
            caclulatedPositions[id] = position;
        }
    });

    // the result is a mix of animated positions and positions which are set directly
    const positions = { ...caclulatedPositions, ...animationDataPositions };
    return positions;
}


const calculateCurrentAnimation = function(store, animationData, direction) {
    // now we have to deal with the animationId, if present ...
    let currentAnimation = store.get().currentAnimation;
    if (Object.keys(currentAnimation).length == 0) {

        // there is no animation going on, we are visiting
        // this slide "for the first" time;
        // for each animation-ID we have to create an animation-administration ...
        let isStartofSlide = !(direction !== undefined
            && direction == 'prev');

        // this does not handle deactivated elements ....
        currentAnimation = calculateActivationForOneSlide(animationData, isStartofSlide,
            store.get().visibilityData.activated, store.get().slideElementMap);
        store.dispatch('setCurrentAnimation', currentAnimation);
    }

    if (Object.keys(currentAnimation).length > 0) {
        // we have an anmiation going
        store.dispatch('addActivated', currentAnimation);
    }
    
}
const dispatchActivations = function (store, animationData, direction) {

    if (animationData === undefined) {
        store.dispatch('setActivated', {});
        store.dispatch('setInvisible', []);
        store.dispatch('setPositions', {});
        return;
    }

    calculateCurrentAnimation(store,animationData,direction);

    // do an animation-Deactivation ...
    if (animationData.deactivateAnimation !== undefined) {

        // for each animation we have to remove the activation ...
        animationData.deactivateAnimation.forEach(animationId => {
            store.dispatch('removeActivated', animationId);
        });

    }

    // invisible maybe replaced by "auto" later on ....
    store.dispatch('setInvisible',  animationData.invisible !== undefined ? animationData.invisible : []);

    // do the position stuff ...
    store.dispatch('setPositions', calculateCompletePositions(animationData.positions, store.get().visibilityData.activated));
}


export { calculateActivationForOneSlide, calculateAnimationsForSlideNum, calculateCompletePositions,  dispatchActivations }; 
