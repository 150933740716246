
import { connectStoreon, useStoreon } from 'storeon/preact';


import { React, Component, h } from 'preact';
import { useEffect, useRef, useState, useMemo } from 'preact/hooks';

import { produce } from 'immer';

import { presentation, allowWorkbench, isPreview } from './../../utils/constants';

const PageNumberDisplay = (props) => {

    const pageNumberRef = useRef('null');

    // this effect is called only once (when the component is "mounted")
    useEffect(() => {
        // this is called from the outside to navigate to 
        // a slide for preview-generation!
        const eventListener = e => {
            dispatch('gotoSlide', { slideNum: e.slideNum, isEnd: true })
        };
        pageNumberRef.current.addEventListener('gotoSlide', eventListener);

        return () => { pageNumberRef.current.removeEventListener('gotoSlide', eventListener) };

    }, []);

    const { navigationState, animationData, dispatch } = useStoreon('navigationState', 'animationData');

    let slideNumBackground = 'rgba(255,255,255,0.7)';
    let linethrough = 'none';

    const isVisible = navigationState.visibleSlideNum !== undefined;

    if (isVisible) {
        slideNumBackground = 'rgba(128,0,0,0.7)';
        linethrough = 'line-through';
    }

    const style = `${props.style}background-color: ${slideNumBackground};text-decoration: ${linethrough};`;

    return <div ref={pageNumberRef} id='pagenumbernavigation' style={style} 
            onClick={(ev) => { props.action(ev); ev.stopPropagation(); ev.preventDefault(); }}>
                {navigationState.currentSlideNum + 1}/{animationData.length}</div>
}

const AnimatedButtonNavigationData = (props) => {
    const { navigationData } = useStoreon('navigationData');

    let style = `${props.style}opacity: ${navigationData.isAnimating ? 0 : 0.4};`;

    if (props.isFullScreenButton) {
        style += `background-color: ${navigationData.isFullScreen ? 'lightblue' : 'lightgrey'};`;
    }

    return <div style={style} onClick={ (ev) => { props.action(ev); ev.stopPropagation(); ev.preventDefault(); }} >
        {props.children}</div>
}

class PreviewCircleBasis extends Component {

    constructor(props) {
        super(props);
        this.state = { data: { isSelected : false, hasOtherVisibleId  : false, isVisible: false } };
    }

    static getDerivedStateFromProps(props, prevstate) {

        const isSelected = props.navigationState.currentSlideNum >= props.startIndex &&
            props.navigationState.currentSlideNum <= props.endIndex;

        const hasOtherVisibleId = props.navigationState.visibleId !== undefined;

        const isVisible = props.navigationState.visibleSlideNum !== undefined &&
            props.navigationState.visibleSlideNum >= props.startIndex &&
            props.navigationState.visibleSlideNum <= props.endIndex;

        const newState = produce(prevstate.data, draft => {
            draft.isSelected = isSelected;
            draft.hasOtherVisibleId = hasOtherVisibleId;
            draft.isVisible = isVisible;
        });

        if (newState !== prevstate.data) {
            return { data: newState };
        }
        return null;
    }

    setCircleRef(el) {
        if (el != null) {
            this.circleRef = el;
        }
    }

    scrollIntoView() {
        if (this.circleRef != null && typeof this.circleRef.scrollIntoView === 'function') {
            this.circleRef.scrollIntoView({ block: 'nearest', behavior: 'auto' });
        }
    }

    componentDidMount() {
        if (this.state.data.isSelected) {
            this.scrollIntoView();
        }
    }
    componentDidUpdate() {
        if (this.state.data.isSelected) {
            this.scrollIntoView();
        }
    }

    shouldComponentUpdate(nextprops, nextstate) {
        return (this.state.data !== nextstate.data);
    }

    render(props, state) {
        let style = 'height: 15px; width: 15px; border-radius: 50%; border-color: darkgrey; border-width: 1.4px; border-style: solid;';
        if (state.data.isSelected) {
            if (state.data.hasOtherVisibleId) {
                style += 'background-color: grey;';
            } else {
                style += 'background-color: darkblue;';
            }
        } else {
            let color = 'background-color: white;';
            if (state.data.isVisible) {
                color = 'background-color: yellow;';
            }
            style += color;
        }

        const previewWithNumber = <div id={props.span_id} style="position: relative; margin-top: -15px; margin-left: 18px;">
            <img src={props.imgSrc} width="0" style="margin-top: 15px;" />
            <span style='position: absolute; top: 0px; left: 0px; background:white;'>{props.startIndex + 1}</span>
            {props.needsUpdate ? <span style='color: red; position: absolute; top: 50px; left: 50px; transform: rotateZ(-45deg);'>need update ... </span> : ''}
            {props.isUpdatingPreviews ? <span style='color: grey; position: absolute; top: 50%; left: 50%;'>Is Updating </span> : ''}
        </div>

        return <div class='circle' ref={el => { this.setCircleRef(el) }} style={style}
            onClick={() => props.dispatch('gotoSlide', { slideNum: props.startIndex })}>
            {previewWithNumber}
        </div>;
    }
}

const PreviewCircle = connectStoreon('navigationState', PreviewCircleBasis);

let PreviewImageArea = (props) => {

    // this is somewhat of a hack ... we have references to the image-components
    // and will call "scrollIntoView" when the PreviewImageArea is made available
    const { navigationState } = useStoreon('navigationState');
    useEffect( () => {
        props.images.forEach((el,index)=>{
            const isSelected = navigationState.currentSlideNum >= el.props.startIndex &&
                navigationState.currentSlideNum <= el.props.endIndex;
            if (isSelected) {
                // this is really needed .. otherwise it will not work 
                props.imageRefs[index].current.scrollIntoView();
                
            }
        });
    });
    return <div class="sidelineNoHover" style={props.style}>{props.images}</div>;
}

class PreviewImageBasis extends Component {

    constructor(props) {
        super(props);
        this.imageRef = null;
        this.state = { data : { isSelected : false, isVisible: false}};
    }

    static getDerivedStateFromProps(props,prevstate) {

        const isSelected = props.navigationState.currentSlideNum >= props.startIndex &&
            props.navigationState.currentSlideNum <= props.endIndex;

        const isVisible = props.navigationState.visibleSlideNum !== undefined &&
            props.navigationState.visibleSlideNum >= props.startIndex &&
            props.navigationState.visibleSlideNum <= props.endIndex;

        const nextstate = produce( prevstate.data, draft => {
            draft.isSelected = isSelected;
            draft.isVisible = isVisible;
        })
        if (nextstate !== prevstate.data) {
            return { data : nextstate } ;
        }
        return null;
    }

    setImageRef(el) {
        if (el != null) {
            this.imageRef = el;
        }
    }

    scrollIntoView() {
        if (this.imageRef != null && this.state.data.isSelected) {
            if ((typeof this.imageRef.scrollIntoView) == 'function') {
                this.imageRef.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
            }
        }
    }
    componentDidMount() {
        // the scrollIntoView will be called "from the outside" (PreviewImageArea)
        // The thing is, that we cannot detect, that a scroll is needed, when
        // the area is is created
    }

    componentDidUpdate() {
        // same applies as to componentDidUpdate 
        //this.scrollIntoView();
    }
    
    shouldComponentUpdate(nextprops,nextstate) {
        return (this.state.data !== nextstate.data);
    }

    render(props,state) {
        let imageBorderStyle = '';
        if (state.data.isSelected) {
            imageBorderStyle = 'border: solid blue 2px;';
        } else if (state.data.isVisible) {
            imageBorderStyle = 'border: solid yellow 1.5px;';
        }
        // we set the height of the image to a value so that the scrollIntoView works even though
        // the images are loaded "afterwards" 
        // (otherwise you get the effect, that the scroll just goes to the place-holder
        // and when the images load, it gets pushed down.
        // another solution would be to ge an event-listener to make sure that all
        // images are loaded and then to scroll to the position ...)
        const imageStyle = `position: relative;${imageBorderStyle}margin-top:5px;`;
        return (<div style={imageStyle} ref={el=>this.setImageRef(el)} onClick={() => props.selectSlide(props.startIndex)}>
            <img src={props.imgSrc} width="200" height="123" />
            <span style='position: absolute; top: 0px; left: 0px; background:white;'>{props.startIndex + 1}</span>
            {props.needsUpdate ? <span style='color: red; position: absolute; top: 50px; left: 50px; transform: rotateZ(-45deg);'>need update ... </span> : ''}
            {props.isUpdatingPreviews ? <span style='color: grey; position: absolute; top: 50%; left: 50%;'>Is Updating </span> : ''}
        </div>)
    }

}

const PreviewImage = connectStoreon('navigationState', PreviewImageBasis);

const Navigation = (props) => {

    const [showPreviewNoHover, setShowPreviewNoHover] = useState(false);

    const { previewHash, hash, globalData, previewData, dispatch } = useStoreon('previewHash', 'hash', 'globalData', 'previewData');

    const selectSlide = (slideNum) => {
        dispatch('gotoSlide', { slideNum });
        setShowPreviewNoHover(false);
    }

    const { circles, images, imageRefs } = useMemo( () => {

        // for the "noHover"-case (touch-input device) 
        // we get a list of images ...
        const myImages = [];
        const myImageRefs = [];

        // lets have a sideline with small circles 
        const myCircles = [];
        myCircles.push(<div style='height: 10px;' />);

        previewData.previewElements.forEach((previewElement, index) => {

            const needsUpdate = hash != previewHash;
            const isUpdatingPreviews = false;

            const imgSrc = `${presentation}/previews/${previewHash}_${("" + (previewElement.props.endIndex + 1)).padStart(3, '0')}.png`;
            const span_id = `c_s_id_${index}`;

            myCircles.push(<PreviewCircle
                imgSrc={imgSrc}
                span_id={span_id}
                startIndex={previewElement.props.startIndex}
                endIndex={previewElement.props.endIndex}
                dispatch={dispatch}
                isUpdatingPreviews={isUpdatingPreviews}
                needsUpdate={needsUpdate} />);

            myCircles.push(<div style='height: 9px;' />);

            const imageRef = useRef(null);
            myImageRefs.push(imageRef);
            myImages.push(<PreviewImage ref={imageRef} imgSrc={imgSrc}
                startIndex={previewElement.props.startIndex}
                endIndex={previewElement.props.endIndex}
                isUpdatingPreviews={isUpdatingPreviews}
                needsUpdate={needsUpdate}
                selectSlide={selectSlide} />)
        })

        myCircles.push(<div style='height: 100px;' />);
        
        return { circles: myCircles, images: myImages, imageRefs: myImageRefs };

    }, [previewData,previewHash, hash]); 

    let buttonPositionY = 10;
    const presentationButtonStyle = `position: absolute; border: solid black 1px; left: 20px; top : calc(${buttonPositionY}px); width : 70px; height: 40px; background-color: lightblue; font-size: 20px; text-align: center; line-height: 35px;vertical-align: middle; border-radius: 12px;opacity: 0.3;background-color : ${globalData.isPresentationMode ? 'lightblue' : 'lightgrey'};`;
    if (allowWorkbench) {
        buttonPositionY += 50;
    }

    const moveZoomButtonStyle = `position: absolute; border: solid black 1px; left: 20px; top : calc(${buttonPositionY}px); width : 70px; height: 40px; font-size: 16px; text-align: center; vertical-align: middle; border-radius: 12px;
    background-color : ${globalData.isMoveZoomMode ? 'lightblue' : 'lightgrey'};transition : opacity 500ms linear 0ms;`;

    buttonPositionY += 50;

    const toggleFullScreenButton = `position: absolute; border: solid black 1px; left: 20px; top : calc(${buttonPositionY}px); width : 70px; height: 40px; font-size: 16px; text-align: center; vertical-align: middle; border-radius: 12px; transition : opacity 500ms linear 0ms;`;

    buttonPositionY += 50;

    const toggleNavigationModeButton = `position: absolute; border: solid black 1px; left: 20px; top : calc(${buttonPositionY}px); width : 70px; height: 40px; font-size: 16px; text-align: center; vertical-align: middle; border-radius: 12px;
    background-color : ${globalData.isNavigationMode ? 'lightblue' : 'lightgrey'}; transition : opacity 500ms linear 0ms;`;

    const navBarHover = `position: absolute; left: calc( 100% - 100px); top : 0px; width : 100px; height: ${buttonPositionY + 70}px; background-color : rgba(0,0,0,0);`;

    const overViewMarker = `position: absolute; left: 20px; top: 20px; font-size: 20px; background: rgba(0,0,0,0.1); color: black; opacity : ${globalData.isShowGlobalView ? 0.4 : 0}; transition : opacity 500ms linear 0ms;`;

    const toggleSideLine = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        setShowPreviewNoHover(!showPreviewNoHover);
    }

    let sidelineToggle = null;
    let sideline = null;
    let sidelineNoHover = null;
    if (globalData.isPresentationMode && previewData.previewElements !== undefined) {
        
        const sidelineStyle = 'position: absolute; left: 10px; top: 33px; height: 95vh; overflow-y: scroll;';

        let sidelineStyleNoHover = 'position: absolute; left: 10px; top: 33px; width: 205px; height: 95vh; background: white; overflow-y: scroll;';

        if (showPreviewNoHover) {
            sidelineStyleNoHover += 'visibility: visible';
            sidelineNoHover = <PreviewImageArea style={sidelineStyleNoHover}
                images={images} imageRefs={imageRefs} />;

        } else {
            // sideline is visible (if the media query in index.css has visibilty set,
            // which happens, if we are not on a touch-device)
            sideline = <div class="sideline" style={sidelineStyle}>{circles}</div>;
        }

        const styleMenuIcon = `font-size:2.5em;${showPreviewNoHover ? 'background: white;' : ''}`;

        sidelineToggle = <div style='position: absolute; top: 3px; left:3px; opacity: 0.5; cursor: pointer; user-select: none;' 
            onClick={toggleSideLine}><i class="material-icons" style={styleMenuIcon}>menu</i></div>
    }

    const pageNumberDisplay = <PageNumberDisplay style={'position: absolute; left: calc(50% - 35px); top : calc(100% - 40px); width : 70px; height: 35px; color: black; font-size: 24px; line-height: 30px; text-align: center; vertical-align: middle; opacity: 0.8;'} action={props.current} />;

    const styleString = 'width: 0; height: 0;';

    if (isPreview) {
        // We are in preview-mode (for thumbnail generation ....); just the page-number
        // and the hidden controls. no other buttons ...
        return <div style={styleString}>
            {pageNumberDisplay}
        </div>;
    }


    return (
        <div style={styleString}>

            {sidelineToggle}
            {sideline}
            {sidelineNoHover}

            <div class='navButtonsTopRight' style={navBarHover}>

                {allowWorkbench ? <div style={presentationButtonStyle} 
                        onClick={ev => { props.togglePresentationMode(ev); ev.stopPropagation(); ev.preventDefault();}} >(p)res</div> : ''}

                <AnimatedButtonNavigationData style={moveZoomButtonStyle} action={props.turnOnMoveZoomMode}>(m)ove <br />zoom</AnimatedButtonNavigationData>

                <AnimatedButtonNavigationData style={toggleFullScreenButton} action={props.toggleFullScreen} isFullScreenButton>Full-<br />screen</AnimatedButtonNavigationData>

                <AnimatedButtonNavigationData style={toggleNavigationModeButton} action={props.toggleNavigationMode}>(n)avi.</AnimatedButtonNavigationData>

            </div>

            {globalData.isShowGlobalView ? (<div style={overViewMarker}>-- (G)lobal Overview turned on --</div>) : ''}

            <div style='position: absolute; border: solid black 1px; left: calc(100% - 80px); top : calc(100% - 50px); width : 70px; height: 40px; background-color: lightblue; font-size: 20px; line-height: 35px;text-align: center; vertical-align: middle; border-radius: 12px;opacity: 0.3;' onClick={ev => props.next(ev)} >(f)orw.</div>

            <div style='position: absolute; border: solid black 1px; left: calc(100% - 160px); top : calc(100% - 50px); width : 70px; height: 40px; background-color: lightblue; font-size: 20px; line-height: 35px;text-align: center; vertical-align: middle; border-radius: 12px;opacity: 0.3;' onClick={ev => props.current(ev)} >(c)urr.</div>

            <div style='position: absolute; border: solid black 1px; left: calc(100% - 240px); top : calc(100% - 50px); width : 70px; height: 40px; background-color: lightblue; font-size: 20px; line-height: 35px; text-align: center; vertical-align: middle; border-radius: 12px;opacity: 0.3;' onClick={ev => props.prev(ev)} >(b)ack.</div>

            {pageNumberDisplay}

            {globalData.isPresentationMode ? <div>
                <div id='next' style='position: absolute; top : calc(90vh); left: 55%; opacity: 0; color: black; font-size: 48pt;transition: opacity 500ms fade-in 0;'>&rarr;</div>
                <div id='prev' style='position: absolute; top : calc(90vh); left: 40%; opacity: 0; color: black; font-size: 48pt;transition: opacity 500ms fade-in 0;'>&larr;</div>
            </div> : ''}

        </div>
    );
}

export default Navigation;

    /*   useEffect( () => {
            let element = fullScreenElement.current;
            if (element !== null) {
                if (userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
                    element.className += ' hidden';
                } else if (userAgent.match(/iPad/i) && iOS().majorReleaseNumber < 12) {
                    element.className += ' hidden';
                } else if (userAgent.match(/iPad/i) && !iPadSafari) {
                    element.className += ' hidden';
                } else {
                    // element.addEventListener('click', _toggleFullScreen, false);
                }
            }
        }, []); */


