import { StoreContext } from 'storeon/preact'
import { React, Component , h} from 'preact'


import { FrameContainer } from './frame';
import { Preview } from './preview';
import Navigation from './navigation';

import { allowWorkbench, presentation } from './../utils/constants'

import store from '../stores/store';

let load = function () {
	store.dispatch('setSlideAndAnimationData', { slideData: [], animationData: [] });
	
	let search = '';
	if (window !== undefined && window.location !== undefined && window.location.search !== undefined) {
		search = window.location.search;
	}

	window.fetch(presentation + '/data' + search, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	}).then(res => {
		if (res.redirected) {
			window.location.href=res.url;
			return;
		}
		return res.json();
	}).then(data => {
		store.dispatch('setSlideAndAnimationData', data.data);

		store.dispatch('setPreviewHash',store.get().hash);
		let gotoData = {};
		let slideNum = 0;
		gotoData.slideNum = slideNum;
		// console.log("setting gotoData " + JSON.stringify(gotoData));
		store.dispatch('gotoSlide', gotoData );
	});
}


class App extends Component {

	constructor() {
		super();
		this.frameRef = null;
		this.frameWindowRef = null;
		this.previewColumnRef = null;
		this.opacityNext = this.opacityPrev = 0;
	}

	setFrameRef(el) {
		if (el != null) {
			this.frameRef = el;
		}
	}
	setFrameWindowRef(el) {
		if (el != null) {
			this.frameWindowRef = el;
		}
	}

	setPreviewColumnRef(el) {
		if (el != null) {
			this.previewColumnRef = el;
		}
	}

	toggleEditMode() {
		if (store.get().globalData.isPresentationMode) {
			return;
		}
		// we edit only the elements, that are part of the current slide/view!?
		// how do we do this?
		const isEditMode = !store.get().globalData.isEditMode;
		store.dispatch('setIsEditMode', isEditMode);

		store.dispatch('setIsShowAnimationEditor', false);
		store.dispatch('setIsShowSlideDataEditor', false);

	}

	setIsShowGlobalView(data) {
		store.dispatch('setIsShowGlobalView', data);
	}


	setIsNavigationMode(data) {
		store.dispatch('setIsNavigationMode', data);
	}

	toggleNavigationMode() {
		const newValue = !store.get().globalData.isNavigationMode;
		store.dispatch('setIsNavigationMode', newValue);
	}

	togglePresentationMode() {

		if (!allowWorkbench && !this.props.isTestMode) {
			return;
		}
		const isPresentationMode = !store.get().globalData.isPresentationMode;

		store.dispatch('setIsPresentationMode', isPresentationMode);
		store.dispatch('setIsMoveZoomMode', !isPresentationMode);

		if (store.get().globalData.isPresentationMode) {
			if (this.previewColumnRef != null) { this.previewColumnRef.hidden = true; }
			this.frameWindowRef.style.width = "100vw";
			// alternative Layout using direct positioning ...
			// documentp.getElementById("frameWindow").style.left = "0px";

			store.dispatch('setIsShowAnimationEditor', false);
			store.dispatch('setIsShowSlideDataEditor', false);

		} else {
			this.previewColumnRef.hidden = false;

			const previewWidth = store.get().globalData.previewWidth;
			const width = `calc( 100vw - ${previewWidth}px)`;
			this.frameWindowRef.style.width = width;

		}

		this.frameRef.resizeEvent();

	}

	toggleFullScreen() {

		let isFullScreen = false;

		if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else {
				if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else {
					if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					}
				}
			}
		} else {
			isFullScreen = true;
			const _element = document.documentElement;
			if (_element.requestFullscreen) {
				_element.requestFullscreen();
			} else {
				if (_element.mozRequestFullScreen) {
					_element.mozRequestFullScreen();
				} else {
					if (_element.webkitRequestFullscreen) {
						_element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
					}
				}
			}
		}


		/*
		let isFullScreen = true;
		if ((document.fullScreenElement && document.fullScreenElement !== null) ||
			(!document.mozFullScreen && !document.webkitIsFullScreen)) {
				isFullScreen = true;
			if (document.documentElement.requestFullScreen) {
				document.documentElement.requestFullScreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullScreen) {
				document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		} else {
			isFullScreen = false;
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
		*/
		store.dispatch('setIsFullScreen', isFullScreen);
	}


	turnOnMoveZoomMode() {
		// this will prepare the Frame to call a showSlide regardless of unchanged content ...
		store.dispatch('setIsMoveZoomMode', true);

	}


	keyListener(event) {

		if (store.get().globalData.isNavigationMode) {
			let handled = false;
			switch (event.code) {
				case 'ArrowLeft': handled = true;
					this.frameRef.rotateEvent('left');
					break;
				case 'ArrowRight': handled = true;
					this.frameRef.rotateEvent('right');
					break;
				case 'ArrowUp': handled = true;
					this.frameRef.rotateEvent('up');
					break;
				case 'ArrowDown': handled = true;
					this.frameRef.rotateEvent('down');
					break;
			}
			if (event.key == 'v') {
				handled = true;
				this.frameRef.createView();
			}
			if (handled) {
				event.stopPropagation();
				event.preventDefault();
				return;
			}
		}

		if (store.get().globalData.isEditMode && store.get().editData.isAddMode) {

			switch (event.key) {
				case 'b':
					store.dispatch('addBulletpoints', store.get().editData.editedSlide)
					break;
				case 't':
					// Text
					store.dispatch('addText',
						{ parentId: store.get().editData.editedSlide });
					break;
				case 's':
					// Slide 
					store.dispatch('addSlide', { parentId:  store.get().editData.editedSlide,
							currentSlideNum: store.get().navigationState.currentSlideNum });
					break;
				case 'i':
					store.dispatch('setIsShowIds', !store.get().globalData.isShowIds);
					break;
				case 'z':
					store.dispatch('undo');
					break;
				case 'a':
					store.dispatch('setIsShowAnimationEditor', !store.get().globalData.isShowAnimationEditor);
					break;
				case 'x':
					store.dispatch('setIsShowSlideDataEditor', !store.get().globalData.isShowSlideDataEditor);
					break;

			}

			store.dispatch('setIsAddMode', false);
			event.preventDefault();
			event.stopPropagation();
			return;
		}

		if (store.get().globalData.isMoveZoomMode && event.key == 'n') {
			// "Navigation"-Mode
			event.preventDefault();
			event.stopPropagation();
			this.setIsNavigationMode(!store.get().globalData.isNavigationMode);
			return;

		}

		if (store.get().globalData.isEditMode && event.key == 'a') {
			store.dispatch('setIsAddMode', true);
			event.preventDefault();
			event.stopPropagation();
			return;
		}

		if (event.key == 'p') {
			event.stopPropagation();
			store.dispatch('setIsShowGlobalView',false);
			// will also turn handle isMoveZoomMode (and turn off isNavigationMode if neccessary)
			this.togglePresentationMode();
		}

		if (event.key == 'f' || event.code == 'ArrowRight') {
			event.preventDefault();
			store.dispatch('setIsShowGlobalView',false);
			store.dispatch('next');
		}
		if (event.key == 'b' || event.code == 'ArrowLeft') {

			event.preventDefault();
			store.dispatch('setIsShowGlobalView',false);
			store.dispatch('prev');
		}

		if (event.key == 'c') {
			event.preventDefault();

			// this will prepare the Frame to call a showSlide regardless of unchanged content ...
			this.frameRef.setDoForceUpdate();
			store.dispatch('setIsShowGlobalView',false);
			store.dispatch('current');

		}

		if (event.key == 'm') {
			event.preventDefault();
			this.turnOnMoveZoomMode();
		}

		if (event.key == 'e' && !store.get().globalData.isPresentationMode) {
			event.preventDefault();
			store.dispatch('setIsShowGlobalView',false);
			this.toggleEditMode();
		}
		if (allowWorkbench && event.key == 'w') {
			if (store.get().globalData.isEditMode) {

				window.fetch(presentation + '/data', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						data: {
							slideData: store.get().slideData,
							animationData: store.get().animationData
						}
					})
				})
				.then(data => data.json())
				.then(data => {
						store.dispatch('setSlideAndAnimationData', data.data);
						
					})
				.then( () => {

					this.fetchPreviewOrPDF('/previews').then( (data) => {
						if (data.error) {
							console.log("We have an error; no previews available?");
							// TODO: handle this error!
						} else {
							// console.log("Setting preview hash ... to " + data.hash)
							// we should now be able to fetch the previews ...
							store.dispatch('setPreviewHash', data.hash);
						}

					})
					// we should now have the information on the previews ...
				})				
			}
		}

		// h for "PDF Handout"
		if (allowWorkbench && event.key == 'h') {
			if (store.get().globalData.isEditMode) {


				// we should now have the information on the previews ...
				this.fetchPreviewOrPDF('/pdf');
			}				
		}
	

		if (event.key == 'l') {
			if (store.get().globalData.isEditMode) {
				load();
			}
		}
		if (event.key == 'g') {
			event.preventDefault();
			this.setIsShowGlobalView(!store.get().globalData.isShowGlobalView);
		}

		if (!store.get().globalData.isPresentationMode && (event.key == '+' || event.key == '-')) {
			event.preventDefault();

			const newWidth = store.get().globalData.previewWidth + (event.key == '+' ? 10 : -10);
			if (this.previewColumnRef != null) { this.previewColumnRef.style.width = `${newWidth}px`; }

			const width = `calc( 100vw - ${newWidth}px)`;

			this.frameWindowRef.style.width = width;
			// alternate placement, if not using flow left leyout
			// document.getElementById("frameWindow").style.left = `${newWidth}px`;

			store.dispatch('setPreviewWidth', newWidth);

			this.frameRef.resizeEvent();
		}

		if (store.get().globalData.isEditMode && event.key == 'Delete') {
			if (store.get().editData.selectedElementId != null) {
				const selectedElementId = store.get().editData.selectedElementId;
				event.preventDefault();
				// remove the selected Element Id ... but there is more to it ...
				// we need to remove it from the animation as-well!

				//console.log("We could also delete all selected elements ");
				//console.log(store.get().editData.selectedElementsMap);

				store.dispatch('removeElement', selectedElementId);

				store.dispatch('setSelectedElement', { elementId: null });
			}
		}

		// 'r' (rotate)
		if (store.get().globalData.isEditMode && event.keyCode == 82) {
			if (store.get().editData.selectedElementId != null) {
				event.stopPropagation();
				event.preventDefault();

				let rotation = 5;
				if (event.shiftKey) {
					rotation = 1;
				}
				if (event.altKey) {
					rotation *= -1;
				}

				store.dispatch('rotateElement', {
					elementId: store.get().editData.selectedElementId,
					position: store.get().editData.selectedElementPosition, rotation
				});
			}
		}


	}


	async fetchPreviewOrPDF(urlTarget) {
		const slideNums = [];
		store.get().previewData.previewElements.forEach(previewElement => {
			slideNums.push(previewElement.props.endIndex);
		});

		const hash = store.get().hash;

		return window.fetch(presentation + urlTarget, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				data: {
					hash,
					slideNums
				}
			})
		})
			.then(data => data.json())
			.then(data => {
				console.log("Received " + JSON.stringify(data));
				if (data.error) {
					console.log("We have an error; no previews available?");
				} else {
					data.hash = hash;
				}
				return data;
			});
	}

	paste(ev) {

		if (store.get().globalData.isEditMode) {
			// then we procede with the paste
			if (ev.clipboardData == false ||  ev.clipboardData.items === undefined) {
				// then we do nothing
			} else {

				const items = ev.clipboardData.items;

				for (let i = 0; i < items.length; i++) {

					if (items[i].type.indexOf("image") >= 0) {

						// Create an abstract canvas and get context
						const mycanvas = document.createElement("canvas");
						const ctx = mycanvas.getContext('2d');

						// Create an image
						const img = new Image();

						// Once the image loads, render the img on the canvas
						img.onload = function () {
							// Update dimensions of the canvas with the dimensions of the image

							mycanvas.width = this.width;
							mycanvas.height = this.height;

							// Draw the image
							ctx.drawImage(img, 0, 0);

							const width = this.width;
							const height = this.height;

							let dataURL = mycanvas.toDataURL("image/png");
							let dataToSend = {
								parentId: store.get().editData.editedSlide,
								width, height, dataURL
							};
							store.dispatch('addImage', dataToSend);
						};

						// Crossbrowser support for URL
						const URLObj = window.URL || window.webkitURL;
						const blob = items[i].getAsFile();
						// Creates a DOMString containing a URL representing the object given in the parameter
						// namely the original Blob
						img.src = URLObj.createObjectURL(blob);
					}
				}
			}
		}
	}

	touchStart(ev) {
		if (store.get().globalData.isMoveZoomMode || !store.get().globalData.isPresentationMode) {
			return;
		}
		if (ev.touches.length != 1) {
			return;
		}
		this.touchStartX = ev.touches[0].clientX;
		this.touchStartY = ev.touches[0].clientY;
		this.opacityNext = this.opacityPrev = 0;

		this.direction = 'unknown';
		ev.preventDefault();
		ev.stopPropagation();

	}
	touchMove(ev) {
		if (store.get().globalData.isMoveZoomMode || !store.get().globalData.isPresentationMode) {
			return;
		}
		if (ev.touches.length != 1 || this.touchStartX === undefined) {
			return;
		}

		// opacity for NEXT / PREV text ...

		this.diffX = ev.touches[0].clientX - this.touchStartX;
		if (this.direction == 'unknown' && this.diffX > 0) {
			this.opacityPrev = Math.pow(Math.min(400,this.diffX)/400,2);
			
		} else if (this.direction == 'unknown') {
			this.opacityNext = Math.pow(Math.min(400,-this.diffX)/400,2);
		}

		// these elements are actually part of the navigation-view ...

		document.getElementById("next").style.opacity = this.opacityNext;
		document.getElementById("prev").style.opacity = this.opacityPrev;

		ev.preventDefault();
		ev.stopPropagation();

	}
	touchEnd(ev) {
		if (store.get().globalData.isMoveZoomMode || 
			!store.get().globalData.isPresentationMode || 
			this.touchStartX == undefined) {
			return;
		}

		if (this.direction == 'unknown' && this.diffX > 350) {
			this.direction = 'prev';
			this.opacityPrev = 1;
			this.opacityNext = 0;
		} else if (this.direction == 'unknown' && this.diffX < -350) {
			this.direction = 'next';
			this.opacityNext = 1;
			this.opacityPrev = 0;
		} 

		if (this.direction == 'next') {
			this.thisNext(ev);
	
		} else if (this.direction == 'prev') {
			this.thisPrev(ev);
	
		}
		this.direction = 'unknown';
		this.touchStartX = this.touchStartY = undefined;
		this.diffX = 0;
		this.opacityPrev = 0;
		this.opacityNext = 0;

		document.getElementById("next").style.opacity = this.opacityNext;
		document.getElementById("prev").style.opacity = this.opacityPrev;


	}
	componentDidMount() {

		this.boundKeylistener = this.keyListener.bind(this);
		document.addEventListener('keydown', this.boundKeylistener);

		if (store.get().globalData.isPresentationMode) {
			if (this.previewColumnRef != null) { this.previewColumnRef.hidden = true; }
			this.frameWindowRef.style.width = "100vw";

		} else {
			
			this.previewColumnRef.hidden = false;
			const width = `calc( 100vw - ${store.get().globalData.previewWidth}px)`;
			this.frameWindowRef.style.width = width;
		}

		this.boundPasteListener = this.paste.bind(this);
		window.addEventListener('paste', this.boundPasteListener);


		document.addEventListener('touchstart',(ev) => this.touchStart(ev) );
		document.addEventListener('touchmove',(ev) => this.touchMove(ev) );
		document.addEventListener('touchend',(ev) => this.touchEnd(ev) );

		if (!this.props.isTestMode) {
			if (typeof window !== 'undefined') {
				//store.dispatch('setSlideAndAnimationData', { slideData : initialSlideData , animationData : initialAnimationData });
				load();
			}		
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.boundKeylistener);
		window.removeEventListener('paste', this.boundPasteListener);

	}

	shouldComponentUpdate() {
		// all the rerendering is happening outside ...
		return false;
	}

	thisNext(ev) {
		store.dispatch('next');
		ev.stopPropagation();
		ev.preventDefault();
	}
	thisPrev(ev) {
		store.dispatch('prev');
		ev.stopPropagation();
		ev.preventDefault();
	}
	thisCurrent(ev) {
		this.frameRef.setDoForceUpdate();
		store.dispatch('current');
		ev.stopPropagation();
		ev.preventDefault();
	}

	render() {

		// let previewStyleString = `position: absolute; width: ${store.get().globalData.previewWidth}px; height: 100%; overflow-x: hidden; overflow-y: scroll;`;
		const previewStyleString = `float: left; width: ${store.get().globalData.previewWidth}px; height: 100%; overflow-x: hidden; overflow-y: scroll;`;
		let frameWindowStyleString = 'position: relative; width: 100%; height: 100%;';
		if (!store.get().globalData.isPresentationMode) {
			// frameWindowStyleString = `position: absolute; left: ${store.get().globalData.previewWidth}px; width: calc(100vw - ${store.get().globalData.previewWidth}px); height: 100%;`;
			frameWindowStyleString += `float: left; width: calc(100vw - ${store.get().globalData.previewWidth}px); height: 100%;`;
		} else {
			// frameWindowStyleString = `position: absolute; width: 100%; height: 100%;`;
			frameWindowStyleString += `float: left; width: 100%; height: 100%;`;
		}

		let preview = null;
		
		// This is not really too nice; do not know how to check for testing
		// or override the "constant" allowWorkbench ....
		if (allowWorkbench || this.props.isTestMode) {

			const previewComponent = (store.get().globalData.isImagePreview ? <Preview isImagePreview /> : <Preview />);
			preview = <div ref={el=>{this.setPreviewColumnRef(el)}} id="previewColumn" 
				style=	{previewStyleString}>
					{previewComponent}
			</div>
		}

		return (<div id="app" style="height: 100%; width: 100%;">
			<StoreContext.Provider value={store} >
					{preview}
					<div ref={el=>{this.setFrameWindowRef(el)}} 
						id="frameWindow" style={frameWindowStyleString}>
							<FrameContainer ref={(ref) => this.setFrameRef(ref)} isTestMode={this.props.isTestMode}/>
							<Navigation next={this.thisNext.bind(this)} prev={this.thisPrev.bind(this)}
								current={this.thisCurrent.bind(this)}
								togglePresentationMode={this.togglePresentationMode.bind(this)}
								turnOnMoveZoomMode={this.turnOnMoveZoomMode.bind(this)}
								toggleFullScreen={this.toggleFullScreen}
								toggleNavigationMode={this.toggleNavigationMode}
							/>
					</div>
			</StoreContext.Provider>
		</div>
		);
	}
}


export default App;

/*


		<Header />
		<Router>
			<Home path="/" />
			<Profile path="/profile/" user="me" />
			<Profile path="/profile/:user" />
		</Router>

 */