import { React  } from 'preact';
import { useStoreon } from 'storeon/preact';

function AnimationEditor() {


        const { dispatch, animationData , navigationState} = useStoreon('animationData',  'navigationState');

        const textareas = [];
        animationData.forEach( (animationStep, index) => {

            let line = JSON.stringify(animationStep);
            let border = '';
            if (navigationState.currentSlideNum == index) {
                border = 'margin: -5px; border: solid blue 5px;';
            }
            textareas.push( <div >
                    <div style='width: 30px; margin-top: -5px; display: inline-block; vertical-align: super;'>{index}:</div> 
                    <textarea id={'ata_' + index } style={border + 'width: calc(100% - 30px); resize: none;'} value={line}  />
                </div>)
        });

        const applyChanges = function() {
            let data = '[';
            let isFirst = true;
            textareas.forEach( (el,index) => {
                let line = document.getElementById('ata_' + index).value.trim();
                if (line.length > 0) {
                    if (!isFirst) {
                        data += ',';
                    }
                    data += line;
                    isFirst = false;
                }
            })
            data += ']';
            try {
                const newAnimationData = JSON.parse(data);
                dispatch('setAnimationData', newAnimationData);
                dispatch('current');
            } catch(e) {
                alert(e);
            }

        }

        const keyDown = function(ev) {
            ev.stopPropagation();
        }
    
        const controlElements = <div style='position: absolute; top: calc(100% - 30px);' >
            <button onClick={applyChanges}>Apply</button> 
            <button onClick={ () => {dispatch('setIsShowAnimationEditor', false)}}>Hide</button>
        </div>
        
        return <div style='position: absolute; left: 10px; width: 600px; height: 500px; overflow: hidden; resize: both;'
                onKeyDown={keyDown}>
            <div style='height: 100%; overflow: scroll;'>
                {textareas}
                <div style='height:40px' />
            </div>
            {controlElements}
        </div>;
    
}

export default AnimationEditor;