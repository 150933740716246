import { slideWidth, slideHeight } from '../utils/constants';
import { parsePositionData1 } from '../utils/matrixCalculations';

import produce from 'immer';


function handleResult(state,key,changedElementData,newChangedElementData) {
  if (changedElementData != newChangedElementData) {
    const result = {};
    result[key] = { ...state[key], changedElementData : newChangedElementData };
    return result;
  }
  return null;
}

let editData = store => {
    store.on('@init', () => (
      {
          editData : { editedSlide : undefined,
            changedElementId : null,
            selectedElementId : null,
            selectedElementPosition : 0,
            selectedElementsMap : {},
            isAddMode : false }
      }
    ));
    store.on('setIsAddMode', (state,data) => {
      if (state.editData.isAddMode != data) {
        return { editData : { ...state.editData, isAddMode : data} };
      }
    });

    store.on('setEditedSlide', (state,data) => {
      // the comparrison with "!==" is important: null and undefined need to be
      // treated differently!
      if (state.editData.editedSlide !== data) {
        return { editData : { ...state.editData, editedSlide : data }};
      }
    });
    store.on('setSelectedElement',(state,data) => {
      const newEditData = produce( state.editData, draft => {
        const selectedElementId = data.elementId;
        const selectedElementPosition = data.position;

        if (draft.selectedElementId === selectedElementId) {
          // deselect the currently selected element ...
          delete draft.selectedElementsMap[selectedElementId];
          draft.selectedElementId = null;
          draft.selectedElementPosition = 0;
        } else if (selectedElementId === null) {
          draft.selectedElementId = null;
          draft.selectedElementPosition = 0;
          draft.selectedElementsMap = {};
        } else {
          draft.selectedElementId = selectedElementId;
          draft.selectedElementPosition = selectedElementPosition;
          draft.selectedElementsMap[selectedElementId] = { position : selectedElementPosition };
        }  
      });
      if (state.editData != newEditData) {
        return { editData : newEditData };
      }
    });
    store.on('startEdit', (state, data) => {
      
      let elementCopy;
      const newEditData = produce(state.editData, draft => {
      
        // no need for element-content ....
        const element = state.slideElementMap[data.id].element; 
        
        elementCopy = parsePositionData1(element);
        if ('otherPositions' in element) {
          // we have to make a "deep copy"
          elementCopy.otherPositions = [];
          element.otherPositions.forEach(position => {
            elementCopy.otherPositions.push(parsePositionData1(position));
          });
        }
        if (element.width !== undefined) {
          elementCopy.width = element.width;
        }
        if (element.height !== undefined) {
          elementCopy.height = element.height;
        }    
        draft.changedElementId = data.id;
      });
      if (state.editData != newEditData) {
        const result = { editData : newEditData };
        const key = 'visibilityData_' + newEditData.changedElementId;
        result[key] = { ...state[key], changedElementData : elementCopy };
        return result;
      }

    });

    store.on('endEdit',(state) => {
      if (state.editData.changedElementId !== undefined &&
        state.editData.changedElementId.length > 0) {

        // setting the changedElementData inside the individual visibilityData
        const key = 'visibilityData_' + state.editData.changedElementId;
        const result = { };
        result[key] = { ...state[key], changedElementData : null };
        result.editData = { ...state.editData, changedElementId : null};
        return result;
      }
    });

    
  store.on('moveElement', (state,data) => {
      if (state.editData.changedElementId == null) {
        return;
      }
      const key = 'visibilityData_' + state.editData.changedElementId;
      const changedElementData = state[key].changedElementData;

      if (!changedElementData) {
        throw("cant move with no elementData!");
      }

      // we make this immutable 
      const newChangedElementData = produce( changedElementData, element => {
        // we need to take the Position into account ....
        if (data.position != 0) {
          element.otherPositions[data.position-1].positionX += data.x;
          element.otherPositions[data.position-1].positionY += data.y;
        } else {
          element.positionX += data.x;
          element.positionY += data.y;
        }
      });

      return handleResult(state,key,changedElementData,newChangedElementData);
  
    });
    store.on('scaleElement', (state,data) => {

      if (state.editData.changedElementId == null) {
        return;
      }
      const key = 'visibilityData_' + state.editData.changedElementId;
      const changedElementData = state[key].changedElementData;

      if (!changedElementData) {
        throw("cant scale with no elementData!");
      }
      // we make this immutable 
      const newChangedElementData = produce( changedElementData, element => {

        let scale = element.scale;
        if (data.position != 0) {
          scale = element.otherPositions[data.position-1].scale;
        }
        if (scale === undefined) {
          scale = 1;
        }
        
        const scaleFactor = 0.005 + scale / 500;
    
        scale = Math.max(0.01, Math.min(2000, scale - data.y  * scaleFactor));
      
        if (data.position != 0 ){
          element.otherPositions[data.position-1]["scale"] = scale;
        } else {
          element["scale"] = scale ; 
        }
      });

      return handleResult(state,key,changedElementData,newChangedElementData);
    
    });
    store.on('changeElementWidthHeight', (state,data) => {
      if (state.editData.changedElementId == null) {
        return;
      }
      const key = 'visibilityData_' + state.editData.changedElementId;
      const changedElementData = state[key].changedElementData;

      if (!changedElementData) {
        throw("cant changeElementWidthHeight with no elementData!");
      }

      // we make this immutable 
      const newChangedElementData = produce( changedElementData, element => {
  
        let scale = element.scale;
        let width = element.width;
        let height = element.height;
        let positionX = element.positionX;
        let positionY = element.positionY;
        if (data.position != 0) {
          scale = element.otherPositions[data.position-1].scale;
          positionX = element.otherPositions[data.position-1].positionX ;
          positionY = element.otherPositions[data.position-1].positionY;
        } 
    
        if (scale === undefined) {
          scale = 1;
        }
        if (width === undefined) {
          width = slideWidth;
        }
        if (height === undefined) {
          height = slideHeight;
        }
        
        if (data.x !== undefined) {
          width += (data.x / scale); // the width is (inversly) scaled, 
          positionX += (data.x/2 ); // but the positions just changes as is ...
        }
        if (data.y !== undefined) {
          height += (data.y / scale); // the width is (inversly) scaled, 
          positionY += (data.y/2 ); // but the positions just changes as is ...
        }
    
        if (data.position != 0) {
          element.otherPositions[data.position-1].positionX = positionX;
          element.otherPositions[data.position-1].positionY = positionY;
        } else {
          element.positionX = positionX;
          element.positionY = positionY;
        }
        element["width"] = width;
        element["height"] = height;
  
      });

      return handleResult(state,key,changedElementData,newChangedElementData);

    });
  };
  
  export default editData;
  
  
  