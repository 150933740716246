import { createStoreon } from 'storeon'

/* create the stores */
import visibilityData from '../stores/visibiltydata';
import editData from '../stores/editdata';
import { slideData } from '../stores/slidedata';
import globalData from '../stores/globaldata';
import navigationData from '../stores/navigationdata';
import previewData from '../stores/previewdata';

const store = createStoreon([slideData,globalData,visibilityData,editData, navigationData, previewData ]);

export default store;
