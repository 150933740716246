import {React, Component, h } from 'preact';

import { connectStoreon  } from 'storeon/preact';

import {  useStoreon } from 'storeon/preact';

import { enrichMat  } from '../../utils/slideDataToSlideComponent';

import { slideHeight, slideWidth , presentation  } from '../../utils/constants';

import { mat4  } from 'gl-matrix';

const SlideNumber = (props) => {

    const { globalData } = useStoreon('globalData');

    return <div style='position: absolute; z-index: 1; background: white;'>{globalData.isShowAnimationEditor ? props.index + ' (' + props.positionInPreview + ')' : ''} </div>
}

const animationStepLength = (view) => {
    return  view.props.endIndex - view.props.startIndex + 1;
}


class PreviewComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { data : { previewData : {}, elements: [] }};
    }

    navigatePreviewPosition(index,code) {

        const views = this.props.previewData.previewElements;

        const newPosition = code == 'ArrowUp' ? index - 1  : index + 1;
        if (newPosition < 0 || newPosition >= views.length) {
            return;
        }
        this.props.dispatch('gotoSlide', 
            { slideNum : views[newPosition].props.startIndex });

    }

    keyDown(ev) {
        if (ev.code != 'ArrowUp' && ev.code != 'ArrowDown') {
            return;
        }

        let index = ev.positionInPreview;
        if (index === undefined) {
            return;
        }

        ev.preventDefault();
        ev.stopPropagation();


        if (!ev.shiftKey) {
            // we are in "navigation"-Mode (not moving a slide)
            this.navigatePreviewPosition(index,ev.code);
            return;
        }
        
        const views = this.props.previewData.previewElements;
        const view = views[index];

        const length = animationStepLength(view);

        const previousLength = index > 0 ? animationStepLength(views[index-1]) : 0;
        const nextLength = index < views.length - 1 ? animationStepLength(views[index+1]) : 0;

        const moveUpPos = view.props.startIndex  - previousLength;
        const moveDownPos = view.props.startIndex + nextLength;

        const newPosition = ev.code == 'ArrowUp' ? moveUpPos : moveDownPos ;

        if (newPosition == view.props.startIndex) {
            return;
        }

        let data = {startIndex : view.props.startIndex , length , newPosition };

        this.props.dispatch('moveAnimation',data);
        this.props.dispatch('gotoSlide', { slideNum : newPosition });
    }

    static getDerivedStateFromProps(nextprops,state) {

        if (nextprops.previewData !== undefined && 
            nextprops.previewData.previewElements !== undefined && 
            state.data.previewData.previewElements !== nextprops.previewData.previewElements) {

            // then we do a recalulation of the components ...

            const newData = { ...state.data };

            let elements = [];

            nextprops.previewData.previewElements.forEach( (element, pos) => {
                let elementStyleString = `position: relative;`;
                let index = element.props.startIndex + '-' + element.props.endIndex;
    
                let displayedPreview;
    
                if (nextprops.isImagePreview) {
                    let imgsrc = `${presentation}/previews/${nextprops.previewHash}_${("" + (element.props.endIndex+1)).padStart(3,'0')}.png`; 
                    displayedPreview = <PreviewContainer isImagePreview imgsrc={imgsrc} 
                        needsUpdate={nextprops.hash!=nextprops.previewHash}
                        startIndex={element.props.startIndex} endIndex={element.props.endIndex} 
                        positionInPreview={pos} />;
                } else {
                    displayedPreview = element;
                }
    
                elements.push(<div style={elementStyleString}>
                        <SlideNumber index={index} positionInPreview={pos}/>
                        {displayedPreview}
                    </div>)
            });

            newData.elements = elements;
            newData.previewData = nextprops.previewData;

            return { data : newData };
        }
        return null;
        
    }

    shouldComponentUpdate(nextprops, nextstate) {
        return this.props.globalData.previewWidth != nextprops.globalData.previewWidth ||
            this.state.data !== nextstate.data ||
            this.props.hash != nextprops.hash ||
            this.props.previewHash != nextprops.previewHash;
    }

    render(props,state) {

        let styleString = `width:${props.globalData.previewWidth}px; border: white solid 5px;`; //  transform-style: preserve-3d;`; 

        return <div style={styleString} onKeyDown={ev=>this.keyDown(ev)}>
            {state.data.elements}
            </div>;
    }
}

class PreviewContainerInternalComponent extends Component  {

    constructor(props) {
        super(props);
        this.state = { position : 0 };
    }

    static getDerivedStateFromProps(nextprops,prevstate) {

        let position = 0;

        if (nextprops.positions !== undefined && 
            nextprops.id in nextprops.positions) {
            position = nextprops.positions[nextprops.id];
        }

        if (position != prevstate.position) {
            return { position };
        }
        return null;

    }

    shouldComponentUpdate(nextprops,nextstate) {

        return (nextprops.slideElementMap !== this.props.slideElementMap ||
            nextprops.previewFrameChildren !== this.props.previewFrameChildren ||
            nextprops.previewSlideElementMaps !== this.props.previewSlideElementMaps ||
            nextstate.position != this.state.position ||
            nextprops.previewWidth != this.props.previewWidth ||
            nextprops.id != this.props.id ||
            nextprops.positionInPreview != this.props.positionInPreview ||
            nextprops.animationDataItem != this.props.animationDataItem );

    }

    render(props, state) {

    
        if (!props.previewSlideElementMaps) {
            return null;
        }
        if (!props.previewFrameChildren || props.previewFrameChildren.length == 0) {
            return null;
        }

        const lookupResult = props.previewSlideElementMaps[props.positionInPreview][props.id];

        if (!lookupResult || !lookupResult.element) {
            return null;
        }

        if (lookupResult.mats === undefined) {
            console.log("ERROR: mats undefined for " + props.id);
            return null;
        }

        const width = lookupResult.element.width || slideWidth;
        const height = lookupResult.element.height || slideHeight;

        // think about this: only if the mat really changes, we need to so an inverted mat ...
        // but this is not too expensive ... !?

        const mat = enrichMat(lookupResult.mats[state.position], 
            props.previewSlideElementMaps[props.positionInPreview], 
            lookupResult.parentId, props.positions);

        const matrixIsIdentity = mat4.equals(mat,mat4.create());

        const imat = matrixIsIdentity ? null : mat4.create();
        if (!matrixIsIdentity) {
            mat4.invert(imat,mat); 
        }

        // calculate the parameters based on props ...
        // scale it down do preview-width ...
        let scalePreview = props.previewWidth / width;
        // TODO: do this, so that we have an adjustment for wide views/slides as well ...
    
        let animationScale = 1;
        if (props.animationDataItem.scale !== undefined) {    
            animationScale = props.animationDataItem.scale;
            scalePreview *= animationScale;
        }

        const previewHeight = scalePreview * height;

        const styleStringContainer = `height:${previewHeight}px; overflow-x: hidden;overflow-y:hidden; margin-bottom: 10px;`; 
        // transform-style:preserve-3d;`;

        // TODO: handle the perspective of this "mini-view" ... is it OK, like that? No, it is not OK ...
        
        // Calculating in "preview-display" is too expensive: a complete minipresentation with perspective is not
        // handled well by the browsers ... we get strange overlay effects even if we have overflow hidden!
        /* 
        const center = vec3.create();
        vec3.transformMat4(center,
            vec3.fromValues(viewWidth/2 ,viewHeight/2,0),
            imat);

        let perspectiveX = center[0] * scalePreview;
        let perspectiveY= center[1] * scalePreview;
        */

        if (lookupResult.element.vmat !== undefined && 
            lookupResult.element.vmat != null ) {
            mat4.multiply(imat,lookupResult.element.vmat,imat);
        }
    
        const matrixString = matrixIsIdentity ? '' : `matrix3d(${imat})`;
        const styleStringPreview = `transform: scale(${scalePreview}) ${matrixString};transform-origin: 0 0;transform-style:preserve-3d;`;
        
        const perspectiveStyle =  // `perspective: ${perspective}px;perspective-origin: ${perspectiveX}px ${perspectiveY}px;` + 
            'overflow: hidden;'

        // due to css and 3d stuff, drag and drop does not work as expected ... 
        //draggable onDragStart={ev => dragStart(ev,props.positionInPreview)}
        return (<div style={styleStringContainer} >
                    <div style={perspectiveStyle}>
                        <div class='view' style={styleStringPreview}>
                        {props.previewFrameChildren[props.positionInPreview]}                    
                        </div>        
                    </div>
                </div>);
    }
}

const PreviewContainerComponent = connectStoreon('previewFrameChildren', 'previewSlideElementMaps', PreviewContainerInternalComponent);

class PreviewContainerBasis extends Component  {

    constructor(props) {
        super(props);
        this.containerRef = null;
        this.state = { isSelected : false };
    }

    setContainerRef(el) {
        if (el != null) {
            this.containerRef = el;
        }
    }

    static getDerivedStateFromProps(props,prevstate) {
        let newSelectedState = false;

        if (props.navigationState.currentSlideNum >= props.startIndex && 
            props.navigationState.currentSlideNum <= props.endIndex) {
            newSelectedState = true;
        }
        if (newSelectedState != prevstate.isSelected) {
            return { isSelected : newSelectedState };
        }
        return null;
    
    }

    shouldComponentUpdate(nextprops,nextstate) {
        return (
            nextstate.isSelected || (nextstate.isSelected != this.state.isSelected) ||
            this.props.globalData.previewWidth != nextprops.globalData.previewWidth || 
            this.props.positions !== nextprops.positions ||
            this.props.id !== nextprops.id ||
            this.props.startIndex !== nextprops.startIndex ||
            this.props.endIndex !== nextprops.endIndex ||
            this.props.positionInPreview !== nextprops.positionInPreview ||
            this.props.animationDataItem !== nextprops.animationDataItem );
    }

    scrollIntoView() {
        if (this.containerRef != null) {
            if ( (typeof this.containerRef.scrollIntoView ) == 'function') {
                this.containerRef.scrollIntoView( {block: 'center', behavior: 'smooth'});
                this.containerRef.focus();
            }
        }    
    }

    componentDidMount() {
        if (this.state.isSelected) {
            this.scrollIntoView();
        }
    }
    componentDidUpdate() {
        if (this.state.isSelected) {
            this.scrollIntoView();
        }
    }

    mouseDown(ev) {
        this.props.dispatch('gotoSlide', { slideNum : this.props.startIndex });
    } 

    keyDown(ev) {
        ev.positionInPreview = this.props.positionInPreview;
    }

    render(props,state) {

        let borderStyle = state.isSelected ? 'solid black 2px;' : 'solid lightgrey 1px;';

        //const dragStart = (ev, positionInPreview) => {
        //    ev.dataTransfer.setData('animationStep', JSON.stringify({ positionInPreview }));
        //}

        const styleStringContainer = `border: ${borderStyle};`;

        let children = [];
        if (props.isImagePreview) {
            children.push( <img src={props.imgsrc} width={props.globalData.previewWidth-10} /> );
            if (props.needsUpdate) {
                children.push(<span style='color: red; position: absolute; top: 50px; left: 50px; transform: rotateZ(-45deg);'>need update ... </span>);
            }
        } else {
            const previewContainerComponent = <PreviewContainerComponent id={props.id} positionInPreview={props.positionInPreview} positions={props.positions} 
            previewWidth={props.globalData.previewWidth}
            animationDataItem={props.animationDataItem} />;
            children.push(previewContainerComponent);

        }

        return (<div id={'pv_' + props.positionInPreview} style={styleStringContainer} ref={el=>this.setContainerRef(el)} 
            tabIndex={props.positionInPreview+1}
            onMouseDown={ev=>this.mouseDown(ev)}
            onKeyDown={ev=>this.keyDown(ev)}>
            {children}
        </div>)
    }
}

const PreviewContainer = connectStoreon('globalData','navigationState', PreviewContainerBasis)

const Preview = connectStoreon('previewData', 'globalData','hash','previewHash', PreviewComponent);

export { Preview, PreviewContainer  };

