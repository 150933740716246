import { React, h } from 'preact';

import { connectStoreon } from 'storeon/preact';

import {
        mdLineNumbers, // mdHighlight, 
        mdLineNumbersNoHTML, Prism
} from '../../utils/markdown';

import produce from 'immer';


import Editmarker from '../editmarker';
import SlideElement from '../slideelement';

/** 
 * @typedef PresentationElementState
 * @type {object}
 * @property {object} data - the Data of the state 
 * @property {boolean} data.visible - is the component visible? 
 * @property {number} data.position - which position are we using (default 0) 
 * @property {boolean} data.isParentSlideEdited - is the parent slide being edited? 
 * @property {matrix4d} data.editMats - the  
*/

/**
 * Class that represents a text that is part of a presentation (e.g. on a {@link Slide})
 * 
 * Being a (P)React Component, the presentation element is completly determined
 * by its properties
 * 
 */
class PresentationElement extends SlideElement {
        /**
         * Create an instant of a PresentationElement
         */
        constructor() {
                super();

                /** 
                * @member {PresentationElementState} state - the initial state.
                * @memberOf PresentationElement
                * @inner 
                */
                this.state = {
                        data: {
                                visible: true, position: 0,
                                isParentSlideEdited: false,
                                editMats: null, editCss: '',
                                isSelected: false
                        },
                        prevVisibilityData: null
                };
        }

        /**
        * Generates a new state from a set of given properties.
        * 
        * @param {object} nextProps 
        * @param nextProps.elementId 
        * @param nextProps.text
        * @param nextProps.innerHTML
        * @param nextProps.parentId
        * @param nextProps.animation
        * @param nextProps.visibilityData_{elementId} 
        * @param nextProps.visibilityData_{elementId}.activated
        * @param {boolean} nextProps.visibilityData_{elementId}.invisible
        * @param {number} nextProps.visibilityData_{elementId}.position
        * @param {object} nextProps.visibilityData_{elementId}.changedElementData
        * @param {number} nextProps.visibilityData_{elementId}.changedElementData.width
        * @param {number} nextProps.visibilityData_{elementId}.changedElementData.height
        * @param {object} nextProps.editData
        * @param {string} nextProps.editData.editedSlide - id of edited slide
        * @param {string} nextProps.editData.changedElementId - id
        * @param {string} nextProps.editData.selectedElementId
        * 
        * @param {object} data - The previous state of the calling {@link PresentationElement} 
        * 
        */
        static calculateNewData(nextProps, data) {

                // shallow copy, no immutable call!
                // let draft = { ...data };
                const newData = produce(data, draft => {

                        draft.editMats = null;
                        draft.editCss = '';

                        /**
                         * If we are in "preview"-mode, the activation, position and invisible settings are
                         * transfered with nextProps.visibilityData.
                         * If we are not in "preview"-mode, the activation, position and invisible settings
                         * are transfered with an individual property, which is made up from
                         * visibilityData{_elementId}
                         */

                        PresentationElement.handleVisibility(draft, nextProps);
                        PresentationElement.handlePositionData(draft, nextProps);
                        PresentationElement.handleEditData(draft, nextProps);
                });

                return newData;
        }

        static getDerivedStateFromProps(nextProps, state) {

                const { elementVisibilityData, doContinue } =
                        PresentationElement.checkIfPropertyChangeNeedsHandling(nextProps, state);

                if (!doContinue) {
                        return null;
                }
                const newData = PresentationElement.calculateNewData(nextProps, state.data);
                if (newData == state.data) {
                        // no change
                        return null;
                }
                return {
                        data: newData,
                        prevVisibilityData: elementVisibilityData
                };
        }
        shouldComponentUpdate(nextProps, nextState) {
                if (this.state.data != nextState.data) {
                        return true;
                }
                return (this.props.mats != nextProps.mats ||
                        this.props.css != nextProps.css ||
                        this.props.type != nextProps.type);
        }

        setRef(el) {
                if (el != null) {
                        this.textRef = el;
                }
        }

        onKeyDown(event) {

                // TODO: do this, when we are in true Text-Edit-Mode ...
                if (this.state.data.isSelected) {
                        if (event.keyCode == 27) { // Escape Key
                                // then we finish this editing and return to the previous value
                                event.eventElementId = this.props.elementId;
                                event.action = 'Escape';
                                this.lastEditedText = this.props.text;
                                return;
                        }

                        // stopPropagation is called to prevent frame from handling keyboard shortcuts ...
                        event.stopPropagation();
                }
        }

        onInput(event) {
                if (this.state.data.isSelected) {
                        event.eventElementId = this.props.elementId;
                        event.text = this.textRef.innerText;
                        this.lastEditedText = event.text;
                }
        }

        focus() {
                if (this.lastEditedText != null) {
                        this.textRef.innerText = this.lastEditedText;
                }
        }

        handleTextDisplay() {
                let innerHTML;
                if (this.props.innerHTML === undefined) {
                        if (this.props.isPreview) {
                                innerHTML = mdLineNumbersNoHTML.render(this.props.text);
                        } else {
                                innerHTML = mdLineNumbers.render(this.props.text);
                        }
                } else {
                        if (this.props.isPreview) {
                                innerHTML = mdLineNumbersNoHTML.render(this.props.text);
                        } else {
                                // make sure that quotes are handled correctly
                                // this is needed, because we do a comparisson of content later on
                                // the conntent of textRef.innerHTML will be converted to " instead of '
                                innerHTML = this.props.innerHTML.replace(/'/g, '"');
                        }
                }
                if (this.textRef.innerHTML != innerHTML) {
                        this.textRef.innerHTML = innerHTML;

                        if (this.props.text !== undefined && this.props.text.indexOf('```javascript') >= 0) {
                                Prism.highlightAllUnder(this.textRef);
                        }
                }
                this.lastEditedText = null;
                if (this.state.data.isParentSlideEdited && this.state.data.isSelected) {
                        this.lastEditedText = this.props.text;
                }

        }
        componentDidMount() {
                if (this.textRef) {
                        this.handleTextDisplay();
                }
        }
        componentDidUpdate() {
                if (this.textRef) {
                        this.handleTextDisplay();
                }

        }
        render(props, state) {

                if (state.data.isHiddenDueToEdit) {
                        return null;
                }

                if (props.isPreview && !state.data.visible) {
                        return null;
                }

                let styleString = SlideElement.basicRendering(props, state);

                let contentEditable = false;

                let editMarker = null;
                if (state.data.isParentSlideEdited) {
                        contentEditable = state.data.isSelected;
                        editMarker = <Editmarker elementId={props.elementId}
                                parentId={props.parentId}
                                scale={props.scale} selected={state.data.isSelected}
                                animation={props.animation}
                                />;
                        const border = 1 * props.scale;
                        styleString += `margin: -${border}px;`;

                }
                const cssclassname = props.type == 'Title' ? 'slidetitle' : 'slidetext';

                let textElement = null;
                if (contentEditable) {
                        textElement = <div ref={el => this.setRef(el)}
                                class={cssclassname} style='user-select: all;width:100%; height:100%;'
                                onInput={ev => this.onInput(ev)}
                                onMouseDown={ev => ev.stopPropagation()}
                                onMouseUp={ev => ev.stopPropagation()}
                                contentEditable='true'
                                spellCheck='true'                               
                                onFocus={ev => this.focus(ev)}
                        />
                } else {
                        // style='width:100%; height:100%'
                        textElement = <div ref={el => this.setRef(el)}    
                                class={cssclassname}
                                contentEditable='false'
                                spellCheck='false'                               
                        />
                }

                // we have three different return.
                return (props.isPreview  ?
                        <div class={'presentationelement' +
                                (props.type == 'Title' ? ' title' : '')} style={styleString}
                        >
                                {textElement}
                        </div> : (state.data.isSelected ? 
                                <div class={'presentationelement' +
                                (state.data.isParentSlideEdited ? ' edit' : '') +
                                (props.type == 'Title' ? ' title' : '')} style={styleString}
                                onKeyDown={ev => this.onKeyDown(ev)}
                                >
                                {textElement}
                                {editMarker}
                                </div> : 
                                <div class={'presentationelement' +
                                (state.data.isParentSlideEdited ? ' edit' : '') +
                                (props.type == 'Title' ? ' title' : '')} style={styleString}
                                >
                                        {textElement}
                                        {editMarker}
                                </div>));
                        

        }
}

const EditablePresentationElement = connectStoreon('editData', PresentationElement);
const StaticPresentationElement = connectStoreon(PresentationElement);

export { EditablePresentationElement, StaticPresentationElement };


