import { React } from 'preact';

import { connectStoreon } from 'storeon/preact';


import produce from 'immer';

import { presentation } from './../../utils/constants'
import Editmarker from '../editmarker';
import SlideElement from '../slideelement';

class ImageComponent extends SlideElement {

        constructor() {
                super();
                this.state = {
                        data:
                        {
                                visible: true, position: 0, isParentSlideEdited: false, editMats: null, editCss: '',
                                isSelected: false, src: ''
                        },
                        prevVisibilityData: null
                };
        }

        static getDerivedStateFromProps(nextProps, state) {

                let { elementVisibilityData, doContinue } =
                        ImageComponent.checkIfPropertyChangeNeedsHandling(nextProps, state);

                if (!doContinue) {
                        return null;
                }

                const newData = produce(state.data, draft => {

                        draft.editMats = null;
                        draft.editCss = '';


                        ImageComponent.handleVisibility(draft, nextProps);
                        ImageComponent.handlePositionData(draft, nextProps);
                        ImageComponent.handleEditData(draft, nextProps);

                        let src = presentation + '/' + nextProps.element.imgURL;
                        if (nextProps.element.dataURL !== undefined) {
                                src = nextProps.element.dataURL;
                        }
                        if (src != state.data.src) {
                                draft.src = src;
                        }

                });
                if (newData != state.data) {
                        return { data: newData, prevVisibilityData: elementVisibilityData };
                }
                return null;
        }

        shouldComponentUpdate(nextProps, nextState) {

                if (this.state.data != nextState.data) {
                        return true;
                }

                return (this.props.mats != nextProps.mats ||
                        this.props.css != nextProps.css ||
                        this.props.type != nextProps.type
                );
        }

        render(props, state) {

                if (state.data.isHiddenDueToEdit) {
                        return null;
                }
                if (props.isPreview && !state.data.visible) {
                        return null;
                }

                let styleString = SlideElement.basicRendering(props, state);

                let editMarker = null;
                if (state.data.isParentSlideEdited) {
                        editMarker = <Editmarker elementId={props.elementId} parentId={props.parentId}
                                scale={props.scale} selected={state.data.isSelected}
                                animation={props.animation}
                                doNotMoveSelected={false} />;
                        const border = 1 * props.scale; // make it an integer ?
                        styleString += `margin: -${border}px;`;
                }

                return (
                        <div class={' presentationelement' +
                                (state.data.isParentSlideEdited ? ' edit' : '')} style={styleString} >
                                <img src={state.data.src} />
                                {editMarker}
                        </div>
                );
        }
}

const EditableImageElement = connectStoreon('editData', ImageComponent);
const StaticImageElement = connectStoreon(ImageComponent);

export { EditableImageElement, StaticImageElement };


