// h is needed for tests ...
import {React, h } from 'preact';

import { calculateActivationForOneSlide,  calculateCompletePositions  } from '../utils/animationLogic';

import { createSlimSlides, updateSingleElementNoChildrenForPreview } from '../utils/slideDataToSlideComponent';

import { PreviewContainer } from '../components/preview';


function updateBulletpointsActivationsIfNeccessary(newElement, currentFixedVisibilityData, updatedFixedVisibilityData, index) {

    if (currentFixedVisibilityData != null && 
        currentFixedVisibilityData.activated !== undefined && 
        newElement.id in currentFixedVisibilityData.activated &&
        'counter' in currentFixedVisibilityData.activated[newElement.id]) {
            // we the element in the currentFixedVisibilityData.activated
            let newLength = newElement.content.length - 1;
            let oldMaxcounter = currentFixedVisibilityData.activated[newElement.id].maxcounter;

            if (newLength != oldMaxcounter) {
                // we must update the fixedVisibilityData
                let updatedData = { ...currentFixedVisibilityData };
                updatedData.activated = { ...currentFixedVisibilityData.activated };
                updatedData.activated[newElement.id] = { counter : newLength , maxcounter : newLength };
                updatedFixedVisibilityData[index] = updatedData;
                return updatedData;
            }

        }
    return currentFixedVisibilityData;

}
const previewData = (store) => {
    store.on('@init', () => (
        { previewData: { 
            fixedVisibilityData : [], // alredy used for update; this is OK
            previewElements : [], // these are the actual elements shown on the preview-panel
         } , 
         previewViewChildComponents : [], // for each previewElement we have a map with slide-ids and the saved children
         previewFrameChildren : [], // the same for the main-View
         previewSlideElementMaps : [] // we need to save the slideViewMap for each preview
        }
    ));
    store.on('createPreviewComponentsAndData', (state,data) => {

        // expect
        // data.slideData
        // data.animationData
        // data.slideElementMa

        if (!data.animationData || data.animationData.length == 0) {
            return;
        }

        // does a complete recalculation ...

        const previewViewChildComponents = [];
        const previewFrameChildren = [];
        const previewSlideElementMaps = [];
        const globalFixedVisibilityData = [];
        const previewElements = [];

        let lastId = null;
        let lastAnimationDataItem = null;
        
        const fixedVisibilityData = { activated : {}, invisible : [], positions: {} };

        let positionInPreview = 0;
        let lastStartIndex = null;

        data.animationData.forEach( (animationDataItem, index) => {

            if (lastId == null || animationDataItem.id == lastId) {

                ({ lastStartIndex, lastId, lastAnimationDataItem } = calculateAnimationDataForAnimationStep(animationDataItem, 
                    fixedVisibilityData, data.slideElementMap, lastStartIndex, index, lastId, lastAnimationDataItem));
                return;
            } 
            
            if (lastId != null && animationDataItem.id != lastId) {

                ({ positionInPreview, lastStartIndex } = createPreviewForAnimationStep(fixedVisibilityData, 
                    positionInPreview, data.slideData, 
                    previewViewChildComponents, previewFrameChildren, previewSlideElementMaps,
                    globalFixedVisibilityData, 
                    lastStartIndex, index, 
                    previewElements, 
                    lastId, lastAnimationDataItem));
                    
            }

            ({ lastStartIndex, lastId, lastAnimationDataItem } = calculateAnimationDataForAnimationStep(animationDataItem, 
                fixedVisibilityData, data.slideElementMap, lastStartIndex, index, lastId, lastAnimationDataItem));

        });

        if (data.animationData.length > 0) {

            createPreviewForAnimationStep(fixedVisibilityData, 
                positionInPreview, data.slideData, 
                previewViewChildComponents, previewFrameChildren, previewSlideElementMaps,
                globalFixedVisibilityData, 
                lastStartIndex, data.animationData.length , 
                previewElements, 
                lastId, lastAnimationDataItem);

        }

        return { previewData: { 
                    fixedVisibilityData : globalFixedVisibilityData,
                    previewElements },
                previewViewChildComponents ,
                previewFrameChildren,
                previewSlideElementMaps  };

    });

    store.on('updatePreviewElement',(state,data) => {

        // TODO:
        // Refactor; make it "switchable"
        // do we need this at all, or do we rely on the server to generate previews ...

        // expect 
        // data.element -> the new element
        // data.parentId -> the elements parentId
        // data.locationInParent -> The location in the parent (viewComponent)

        // we iterate over all previewElements
        // cannot use "produce" from 'immer' (immutable) for complex Preact-Objects ....

        const updatePreviewElement = data.updatePreviewElement;
        const isFrameElementUpdate = (updatePreviewElement.parentId == null);

        const componentContainer = (isFrameElementUpdate ? state.previewFrameChildren : state.previewViewChildComponents);

        // componentContainer is either an array of arrays the ReactElements that are directly placed on the "mainview"
        // (they do not have a parent). For each preview-Window there is an array of Frame-Children
        // or componentContainer is the Array continaing a map of viewChildComponents for each preview-"window" 
        // the individual childs of the viewChildComponents can be accessed be the elementId (which will be the
        // parentId of the updated component)

        const newViewChildComponents = (isFrameElementUpdate ? [ ...state.previewFrameChildren ] : [ ...state.previewViewChildComponents ]);

        let needsUpdate = false;
        const updatedFixedVisibilityData = [];
        
        // so we iterate over all previews.
        componentContainer.forEach( (previewChildComponents, index) => {

            const oldElementChildrenforParent = (isFrameElementUpdate ? previewChildComponents : 
                previewChildComponents[updatePreviewElement.parentId]);
            // this is done to prevent updates from elements that are not visible in the preview due to 
            // honeyPath
            if (oldElementChildrenforParent === undefined) {
                return;
            }

            if (!oldElementChildrenforParent[updatePreviewElement.locationInParent] || 
                oldElementChildrenforParent[updatePreviewElement.locationInParent].length == 0) {
                    return;
            }

            // previewChildComponts is an entry in the array;
            // (so either the array for the previewFrameChildren for elements with no parents)
            // (or an map with the child elements for all elements containing other elements )

            // if we are dealing with bullet-points, the currentFixedVisiblityData must be adapted ...
            let currentFixedVisibilityData = state.previewData.fixedVisibilityData[index];

            // Feels like a hack ...!?
            if (updatePreviewElement.element.type === 'Bulletpoints') {
                // we have a potentially different fixed activation (number of bulletpoints changed )
                const newFixedVisibilityData = updateBulletpointsActivationsIfNeccessary(updatePreviewElement.element,currentFixedVisibilityData,updatedFixedVisibilityData,index);
                if (currentFixedVisibilityData != newFixedVisibilityData) {
                    currentFixedVisibilityData = newFixedVisibilityData;
                    needsUpdate = true;
                }                
                updatedFixedVisibilityData[index] = currentFixedVisibilityData;
            }

            // I need to determine, if the updated element is a slide element, that will not be "retransformed" ...
            
            const newReactElement = updateSingleElementNoChildrenForPreview(updatePreviewElement.element, 
                updatePreviewElement.parentId, currentFixedVisibilityData );

            // another shallow copy ...
            const newElementChildrenforParent = [ ...oldElementChildrenforParent ];
            
            newElementChildrenforParent.splice(updatePreviewElement.locationInParent,1,newReactElement);

            if (isFrameElementUpdate) {
                newViewChildComponents[index] = newElementChildrenforParent;
            } else {
                // do a shallow copy of the previewChildComponts map so that
                // previous state is not mutated!
                newViewChildComponents[index] = { ...previewChildComponents };
                newViewChildComponents[index][updatePreviewElement.parentId] = newElementChildrenforParent;
            }
        });

        // check if, we have changed the fixedVisiblity Data .
        let result = {};
        if (needsUpdate) {
            result.previewData = { ...state.previewData, fixedVisibilityData : updatedFixedVisibilityData }
        }

        if (isFrameElementUpdate) {
            result.previewFrameChildren = newViewChildComponents;
            return result;
        }
        result.previewViewChildComponents = newViewChildComponents;
        return result;

    });
    store.on('removeElementInPreviews', (state,data) => {

        const removeElementInPreviews = data.removeElementInPreviews;

        // wenn sich die Animations-Steps ändern, dann muessen sich potentiell alle Previews ändern ...

        if ('animationData' in data) {
            store.dispatch('createPreviewComponentsAndData', 
            { slideData : data.slideData, animationData: data.animationData, slideElementMap : data.slideElementMap });
            return;
        }

        const isFrameElementUpdate = (removeElementInPreviews.lookupResult.parentId == null);

        const componentContainer = (isFrameElementUpdate ? state.previewFrameChildren : state.previewViewChildComponents);

        const newViewChildComponents = [];
        
        componentContainer.forEach( (previewChildComponents, index) => {

            const oldParentElementChildren = (isFrameElementUpdate ? previewChildComponents : 
                previewChildComponents[removeElementInPreviews.lookupResult.parentId]);

            // a shallow copy ... we will change teh parentElementChildren by
            // removing an entry. The previous state must not be mutated!
            const newParentElementChildren = [ ...oldParentElementChildren ];

            const locationInParent = removeElementInPreviews.lookupResult.parentIds[
                removeElementInPreviews.lookupResult.parentIds.length-1];
            
            newParentElementChildren.splice(locationInParent,1);

            if (isFrameElementUpdate) {
                newViewChildComponents[index] = newParentElementChildren;
            } else {
                // shallow copy so that the previous state is not mutated.
                newViewChildComponents[index] = { ...previewChildComponents };
                newViewChildComponents[index][removeElementInPreviews.lookupResult.parentId] = newParentElementChildren;
            }
        });

        if (isFrameElementUpdate) {
            return { previewFrameChildren : newViewChildComponents };
        }
        return { previewViewChildComponents : newViewChildComponents  };

    });
}

export default previewData;

function calculateAnimationDataForAnimationStep(animationDataItem, 
    fixedVisibilityData, slideElementMap, lastStartIndex, index, lastId, lastAnimationDataItem) {
        
    const isStartOfSlide = false;

    const currentAnimation = calculateActivationForOneSlide(animationDataItem, isStartOfSlide,
        fixedVisibilityData.activated, slideElementMap );
    fixedVisibilityData.activated = { ...fixedVisibilityData.activated, ...currentAnimation };

    // we have to remove the deactivatedAnimations ...
    if (animationDataItem.deactivateAnimation !== undefined) {
        animationDataItem.deactivateAnimation.forEach(deactivated => {
            delete fixedVisibilityData.activated[deactivated];
        });
    }

    fixedVisibilityData.invisible = animationDataItem.invisible !== undefined ?
        animationDataItem.invisible : [];

    fixedVisibilityData.positions = calculateCompletePositions(animationDataItem.positions,
        fixedVisibilityData.activated);

    if (lastStartIndex == null) {
        lastStartIndex = index;
    }
    lastId = animationDataItem.id;
    lastAnimationDataItem = animationDataItem;

    return { lastStartIndex, lastId, lastAnimationDataItem };
}

function createPreviewForAnimationStep(fixedVisibilityData, positionInPreview, 
    slideData, 
    previewViewChildComponents, 
    previewFrameChildren, 
    previewSlideElementMaps,
    globalFixedVisibilityData, 
    lastStartIndex, index, 
    previewElements, lastId, animationStep) {

    const slideElementMap = {};

    const viewComponentChildren = {};
    const currentFixedVisibilityData = { ...fixedVisibilityData, previewPosition: positionInPreview };
    const children = createSlimSlides(slideData, slideElementMap, currentFixedVisibilityData, viewComponentChildren,animationStep.id);

    previewViewChildComponents.push(viewComponentChildren);
    previewFrameChildren.push(children);
    previewSlideElementMaps.push(slideElementMap);

    globalFixedVisibilityData.push(currentFixedVisibilityData);

    const startIndex = lastStartIndex;
    const endIndex = index - 1;

    previewElements.push(<PreviewContainer positions={currentFixedVisibilityData.positions}
        id={lastId} startIndex={startIndex} endIndex={endIndex}
        positionInPreview={positionInPreview}
        animationDataItem={animationStep} />);

    lastStartIndex = null;
    positionInPreview++;
    return { positionInPreview, lastStartIndex };
}
