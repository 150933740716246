
function findParentSlide(slideElementMap, id) {
  // no need for element content ...
  const lookupResult = slideElementMap[id];
  if (lookupResult) {
    if (lookupResult.element.type != 'Slide') {
      return findParentSlide(slideElementMap, lookupResult.parentId);
    }
    return id;
  }
  return null;
}

let globalData = store => {
  store.on('@init', () => (
    {
      globalData: {
        isPresentationMode : true,
        isMoveZoomMode : false,
        isEditMode : false,
        isNavigationMode : false,
        isShowIds: false,
        isShowAnimationEditor : false,
        isShowSlideDataEditor : false,
        isShowGlobalView : false,

        previewWidth : 200, // for Edit-Mode this defines the width of the previews on the left side
        isImagePreview : false // set to true if the images in edit mode should be generated by server instead of 
                  // html rendered presentations (memory usage)
    }
  }));
  store.on('setIsPresentationMode', (state,data) => {
    if (state.globalData.isPresentationMode != data) {
      store.dispatch('setEditedSlide', undefined);
      return { globalData : { ...state.globalData, isPresentationMode : data , isEditMode: false }};
    }
  });

  store.on('setIsMoveZoomMode', (state,data) => {
    if (data != state.globalData.isMoveZoomMode ) {      
      const result = { globalData : { ...state.globalData, 
        isMoveZoomMode : data }};
      if (!data) {
        // switch of navigation mode if movezoommode is cancelled ...
        result.globalData.isNavigationMode = false;
      }
      return result;
    }

  });

  store.on('setIsEditMode', (state,data) => {
    if (data != state.globalData.isEditMode) {
      let editedSlideNum = undefined;
      if (data) {
        if (!store.get().globalData.isShowGlobalView && store.get().animationData.length > 0) {
          editedSlideNum = store.get().animationData[store.get().navigationState.currentSlideNum].id;
          editedSlideNum = findParentSlide(store.get().slideElementMap, editedSlideNum);
        }
      }
      store.dispatch('setEditedSlide', editedSlideNum);
  
      // make sure, that the previous dispatch is reflected (if globalData has changed ...)
      return { globalData : { ...store.get().globalData, 
        isEditMode : data }};
    }
  });
  store.on('setIsNavigationMode', (state,data) => {
    if ( (data && state.globalData.isMoveZoomMode) || !data) {
      if (data != state.globalData.isNavigationMode) {
        let result = { globalData : { ...state.globalData, 
          isNavigationMode : data }};
        return result;
      }
    }
  });

  store.on('setIsShowIds', (state,data) => {
    if (data != state.globalData.isShowIds) {
      return { globalData : { ...state.globalData, 
        isShowIds : data }};
    }
  });
  store.on('setIsShowAnimationEditor', (state,data) => {
    if (data != state.globalData.isShowAnimationEditor) {
      return { globalData : { ...state.globalData, 
        isShowAnimationEditor : data }};
    }
  });
  store.on('setIsShowSlideDataEditor', (state,data) => {
    if (data != state.globalData.isShowSlideDataEditor) {
      return { globalData : { ...state.globalData, 
        isShowSlideDataEditor : data }};
    }
  });
  store.on('setIsShowGlobalView', (state,data) => {
    if (data != state.globalData.isShowGlobalView) {
      return { globalData : { ...state.globalData, 
        isShowGlobalView : data }};
    }
  });
  store.on('setPreviewWidth', (state,data) => {
    if (data != state.globalData.previewWidth) {
      return { globalData : { ...state.globalData, previewWidth : data }};
    }

})

};

export default globalData;
