import { glMatrix, quat, mat4, vec3 } from 'gl-matrix';
import { slideWidth, slideHeight } from '../utils/constants';


glMatrix.setMatrixArrayType(Array);

const rotationFunction = function (someRotation) {

    let q = quat.create();

    if (Array.isArray(someRotation) && someRotation.length > 0) {
        someRotation.forEach((element) => {
            if (element.x !== undefined && element.x != 0) {
                quat.rotateX(q, q, element.x / 180 * Math.PI);
            }
            if (element.y !== undefined && element.y != 0) {
                quat.rotateY(q, q, element.y / 180 * Math.PI);
            }
            if (element.z !== undefined && element.z != 0) {
                quat.rotateZ(q, q, element.z / 180 * Math.PI);
            }
        });
    }
    return q;
};


const parsePositionData1 = function(somePositionData) {
    let positionZ = 0.001;

    if (somePositionData.positionZ !== undefined) {
        positionZ = somePositionData.positionZ;
    }

    let rotation = [];
    if (somePositionData.rotation !== undefined) {
        rotation = somePositionData.rotation;
    }

    let positionX = 0;
    if (somePositionData.positionX !== undefined) {
        positionX = somePositionData.positionX;
    }

    let positionY = 0;
    if (somePositionData.positionY !== undefined) {
        positionY = somePositionData.positionY;
    }

    let scale = 1;
    if (somePositionData.scale !== undefined) {
        scale = somePositionData.scale;
    }
    return { positionX, positionY, positionZ, scale, rotation };

}

const parsePositionData = function (somePositionData, mySlideWidth, mySlideHeight) {

    const { positionX, positionY, positionZ, scale, rotation } = parsePositionData1(somePositionData);

    const q = rotationFunction(rotation);
    const mat = mat4.create();
    const translationPosition = vec3.fromValues(positionX -
        mySlideWidth / 2, positionY - mySlideHeight / 2, positionZ);
    const scaleVect = vec3.fromValues(scale, scale, 1);
    const translationOrigin = vec3.fromValues(mySlideWidth / 2, mySlideHeight / 2, 0);

    mat4.fromRotationTranslationScaleOrigin(mat, q, translationPosition, scaleVect, translationOrigin);

    return mat;
}

const calculateTransformMatrixes = function (elementData ) {

    const result = [];
    let mySlideWidth = slideWidth;
    let mySlideHeight = slideHeight;
    if (elementData.width !== undefined) {
        mySlideWidth = elementData.width;
    }
    if (elementData.height !== undefined) {
        mySlideHeight = elementData.height;
    }

    result.push(parsePositionData(elementData, mySlideWidth, mySlideHeight));

    // the otherPositions share the same Width/Height!
    if (elementData.otherPositions != null && Array.isArray(elementData.otherPositions) &&
        elementData.otherPositions.length > 0) {
        elementData.otherPositions.forEach((pos) => {
            result.push(parsePositionData(pos, mySlideWidth, mySlideHeight));
        })
    }

    return result;
};

export { calculateTransformMatrixes, parsePositionData1 };