import { connectStoreon } from 'storeon/preact';

// h is needed for tests
import { React, Component , h } from 'preact';

import AnimationEditor  from '../animationeditor';
import SlideDataEditor  from '../slidedataeditor';

import { calculateViewSpace, enrichMat } 
		from '../../utils/slideDataToSlideComponent';

import { slideWidth, slideHeight, viewWidth, viewHeight, perspective } 
		from '../../utils/constants';

import {  handleFocus, getMatCenterForPresentationMode  } 
		from '../../utils/helperFunctions';

import { isPreview } from '../../utils/constants'

import { mat4, vec3 } from 'gl-matrix';

import { produce } from 'immer';

let firefoxAgent = -1;
if (typeof navigator != 'undefined') {
	firefoxAgent = navigator.userAgent.indexOf("Firefox");
}

console.log("isFirefox" + firefoxAgent);

const width = viewWidth;
const height = viewHeight;

//let eventXZoomCache, eventYZoomCache, eventZZoomCache;
//let lastZoomEvent = 0;

const zoomsensitivity = 5;

class FrameBasis extends Component {

	constructor(props) {
		super();

		this.frameRef = null;
		this.canvasRef = null;
		this.viewRef = null;

		const offsetx = (props.globalData.isPresentationMode ? 0 : props.globalData.previewWidth);
		this.state = { 
			data : {
				currentIMat : null,
				vmat :  null,
				mySlideWidth : slideWidth,
				mySlideHeight : slideHeight,
				animationDataId : null,
				currentPosition : -1,
				isShowGlobalView : false,
				offsetx,
				visibleId : undefined 
			}, 
			slideElementMap : null
		};

		// saves the edit-state of moved/scaled/edited elements
		this.edit = null;
		this.currentUpdateCounter = 0;
		this.isAnimating = false;

		this.eventXZoomCache = undefined;
		this.eventYZoomCache = undefined;
		this.eventZZoomCache = undefined;
		this.lastZoomEvent = 0;

	}

	setFrameRef(el) {
		if (el != null) {
			this.frameRef = el;
		}
	}
	setCanvasRef(el) {
		if (el != null) {
			this.canvasRef = el;
		}
	}
	setViewRef(el) {
		if (el != null) {
			this.viewRef = el;
		}
	}

	resizeEvent() {

		if (this.frameRef != null) {
	
			if (this.props.globalData.isPresentationMode ||
				!this.hasModifiedSlideView) {
				// make sure, that a visible slide gets centered


				this.setDoForceUpdate();
				this.forceUpdate();
			}

			const isFullScreen = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
			this.props.dispatch('setIsFullScreen', isFullScreen != null);

		}
		// handle fullscreen-stuff
	}
	setDoForceUpdate() {
		this.doForceUpdate = true;
	}

	
	onInput(extendedEvent) {
		if (extendedEvent.eventElementId === undefined) {
			return;
		}
		if (this.edit == null) {
			// then we have to construct the edit ...

			this.edit = { id: extendedEvent.eventElementId, 
				vx: 0, vy: 0 ,
				action : 'editing', position : -1 } ;

		}
		if (this.edit.id == extendedEvent.eventElementId) {
			this.edit.text = extendedEvent.text;
		} 
	} 
	onKeyDown(extendedEvent) {
		if (extendedEvent.eventElementId === undefined) {
			return;
		}
		if (extendedEvent.action == 'Escape') {
			// this deselects the editing
			this.props.dispatch('setSelectedElement', { elementId : null });
			this.edit = null;
		}
	}

	finishEditing() {
		if (this.edit != null) {
			if (this.edit.action == 'editing') {
				// then we had some editing going on 
				this.props.dispatch('changeText',{ id : this.edit.id, text : this.edit.text });
			}
		}
	}

	finishEditingAndDeselectElement() {
		this.finishEditing();
		this.props.dispatch('setSelectedElement', { elementId : null });
		this.edit = null;
	}

	handleClickSelect(extendedEvent) {

		const action = extendedEvent.action;
		if (action == 'clickOnSlide') {
			if (this.props.globalData.isPresentationMode) {
				this.props.dispatch('setVisibleId', extendedEvent.eventElementId);
			} else {
				// console.log("Action clickOnSlide");
				this.finishEditingAndDeselectElement();
			}
			return true;
		}
		
		if (action == 'select') {
			this.finishEditing();
			const positions = this.props.visibilityData.positions;
			let position = 0;
			
			if (extendedEvent.eventElementId in positions) {
				position = positions[extendedEvent.eventElementId];
			}
			// Todo: handle an editing, that might be finished here!
			this.props.dispatch('setSelectedElement', { elementId : extendedEvent.eventElementId,
					position } );
			return true;
		}

		return false;

	}

	onMouseDown(extendedEvent) {
		// event bubbles from down below ... 
		// check, if it originates form an element, that can be moved ....
		if (extendedEvent.eventElementId !== undefined) {
			// we have an originating eventElementId (some Child-Element ...)

			if (this.handleClickSelect(extendedEvent)) {
				return;
			}

			let action = null;

			switch (extendedEvent.action) {
				case 'move' : action = 'moveElement'; break;
				case 'scale': action = 'scaleElement'; break;
				case 'width': action = 'width'; break;
				case 'height': action = 'height'; break;
			}

			if (action != null) {

				const positions = this.props.visibilityData.positions;
				
				let position = 0;
				
				if (extendedEvent.eventElementId in positions) {
					position = positions[extendedEvent.eventElementId];
				}
				
				this.edit = { id: extendedEvent.eventElementId, 
					vx: extendedEvent.x, vy: extendedEvent.y ,
					action, position } ;
				
				this.props.dispatch('startEdit', { id : extendedEvent.eventElementId, action, position  });
				extendedEvent.preventDefault();
				return;
			}
		}

		this.finishEditingAndDeselectElement();

	}

	changeElement(event) {
		if (this.edit == null) {
			return;
		}
		const id = this.edit.id;

		const vx = event.x ;
		const vy = event.y ;

		const x = (vx - this.edit.vx) / this.scale;
		const y = (vy - this.edit.vy) / this.scale;

		if (this.edit.action == 'width') {
			this.props.dispatch('changeElementWidthHeight',{id,x, position: this.edit.position})
		} else if (this.edit.action == 'height') {
			this.props.dispatch('changeElementWidthHeight',{id,y, position: this.edit.position});
		} else {
			this.props.dispatch(this.edit.action,{id,x,y, position: this.edit.position });
		}
		return id;
	}

	onMouseMove(event) {

		if (this.edit == null || this.edit.action == 'editing') {
			return;
		}
		const id = this.changeElement(event);
		this.edit = { id,  
			vx: event.x, vy: event.y ,
			action : this.edit.action,
		position : this.edit.position } ;

		
	}

	onMouseUp(event) {
		
		if (this.edit == null) {
			return;
		}
		
		this.changeElement(event);
		this.props.dispatch('endChange', this.edit );
		this.edit = null;
	}

	handleGestureZoom(event) {
		event.stopPropagation();
		event.preventDefault();

		event.x = event.clientX;
		event.y = event.clientY;
		this.doZoom(event);
	}
	gestureStart(event) {
		if (!this.checkIfZoomIsPossible(event)) {
			return;
		}
		this.initialScale = this.scale;
		this.handleGestureZoom(event);
	}

	gestureChange(event) {
		if (!this.checkIfZoomIsPossible(event)) {
			return;
		}
		this.handleGestureZoom(event);
	}
	gestureEnd(event) {
		event.stopPropagation();
		event.preventDefault();
	}

	componentDidMount() {



		this.canvasRef.style.perspective = `${perspective}px`;
		this.listener = this.resizeEvent.bind(this);
		window.addEventListener('resize', this.listener);


		const updateCounter = this.startUpdate();
		
		this.showSlide(this.props.navigationState.currentSlideNum,false,updateCounter, 0);

	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.listener);
	}

	startUpdate() {
		this.isAnimating = true;
		this.props.dispatch('setIsAnimating',true);
		
		return  ++this.currentUpdateCounter;
	}

	endUpdate(updateCounter) {
		if (updateCounter == this.currentUpdateCounter) {

			this.doForceUpdate = false;
			this.isAnimating = false;
			this.hasModifiedSlideView = false;

			this.props.dispatch('setIsAnimating',false);

			if (!this.props.globalData.isMoveZoomMode) {

				// this could be used to automatically switch to "Move/Zoom" mode,
				// whenever a new slide has been reached.
				// The thing is, that it does not look to nice, because you get
				// a flicker of the scrollbars ...

				// TODO handle this with a flag  -> Has some side-effects ...
				// does not look to nice, because "scroll-bars" are shortly visible after each animation ..
				// this.props.dispatch('setIsMoveZoomMode',true);
			}
		}
	}

	isCurrentUpdate(updateCounter) {
		return updateCounter == this.currentUpdateCounter;
	}

	handleReturnFromMoveZoomMode(prevstate) {

		// we are returning from MoveZoomMode to "normal" mode.
		// either going to a next/prev slide or to the current slide ..

		// then we have to first put the slide in a correct position to do 
		// the correct animation transition ...

		// first attempt was to scroll back ...
		// new attempt: make a transformation without scrollbars that is at
		// the current position
		// then proceed to the slide, where we want to go to using the "normal" presentation animations.

		const translateX =  (this.currentTranslateX - this.frameRef.scrollLeft); 
		const translateY = (this.currentTranslateY - this.frameRef.scrollTop);

		this.doMatrixTransformAdvanced(0,0,prevstate.data,this.scale,false,translateX, translateY);

		this.frameRef.className = "frame presentation";
		this.canvasRef.className = "canvas presentation";
		this.viewRef.className = "view presentation";

		let defaultTransitionTime = undefined;
	
		if (JSON.stringify(prevstate.data.currentIMat) == JSON.stringify(this.state.data.currentIMat) && 
			!this.hasModifiedSlideView) {
			defaultTransitionTime = 150;
		}
		return defaultTransitionTime;

	}
	componentDidUpdate(prevprops,prevstate) {

		let doShowSlide = this.doForceUpdate;

		doShowSlide = doShowSlide || prevprops.navigationState.currentSlideNum != 
			this.props.navigationState.currentSlideNum;
		doShowSlide = doShowSlide || this.hasModifiedSlideView;

		doShowSlide = doShowSlide || prevprops.globalData.isShowGlobalView != this.props.globalData.isShowGlobalView;
		doShowSlide = doShowSlide || prevprops.globalData.isPresentationMode != this.props.globalData.isPresentationMode;
		doShowSlide = doShowSlide || prevprops.globalData.isMoveZoomMode != this.props.globalData.isMoveZoomMode;
		doShowSlide = doShowSlide || prevprops.navigationState.visibleId !== this.props.navigationState.visibleId;

		doShowSlide = doShowSlide || prevprops.animationData !== this.props.animationData;
		doShowSlide = doShowSlide || prevprops.slideElementMap !== this.props.slideElementMap;

		const hasChangedNavigationMode = (prevprops.globalData.isNavigationMode != this.props.globalData.isNavigationMode);
		if (hasChangedNavigationMode) {
			this.vmat = null;
		}
		doShowSlide = doShowSlide || hasChangedNavigationMode;

		if (doShowSlide) {

			const updateCounter = this.startUpdate();

			let defaultTransitionTime;

			if (this.props.globalData.isPresentationMode && 
				prevprops.globalData.isPresentationMode &&
				prevprops.globalData.isMoveZoomMode && 
				!this.props.globalData.isMoveZoomMode) {
	
				defaultTransitionTime = this.handleReturnFromMoveZoomMode(prevstate);
						
			} else {

				if (JSON.stringify(prevstate.data.currentIMat) == JSON.stringify(this.state.data.currentIMat)) {
					defaultTransitionTime = 150;
				}
			}
			
			this.showSlide(this.props.navigationState.currentSlideNum, prevprops.globalData.isPresentationMode,
				updateCounter , defaultTransitionTime );
		}
	}

	static calculateMatrizes(draft,props,positions,mySlideWidth,mySlideHeight,lookupResult,position) {
		let mat = [];
		let vmat = null;
		if (!props.globalData.isShowGlobalView) {
			if (props.navigationState.visibleId !== undefined) {
				// we have a slide that we clicked on that is currentyl being shown, but
				// is not the same as the "currentSlideNum" in the presentation.
				const slideLookup = props.slideElementMap[props.navigationState.visibleId];
				draft.mySlideWidth = slideLookup.element.width !== undefined ? slideLookup.element.width : slideWidth;
				draft.mySlideHeight = slideLookup.element.height !== undefined ? slideLookup.element.height : slideHeight;

				let slidePosition = 0;
				if (props.navigationState.visibleId in positions) {
					slidePosition = positions[props.navigationState.visibleId];
				}
				mat = enrichMat(slideLookup.mats[slidePosition], props.slideElementMap, slideLookup.parentId, positions);

				if (slideLookup.element.vmat !== undefined && slideLookup.element.vmat != null) {
					vmat = slideLookup.element.vmat;
				}
	
			} else {
				draft.mySlideWidth = mySlideWidth;
				draft.mySlideHeight = mySlideHeight;
	
				if (lookupResult.mats === undefined) {
					console.log("ERROR !!no mats for " + lookupResult.element.id + " " );
					mat = mat4.create();
				} else {
					mat = enrichMat(lookupResult.mats[position], props.slideElementMap, lookupResult.parentId, positions);
				}
				if (lookupResult.element.vmat !== undefined && lookupResult.element.vmat != null) {
					vmat = lookupResult.element.vmat;
				}		

			}
		} else {
			draft.mySlideWidth = viewWidth;
			draft.mySlideHeight = viewHeight;
			mat = mat4.create();
		}
		const imat = mat4.create();
		mat4.invert(imat,mat);

		draft.currentIMat = imat;
		draft.vmat = vmat;
	}

	static getDerivedStateFromProps(props, prevstate) {

		const offsetx = (props.globalData.isPresentationMode ? 0 : props.globalData.previewWidth);

		if (props.animationData == null || props.animationData.length == 0 ) {
			// this is only called, if we have no valid data ... there is nothing to see
			// or show ... so we just set everything to identity and wait for better times.
			return { data : { ...prevstate.data, currentIMat : mat4.create() } , slideElementMap : props.slideElementMap };
		}


		const animationData = props.animationData[props.navigationState.currentSlideNum];

		const positions = props.visibilityData.positions;

		let position = 0;
		if (animationData.id in positions) {
			position = positions[animationData.id];
		}

		// Todo: think about this: if slideData changes (position-Information), 
		// then it may be, that the slide is not shown at the correct position, because
		// the currentIMat needs to be reset ...
		// how would I know this??? Listen to slideData changes? 
		if (animationData.id == prevstate.data.animationDataId && 
			position == prevstate.data.currentPosition &&
			props.globalData.isShowGlobalView == prevstate.data.isShowGlobalView &&
			props.slideElementMap === prevstate.slideElementMap &&
			offsetx === prevstate.data.offsetx &&
			props.navigationState.visibleId === prevstate.data.visibleId) {
			// we do not have to recalulate the currentIMat, everything is just the same!
			return null;
		}

		const newStateData = produce( prevstate.data, draft => {

			draft.currentPosition = position;
			draft.animationDataId = animationData.id;
			draft.isShowGlobalView = props.globalData.isShowGlobalView;
			draft.offsetx = offsetx;
			draft.visibleId = props.navigationState.visibleId;
	
			// this is part of the view:
			// now we look for the slide/view with the given ID ...
			const lookupResult = props.slideElementMap[animationData.id];
		
			if (lookupResult === undefined) {
				// we have no lookup ..
				draft.currentIMat = mat4.create();
				return;
			}
	
			const showSlideInfo = lookupResult.element;
	
			// now we calculate the imat ... so that the frame can display the
			// correct animated slide
			const mySlideWidth = showSlideInfo.width ?? slideWidth;
			const mySlideHeight = showSlideInfo.height ?? slideHeight;

			FrameBasis.calculateMatrizes(draft,props,positions,mySlideWidth,mySlideHeight,lookupResult,position);
	
		});

		if (prevstate.data != newStateData ||
			prevstate.slideElementMap != props.slideElementMap) {
			return { 
				data : newStateData, 
				slideElementMap : props.slideElementMap 
			}
		}
		return null;
	}


	doMatrixTransform(scrollCorrectionLeft,scrollCorrectionTop, state, scale) {
		return this.doMatrixTransformAdvanced(scrollCorrectionLeft,scrollCorrectionTop, 
			state,scale, true, 0 ,0 );
	}
	doMatrixTransformAdvanced(scrollCorrectionLeft,scrollCorrectionTop, 
		state, scale, doCalc, newTranslateX, newTranslateY) {

		// this uses cache to calculate this only once ...
		// maybe we need maxx and maxy in the future ....
		const [calculatedWidth, calculatedHeight, minx, , miny, , centerX, centerY] = 
			calculateViewSpace(state.currentIMat, width, height, perspective);

		const scaledViewWidth = calculatedWidth * scale;
		const scaledViewHeight = calculatedHeight * scale;

		const canvasWidth = scaledViewWidth + 2 * this.frameRef.clientWidth;
		const canvasHeight = scaledViewHeight + 2 * this.frameRef.clientHeight;

		const translateX = doCalc ? this.frameRef.clientWidth - (minx * scale) : newTranslateX;
		const translateY = doCalc ? this.frameRef.clientHeight - (miny * scale) : newTranslateY;

		const perspectiveX = translateX + 
		centerX * scale;
		const perspectiveY = translateY + 
		centerY * scale;

		const mat = mat4.create();

		mat4.translate(mat, mat, vec3.fromValues(translateX, translateY, 0));
		mat4.scale(mat, mat, vec3.fromValues(scale, scale, 1));

		// this.vmat is another transform-matrix that is handled by
		// the navigation mode; it can be adjusted by keystrokes and
		// directs how the view is presented.
		if (this.props.globalData.isNavigationMode && 
			this.vmat !== undefined && 
			this.vmat != null) {
			mat4.multiply(mat,mat,this.vmat);
		} 

		// the state.vmat is provided by the element itself
		// for advanced elements, we can define a translation
		// in the element-data using "vmat" ....
		if (state.vmat !== undefined && state.vmat != null) {
			mat4.multiply(mat,mat,state.vmat);
		}

		mat4.multiply(mat, mat, state.currentIMat);
		
		this.canvasRef.style.perspectiveOrigin = `${perspectiveX}px ${perspectiveY}px`;

		let newScrollLeft = 0;
		let newScrollTop = 0;

		if (doCalc) {
			newScrollLeft = translateX + scrollCorrectionLeft;
			newScrollTop = translateY + scrollCorrectionTop;

			if (newScrollLeft < 0) {
				newScrollLeft = 0;
			}
			if (newScrollLeft > canvasWidth - this.frameRef.clientWidth) {
				newScrollLeft = canvasWidth - this.frameRef.clientWidth;
			}

			if (newScrollTop < 0) {
				newScrollTop = 0;
			}
			if (newScrollTop > canvasHeight - this.frameRef.clientHeight) {
				newScrollTop = canvasHeight - this.frameRef.clientHeight;
			}
		}

		this.viewRef.style.transform = `matrix3d(${mat})`;
		this.doCanvasSizeAndScroll(canvasWidth,canvasHeight,newScrollLeft,newScrollTop);
		return { translateX, translateY  };
	}

	doZoom(event) {
		let vx, vy, vz;

		// Note, that we used to have this.framRef.offsetLeft and offsetTop to determine
		// if the frame was moved with respect to the window
		// because we changed the layout, we no longer need this
		const offsetx = this.state.data.offsetx;  // + this.frameRef.offsetLeft;
		// let offsety = 0; // this.frameRef.offsetTop;

		const currentTime = new Date().getTime();
		if (this.lastZoomEvent != 0 && (currentTime - this.lastZoomEvent) <= 300) {
			vx = this.eventXZoomCache;
			vy = this.eventYZoomCache;
			vz = this.eventZZoomCache;

		} else {
			// find out, where the weel is at ...
			const clickX = this.frameRef.scrollLeft + event.x -  offsetx;
			const clickY = this.frameRef.scrollTop + event.y; // - offsety

			vx = (clickX - this.currentTranslateX) / this.scale;
			vy = (clickY - this.currentTranslateY) / this.scale;
			vz = 0;
		}
		this.lastZoomEvent = currentTime;
		this.eventXZoomCache = vx;
		this.eventYZoomCache = vy;
		this.eventZZoomCache = vz;

		if (event.scale !== undefined) {
			const newval = (event.scale - 1) * 1.2 + 1;
			this.scale = Math.max(0.01, Math.min(2000,this.initialScale * newval));
		} else {
			const realZoomSensitivity = event.ctrlKey ? -0.4 : zoomsensitivity;

			// the Scale-Factor (sensitivity should be settable from outside)
			// increasing the value will lead to less sensitivity.
			const scaleFactor = 0.8 / 100;
			this.scale = Math.max(0.01, Math.min(2000, this.scale * (1+(event.deltaY / realZoomSensitivity) * scaleFactor) ));
		}
		const scrollCorrectionLeft = - event.x + offsetx + vx* this.scale;
		const scrollCorrectionTop = -event.y + 
				// offsety 
				vy * this.scale;

		const { translateX, translateY } = this.doMatrixTransform( scrollCorrectionLeft, scrollCorrectionTop, 
			this.state.data, this.scale);
		this.currentTranslateX = translateX;
		this.currentTranslateY = translateY;
	}

	// this comes from "navigation"
	rotateEvent(data) {

		// Note, that we used to have this.framRef.offsetLeft and offsetTop to determine
		// if the frame was moved with respect to the window
		// because we changed the layout, we no longer need this
		const offsetx = this.state.data.offsetx;  // + this.frameRef.offsetLeft;
		// let offsety = 0; // this.frameRef.offsetTop;

		// assume the event at the center 
		const clickX = this.frameRef.scrollLeft + this.frameRef.clientWidth / 2 -  offsetx;
		const clickY = this.frameRef.scrollTop + this.frameRef.clientHeight / 2 ; // - offsety

		const vx = (clickX - this.currentTranslateX) / this.scale;
		const vy = (clickY - this.currentTranslateY) / this.scale;

		const scrollCorrectionLeft = - this.frameRef.clientWidth / 2 + offsetx + vx* this.scale;
		const scrollCorrectionTop = - this.frameRef.clientHeight / 2 + 
				// offsety 
				vy * this.scale;

		let rotateY = undefined;
		let rotateX = undefined;
		// let rotateZ = undefined;
		if (data == 'left') {
			rotateY = -2;
		} else if (data == 'right') {
			rotateY = 2;
		} else if (data == 'up') {
			rotateX = 2;
		} else if (data == 'down') {
			rotateX = -2;
		}

		const nmat = mat4.create();
		const transX = this.state.data.mySlideWidth / 2;
		const transY = this.state.data.mySlideHeight / 2;

		mat4.translate(nmat,nmat,vec3.fromValues(transX,transY,0));
		if (rotateY !== undefined) {
			mat4.rotateY(nmat,nmat, rotateY * Math.PI /180);
		}
		if (rotateX !== undefined) {
			mat4.rotateX(nmat,nmat, rotateX * Math.PI /180);
		}
		mat4.translate(nmat,nmat,vec3.fromValues(-transX,-transY,0));
		
		if (this.vmat === undefined || this.vmat == null) {
			this.vmat = nmat;
		} else {
			mat4.multiply(this.vmat,this.vmat, nmat);
		}

		const { translateX, translateY } = this.doMatrixTransform( scrollCorrectionLeft, scrollCorrectionTop, 
			this.state.data, this.scale);
		this.currentTranslateX = translateX;
		this.currentTranslateY = translateY;
	}

	createView() {

		// not sure about the "width" -> using offsetX ist better, but I am not sure why?
		this.props.dispatch('addCustomView', { vmat : this.vmat, width: this.state.data.mySlideWidth  ,
			height: this.state.data.mySlideHeight , scale: this.scale });

	}

	checkIfZoomIsPossible(event) {
		if (this.isAnimating || !this.props.globalData.isMoveZoomMode) {
			event.stopPropagation();
			event.preventDefault();
			return false;
		}
		return true;
	}

	zoomView(event) {
		if (!this.checkIfZoomIsPossible(event)) {
			return;
		}

		// any scroll or zoom ...
		this.hasModifiedSlideView = true;

		if (event.altKey || event.ctrlKey) {
			event.preventDefault();
			event.stopPropagation();

			this.doZoom(event);
			return;
		}

	}

	showSlideMoveZoom(scrollCorrectionLeft,scrollCorrectionTop,updateCounter) {

		// this is important, it makes sure, that the
		// change is instantly visible.

		// but this is also a problem, because it stops animations within the view
		// as well .. so we create a new class "movezoom" which overrides the presentation setting
		// but the contained elements (eg. slides/texts/ etc.) will still be animated
		this.frameRef.className = "frame";
		this.canvasRef.className = "canvas";
		this.viewRef.style.transitionDuration = null;
		this.viewRef.className = "view presentation movezoom";
		// so what we have: view transform will not transition, but be immediate. 
		// the rest will still be animated ... nice!

		const translateResult = this.doMatrixTransform(scrollCorrectionLeft,scrollCorrectionTop, 
			this.state.data, this.scale);
		this.currentTranslateX = translateResult.translateX;
		this.currentTranslateY = translateResult.translateY;

		this.endUpdate(updateCounter);

	}

	showSlideFadeOutIn(transitionDuration, mat, scrollCorrectionLeft, scrollCorrectionTop, center, updateCounter) {
						
		const timeFadeOut = (transitionDuration === undefined ? 250 : transitionDuration);
		// Todo: Redo this part ... has lots of code-duplication!

		this.viewRef.style.transition = `opacity ${timeFadeOut}ms, transform 0ms linear ${timeFadeOut}ms`;
		this.canvasRef.style.transition = `perspective-origin 0ms linear ${timeFadeOut}ms`;
		this.viewRef.style.opacity = 0;
		// due to transition style above, the transform will be
		// performed after the timeFadeout (10 ms);
		this.viewRef.style.transform = `matrix3d(${mat})`;

		const perspectiveX = -scrollCorrectionLeft + center[0] * this.scale;
		const perspectiveY = -scrollCorrectionTop +  center[1] * this.scale;

		this.canvasRef.style.perspectiveOrigin = `${perspectiveX}px ${perspectiveY}px`;

		setTimeout( () => {
			if (!this.isCurrentUpdate(updateCounter)) {
				// console.log("Not the current update, but need to fadeIn View after transition ....");
			}
			// This might be a problem:
			// if this is not the current animation, we might be stuck with 
			this.viewRef.style.opacity = 1;

			this.doCanvasSizeAndScroll(width,height,0,0);

			setTimeout( () => {
				this.frameRef.className = "frame presentation";
				this.canvasRef.className = "canvas presentation";
				this.viewRef.className = "view presentation";
				this.viewRef.style.transition = null;
				this.canvasRef.style.transition = null;
				this.viewRef.style.transitionDuration = null;

			},100);

		},timeFadeOut);

		setTimeout( () => {
			if (!this.isCurrentUpdate(updateCounter)) {
				return;
			}
			this.endUpdate(updateCounter);

		}, 2* timeFadeOut);
	}

	showSlidePresentationMode(transitionDuration,mat,
		scrollCorrectionLeft,scrollCorrectionTop,center,updateCounter,
		defaultTransitionTime) {

		// no fading animation ...
		this.viewRef.style.transition = null;
		this.canvasRef.style.transition = null;

		if (transitionDuration !== undefined) {	
			this.viewRef.style.transitionDuration = `${transitionDuration}ms`;
		} else {
			this.viewRef.style.transitionDuration = null;
		}

		this.setViewAndCanvasTransformation(mat, 
			scrollCorrectionLeft, scrollCorrectionTop, 
			center, this.scale);

		setTimeout( () => {
			if (!this.isCurrentUpdate(updateCounter)) {
				return;
			}
			this.frameRef.className = "frame presentation";
			this.canvasRef.className = "canvas presentation";
			this.viewRef.className = "view presentation";

		},100);

		const updateDuration = transitionDuration !== undefined ? 
				transitionDuration : defaultTransitionTime;

		setTimeout( () => {
			if (!this.isCurrentUpdate(updateCounter)) {
				return;
			}
			this.endUpdate(updateCounter);
		}, updateDuration);
	}
	
	showSlide(slidenum, prevIsPresentationMode, updateCounter, defaultTransitionTime = 2000) {

		const animationData = this.props.animationData[slidenum];
		if (animationData === undefined) {
			return;
		}

		if (!this.props.isTestMode) {
			handleFocus(animationData);
		}

		let { scrollCorrectionLeft, scrollCorrectionTop, viewScale } = 
			this.calculateScrollCorrectionAndScale(this.state.data);

		if (!this.props.globalData.isShowGlobalView) {
			if (animationData.scale !== undefined && this.props.globalData.visibleData === undefined) {
				viewScale *= animationData.scale;
			}
		}

		this.scale = viewScale;

		if (this.props.globalData.isPresentationMode && !isPreview) {

			// so what is the "REAL" transitionDuration?
			// which elements are being animated in this step? How do we know?

			if (this.props.globalData.isMoveZoomMode) {
				this.showSlideMoveZoom(scrollCorrectionLeft,scrollCorrectionTop,updateCounter);
				return;
			}

			// we handle the perspective ...
			const { mat, center } = getMatCenterForPresentationMode(width, height, 
				scrollCorrectionLeft, scrollCorrectionTop, this.state.data, this.scale);

			let transition = animationData.transition;
			let transitionDuration = animationData.transitionDuration;

			if ( this.props.navigationState.currentSlideNum ==
				this.props.navigationState.previousSlideNum - 1) {
				transition = this.props.animationData[slidenum+1].transition;
				transitionDuration = this.props.animationData[slidenum+1].transitionDuration;
			}
			let doFadeOutIn = false;
			if (prevIsPresentationMode) {
				if (transition !== undefined && transition == 'fade-out-in') {
					doFadeOutIn = true;
				}
			}

			if (doFadeOutIn) {
				this.showSlideFadeOutIn(transitionDuration,mat,
					scrollCorrectionLeft,scrollCorrectionTop,center,updateCounter);
			} else {
				this.showSlidePresentationMode(transitionDuration,mat,
					scrollCorrectionLeft,scrollCorrectionTop,center,updateCounter,defaultTransitionTime);
			}
			return;
		} 
		
		// a showSlide in "workbench"-mode. No transitions, just immediate showing of slide.

		// this is important, it makes sure, that the
		// change is instantly visible.
		this.frameRef.className = "frame";
		this.canvasRef.className = "canvas";
		this.viewRef.className = "view";
		
		this.viewRef.style.transitionDuration = null;

		const { translateX, translateY } = this.doMatrixTransform(scrollCorrectionLeft,scrollCorrectionTop, 
			this.state.data, this.scale);
		this.currentTranslateX = translateX;
		this.currentTranslateY = translateY;

		this.endUpdate(updateCounter);
	}

	calculateScrollCorrectionAndScale(state) {

		const viewScaleWidth = this.frameRef.clientWidth / state.mySlideWidth;
		const viewScaleHeight = this.frameRef.clientHeight / state.mySlideHeight;

		const viewScale = Math.min(viewScaleWidth, viewScaleHeight);

		// now we place it in the center ....
		const actualSlideWidth = state.mySlideWidth * viewScale;
		const actualSlideHeight = state.mySlideHeight * viewScale;

		const scrollCorrectionLeft = -(this.frameRef.clientWidth - actualSlideWidth) / 2;
		const scrollCorrectionTop = -(this.frameRef.clientHeight - actualSlideHeight) / 2;

		return { scrollCorrectionLeft, scrollCorrectionTop , viewScale };

	}

	setViewAndCanvasTransformation(mat, 
		scrollCorrectionLeft, scrollCorrectionTop,
		center, scale) {
		
		this.viewRef.style.transform = `matrix3d(${mat})`;

		this.doCanvasSizeAndScroll(width, height, 0, 0);

		const perspectiveX = -scrollCorrectionLeft + center[0] * scale;
		const perspectiveY = -scrollCorrectionTop + center[1] * scale;

		this.canvasRef.style.perspectiveOrigin = `${perspectiveX}px ${perspectiveY}px`;
	}

	// This is needed because firefox needs a different order of
	// the commands ..
	doCanvasSizeAndScroll(vWidth,vHeight,scrollLeft,scrollTop) {

		if (firefoxAgent < 0) {
			this.canvasRef.style.width = `${vWidth}px`;
			this.canvasRef.style.height = `${vHeight}px`;
			if (this.frameRef && typeof this.frameRef.scrollTo == 'function')
				this.frameRef.scrollTo(scrollLeft, scrollTop);				
		} else {
			if (this.frameRef && typeof this.frameRef.scrollTo == 'function')
				this.frameRef.scrollTo(scrollLeft, scrollTop);				
			this.canvasRef.style.width = `${vWidth}px`;
			this.canvasRef.style.height = `${vHeight}px`;
		}
	}


	generateRenderedComponent(props) {

		// onMouseLeave={ev=>this.onMouseUp(ev)}
		return <div ref={el => this.setFrameRef(el)} class='frame'
			onWheel={event => this.zoomView(event)}
			onMouseDown={ev=>this.onMouseDown(ev)}			
			onMouseMove={ev=>this.onMouseMove(ev)}
			onMouseUp={ev=>this.onMouseUp(ev)}
			
			onInput={ev=>this.onInput(ev)}
			onKeyDown={ev=>this.onKeyDown(ev)}
			onGestureStart={ev=>this.gestureStart(ev)}
			onGestureChange={ev=>this.gestureChange(ev)}
			onGestureEnd={ev=>this.gestureEnd(ev)}
			>
			<div ref={el => this.setCanvasRef(el)} class='canvas'>
				<div ref={el => this.setViewRef(el)} class='view'>
					{props.children}
				</div>
			</div>
		</div>;
	}
	shouldComponentUpdate(nextprops) {

		if (this.props.children != nextprops.children) {
			this.renderedComponent = null; 
		}
		// we always return true so that componentDidUpdate will be called
		// (which is important for presenting slides with showSlide being called )
		// this might be bad for needing to update the component? But I figure, that the
		// virtual dom might not need an update???
		return true;
	}


	render(props) {

		if (this.renderedComponent == null) {
			this.renderedComponent = this.generateRenderedComponent(props);
		}
		return this.renderedComponent;
	}
}

const Frame = connectStoreon('animationData','globalData','visibilityData', 'navigationState', 'slideElementMap',  FrameBasis);

class FrameContainerBase extends Component  {

	constructor() {
		super();
	}
	setFrameRef(el) {
		if (el != null) {
			this.frameRef = el;
		}
	}

	resizeEvent() {
		this.frameRef.resizeEvent();
	}
	setDoForceUpdate() {
		this.frameRef.setDoForceUpdate();
	}

	rotateEvent(data) {
		this.frameRef.rotateEvent(data);
	}

	createView() {
		this.frameRef.createView();
	}

	shouldComponentUpdate(nextprops) {
		return (this.props.frameChildren != nextprops.frameChildren ||
			this.props.globalData.isShowAnimationEditor != nextprops.globalData.isShowAnimationEditor ||
			this.props.globalData.isShowSlideDataEditor != nextprops.globalData.isShowSlideDataEditor);
	}

	render(props) {
		return <div style='position absolute; width: 100%; height: 100%'>
			<Frame ref={ref=>this.setFrameRef(ref)} isTestMode={props.isTestMode}>{props.frameChildren}</Frame>
			{props.globalData.isShowAnimationEditor ? <AnimationEditor /> : ''}
			{props.globalData.isShowSlideDataEditor ? <SlideDataEditor /> : ''}
			</div> 
	}
}

const FrameContainer = connectStoreon('frameChildren', 'globalData', FrameContainerBase);

export { FrameContainer, Frame, FrameBasis };
