
// needs to be set in style.css aswell!

const slideWidth = 960;
const slideHeight = 540;

const viewWidth = 1920;
const viewHeight = 1080;

const perspective = 4000;

const initialSlideData = [{
    "id": "main",
    "type": "View",
    "width" : 1920,
    "height" : 1040,
    "positionX": 960,
    "positionY": 540,
    "scale" : 1.5
  }, {
    "id": "slide_1",
    "type": "Slide",
    "positionX" : 960,
    "positionY" : 540,
    content : [{
      "id" : "slide_1_title",
      "type": "Title",
      "positionX" : 480,
      "positionY" : 50,
      "width" : 900,
      "height" : 60,
      "text" : "Hello Title"
    }, {
      "id": "slide_1_text_1",
      "type" : "Text",
      "positionX" : 480,
      "positionY" : 300,
      "width": 900,
      "height": 380,
      "text" : "Some content ..."
    }]
  }];

  const initialAnimationData =  [
    { "id" : "main" } ,
    { "id" : "slide_1"}];

  const myWindow = (typeof window !== 'undefined' ?
  window : { location : { search : "" }});

  const queryString = (function(a) {
      if (a == "") return {};
      var b = {};
      for (var i = 0; i < a.length; ++i)
      {
          var p=a[i].split('=', 2);
          if (p.length == 1)
              b[p[0]] = "";
          else
              b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
      }
      return b;
  })(myWindow.location.search.substr(1).split('&'));
  
  const presentation = (queryString.presentation === undefined ? 'welcome' : queryString.presentation);

  const isPreview = (queryString.preview === undefined ? false : true);

  const allowWorkbench = (queryString.workbench === undefined ? false : true );

  const requestedDataHash = (queryString.hash === undefined ? undefined : queryString.hash );

export { slideWidth,slideHeight, viewWidth, viewHeight, perspective, initialSlideData, initialAnimationData ,
presentation, allowWorkbench, 
isPreview, requestedDataHash  };