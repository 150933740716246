import MarkdownIt from 'markdown-it';
import Prism from 'prismjs';

const md = new MarkdownIt({ breaks: true });


const higlightWithLineNumbers = function (str, lang) {
  // Returns a highlighted HTML string
  return `<pre class='line-numbers language-${lang}'><code>` + Prism.highlight(str, Prism.languages[lang], lang) + "</code></pre>";
};


const mdHighlight = new MarkdownIt({ breaks: true, html: true,

    highlight: function (str, lang) {
            // Returns a highlighted HTML string
            return `<pre class='language-${lang}'><code>${Prism.highlight(str, Prism.languages[lang], lang)}</code></pre>`;
          }});

const mdLineNumbers = new MarkdownIt({ breaks: true, html: true, highlight: higlightWithLineNumbers });

const mdLineNumbersNoHTML = new MarkdownIt({ breaks: true, html: false,
  highlight: higlightWithLineNumbers });

export { md, mdHighlight, mdLineNumbers, mdLineNumbersNoHTML, Prism };
