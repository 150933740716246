// import style from './style.css';
import { React,h } from 'preact';

import { connectStoreon } from 'storeon/preact';
import { calculateTransformMatrixes } from '../../utils/matrixCalculations';

import Editmarker from '../editmarker';
import SlideElement from '../slideelement';

// import produce from 'immer';

class Slide extends SlideElement {

	constructor() {
		super();
		this.state = {
			data: {
				visible: true, slideEdited: false, position: 0, isSelected: false,
				isParentSlideEdited: false,
				editCss: '', editMats: null
			},
			prevVisibilityData : null
		};
	}

	// we do this, because we do not want to re-render all the slides
	// if only the visibility of some slides really do change ....
	static getDerivedStateFromProps(nextProps, state) {

		let { elementVisibilityData, doContinue } = 
			Slide.checkIfPropertyChangeNeedsHandling(nextProps,state);

		if (!doContinue) {
			return null;
		}

		// no produce from immer for preact elements 
		//let newData = produce(state.data, draft => {
		const draft = { ...state.data };

		draft.editMats = null;
		draft.editCss = '';

		/**
		 * If we are in "preview"-mode, the activation, position and invisible settings are
		 * transfered with nextProps.visibilityData.
		 * If we are not in "preview"-mode, the activation, position and invisible settings
		 * are transfered with an individual property, which is made up from
		 * visibilityData{_elementId}
		 */

		let visible = true;
		if (nextProps.animation !== undefined &&
			nextProps.animation) {
			// check, if the element has been activated by the current animation 
			if (nextProps.isPreview) {
				visible = nextProps.elementId in nextProps.visibilityData.activated;
			} else {
				visible = elementVisibilityData !== undefined && elementVisibilityData.activated !== undefined;
			}
		}

		let invisible;
		if (visible) {
			if (nextProps.isPreview) {
				if (nextProps.visibilityData.invisible.includes(nextProps.elementId)) {
					visible = false;
					invisible = true;
				}
			} else {
				if (elementVisibilityData !== undefined &&
					elementVisibilityData.invisible) {
					visible = false;
					invisible = true;
				}
			}
		}
		
		draft.visible = visible;
		draft.invisible = (invisible !== undefined ? invisible : false);

		let position;
		if (nextProps.isPreview) {
			if (nextProps.elementId in nextProps.visibilityData.positions) {
				position = nextProps.visibilityData.positions[nextProps.elementId];
			}
		} else {
			if (elementVisibilityData !== undefined && elementVisibilityData.position !== undefined) {
				position = elementVisibilityData.position;
			}
		}
		draft.position = position !== undefined ? position : 0;

		if (!nextProps.isPreview && nextProps.editData !== undefined) {

			draft.isParentSlideEdited =
				nextProps.editData.editedSlide !== undefined &&
				nextProps.editData.editedSlide === nextProps.parentId;

			draft.isSlideEdited = nextProps.editData.editedSlide !== undefined &&
				nextProps.editData.editedSlide === nextProps.elementId;

			draft.isSelected = nextProps.editData.selectedElementId !== undefined &&
				nextProps.editData.selectedElementId == nextProps.elementId;

			//if (draft.isSelected) {
			//	console.log("could have checkt for selection also with");
			//	console.log(nextProps.editData.selectedElementsMap);
			//}

			if (nextProps.editData.changedElementId == nextProps.elementId &&
				elementVisibilityData !== undefined &&
				elementVisibilityData.changedElementData) {

				// the element is currently being changed (moved/scaled, resized)
				draft.editMats = calculateTransformMatrixes(elementVisibilityData.changedElementData);
				if (elementVisibilityData.changedElementData.width !== undefined) {
					draft.editCss += `width: ${elementVisibilityData.changedElementData.width}px;`;
				}
				if (elementVisibilityData.changedElementData.height !== undefined) {
					draft.editCss += `height: ${elementVisibilityData.changedElementData.height}px;`;
				}
			}

		} else {
			draft.isParentSlideEdited = false;
			draft.isSelected = false;
			draft.isSlideEdited = false;
		}

		//});
		//if (newData != state.data) {
		//	return { data : newData };
		//}
		//return null;
		return { data: draft, prevVisibilityData : elementVisibilityData };
	}

	shouldComponentUpdate(nextProps, nextState) {

		if (this.state.data != nextState.data) {
			return true;
		}

		// this is cool stuff ... makes sure that slides are only updated, if there is an actual change!
		// (also in preview mode!);
		return (this.props.mats != nextProps.mats ||
			this.props.css != nextProps.css ||
			(nextProps.isPreview && this.props.previewViewChildComponents[this.props.visibilityData.previewPosition][this.props.elementId]
				!= nextProps.previewViewChildComponents[nextProps.visibilityData.previewPosition][nextProps.elementId]) ||
			(!nextProps.isPreview && this.props.viewComponentChildren[this.props.elementId] != nextProps.viewComponentChildren[nextProps.elementId])
		);

	}

	onMouseDown(ev) {
		
		if (ev.eventElementId === undefined) {
			ev.eventElementId = this.props.elementId;
			ev.action = 'clickOnSlide';
		}
	}

	render(props, state) {

		if (props.isPreview && !state.data.visible) {
			return null;
		}

		if (state.data.invisible) {
			return null;
		}

		let mat = props.mats[state.data.position];
		if (state.data.editMats != null) {
			mat = state.data.editMats[state.data.position];
		}

		let styleString = `transform: matrix3d(${mat}); ${props.css};` + state.data.editCss;
		styleString += 'opacity: ' + (state.data.visible ? '1;' : '0;');

		let isEdited = state.data.isParentSlideEdited;
		let editMarker = null;
		if (isEdited) {
			let parentId = props.parentId;
			editMarker = <Editmarker elementId={props.elementId} parentId={parentId}
				scale={props.scale} 
				animation={props.animation}
				selected={props.editData.selectedElementId == props.elementId} />;
			const border = 1 * props.scale;
			styleString += `margin: -${border}px;`;
		}

		const children = props.isPreview ?
			props.previewViewChildComponents[props.visibilityData.previewPosition][props.elementId] : 
			props.viewComponentChildren[props.elementId];
		
		return (props.isPreview ? 
			<div class={'slide' + (isEdited ? ' edit' : '')}
				style={styleString} >
				<div class='slidecontainer'>
					{children}
				</div>
			</div> :
			<div class={'slide' + (isEdited ? ' edit' : '')}
				style={styleString}
				onMouseDown={(ev)=>this.onMouseDown(ev)} >
				<div class='slidecontainer'>
					{children}
					{editMarker}
				</div>
			</div>
		);

	}
}

let EditableSlide = connectStoreon('viewComponentChildren',	'editData', Slide);
let StaticSlide = connectStoreon('globalData', 'previewViewChildComponents', Slide);
export { EditableSlide, StaticSlide };

